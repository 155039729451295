import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { interval, Subscription, SubscriptionLike } from 'rxjs';
import { InboxService } from 'src/app/workflow/inbox/inbox.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-inbox-menu',
  templateUrl: './inbox-menu.component.html',
  styleUrls: ['./inbox-menu.component.scss']
})
export class InboxMenuComponent implements OnInit {
  public InboxCount:any;
  @Input() ShowInbox: any[];
  constructor(private inboxService: InboxService) {
    this.InboxCount = localStorage.getItem('inboxCount');
   }

  subscription : Subscription;

  ngOnInit(): void {
    this.subscription = interval(60000).subscribe(() => {
      //get workflow task count without spinner
      this.inboxService.getWorkflowTaskCount().subscribe({
        next: result => {
          localStorage.setItem('inboxCount', JSON.stringify(result));
          this.InboxCount = localStorage.getItem('inboxCount');

        },
        error: err => err
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
