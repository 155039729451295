import { IHistoryItem } from './models/history-item';
import { ResetPassword } from './../security-configuration/user-administration/models/reset-password';
import { User } from './models/user';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Role } from '../security-configuration/role-administration/models/roles';
import { InboxService } from '../workflow/inbox/inbox.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RefreshTokenResponse } from '../account/models/refresh-token-response';
import { ModalService } from '../shared/services/modal.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    private userLoggedIn = new Subject<boolean>();
    public userAvailability = new Subject<boolean>();
    public observableUser: Observable<User>;
    public User: User;
    public inboxCount:string;
    private loginUrl: string = '/api/authentication/login';
    private forgotPasswordUrl: string  = '/api/account/forgotpassword';
    private setavailableUrl: string = '/api/useradmin/setavailable';
    private setunavailableUrl: string = '/api/useradmin/setunavailable';
    private changePasswordUrl: string = '/api/users/changepassword';
    private resetPasswordUrl: string = '/api/account/resetpassword';
    private getHistoryUrl: string = '/api/user/gethistory';
    private addHistoryUrl: string = '/api/user/addhistory';
    private getDomainModelCountUrl = '/api/domainModels/count';
    private getWinUserUrl = '/api/account/GetWinUser';
    private logoutUrl = '/api/authentication/logout';
    private refreshTokenUrl: string = '/api/authentication/refreshtoken';    

    constructor(private router: Router, 
        private http: HttpClient,
        private inboxService:InboxService,
        private ngbModalService: NgbModal,
        private modalService:ModalService    
    ) {

        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.observableUser = this.userSubject.asObservable();
        this.userLoggedIn.next(false);
    }

    addHistory(page: string, url: string, entityId: string) {
        const headers = { 'content-type': 'application/json' };
        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user.id;
        const body = JSON.parse(`{"pageName": "${page}", "url": "${url}", "userId": "${userId}", "entityId": "${entityId}"}`);
        this.http.post(this.addHistoryUrl, body, {headers: headers}).subscribe();
    }

    getUserLoggedIn(): Observable<boolean> {
        return this.userLoggedIn.asObservable();
    }

    getUserAvailabiltyStatus(): Observable<boolean> {
        return this.userAvailability.asObservable();
    }

    resetPassword (route: string, body: ResetPassword) {
        const headers = { 'content-type': 'application/json' };
        return this.http.post(this.resetPasswordUrl, body, { 'headers': headers });  
    }

    tokenNotExpired(token: any): boolean {
        if (token) {
            const expires = token.expires;
            const currentDateTime = new Date();
            const expired = (moment(expires).isBefore(currentDateTime));
            
            if(expired) { 
                 this.clearToken();
            }

            return !expired;
        }
        return false;
    }

    tokenExpired(token: any): boolean {
        return !this.tokenNotExpired(token);
    }
    
    refreshTokenNotExpired(token: any): boolean {
        if (token) {
            const expires = token.refreshtokenexpires;
            const currentDateTime = new Date();
            const expired = (moment(expires).isBefore(currentDateTime));           
            if(expired) { 
                 this.clearBackupToken();
            }

            return !expired;
        }
        return false;
    }

    getToken(): any {
        return JSON.parse(localStorage.getItem('multivuetoken'));
    }

    getBackupToken(): any {
        return JSON.parse(localStorage.getItem('multivuetokenbackup'));
    }

    getRolePermissions(): Role[] {
        return JSON.parse(localStorage.getItem('rolePermissions'));
    }

    getRequestCustomer(): string {
        return localStorage.getItem('requestCustomer');
    }

    public clearToken() {
        if (this.getToken() !== null) {
            localStorage.setItem('multivuetokenbackup', JSON.stringify(this.getToken()));
        }
        return localStorage.removeItem('multivuetoken');
    }

    public clearBackupToken() {
        return localStorage.removeItem('multivuetokenbackup');
    }

    clearUser() {
        localStorage.removeItem('rolePermissions');
        localStorage.removeItem('requestCustomer');
        return localStorage.removeItem('user');
    }

    public isAuthenticated(): boolean {
        const token = this.getToken();
        // return a boolean reflecting 
        // whether or not the token is expired
        return this.tokenNotExpired(token);
    }

    forgotPassword(emailAddress: string) {
        const headers = { 'content-type': 'application/json' };
        const body = JSON.parse(`{"email": "${emailAddress}"}`);
        return this.http
                    .post(this.forgotPasswordUrl, body, { 'headers': headers })
                    .pipe(map(event => {
                        return event;
                    }));
    }

    loadHistory(userId: string): Observable<any> {
        return this.http.get<any>(this.getHistoryUrl + '?userId=' + userId);
    }

    getWinUser(): Observable<any> {
      const result = this.http.get<any>(this.getWinUserUrl).pipe(catchError(this.handleError));
      return result;
    }

    login(id: string, secret: string, authType: string) {
      const httpOptions: { headers; observe; } = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            observe: 'response'
        };

        // this line escapes the single \ used by domain users
        //  with the \\ character that it is stored in the database with
      //username = username.replace(/\\/, '\\\\');

      const body = JSON.parse(`{"id": "${id}", "secret": "${secret}", "authenticationType": "${authType}"}`);
      const user = this.http
            .post<User>(this.loginUrl, body, httpOptions)
            .pipe(map(event => {
                if (event.type === HttpEventType.Response) {
                    const user = event.body;
                    const headers = event.headers;
                    const multivuetoken = {
                        token: headers.get('X-Auth-Token'),
                        expires: headers.get('X-Auth-Token-Expires'),
                        refreshtoken: headers.get('X-Auth-RefreshToken'),
                        refreshtokenexpires: headers.get('X-Auth-RefreshToken-Expires')
                    };
                    this.userSubject.next(user);
                    this.userLoggedIn.next(true);
                    this.userAvailability.next(user.available);
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('multivuetoken', JSON.stringify(multivuetoken));             
                    localStorage.setItem('available', user.available.toString());
                    localStorage.setItem('rolePermissions', JSON.stringify(user.accessToViewModels.find(x => x.isDefaultCustomer).roles.map(x => x.rolePermission)));
                    localStorage.setItem('requestCustomer', user.accessToViewModels.find(x => x.isDefaultCustomer).customer.id);
                    localStorage.setItem('instanceName', user.instanceName);
                    //localStorage.setItem('inboxCount', user.inboxCount.toString());
                   
                    this.User = user;
                     this.getDomainModelCount().subscribe({
                         next: result => {
                            if(result > 0)
                             localStorage.setItem('hasDomainModels', JSON.stringify(true));
                            else
                             localStorage.setItem('hasDomainModels', JSON.stringify(true));
                         },
                         error: err => err
                     });
                     this.inboxService.getWorkflowTaskCount().subscribe({
                        next: result => {
                          localStorage.setItem('inboxCount', JSON.stringify(result));
                        },
                        error: err => err
                      });
                     
                
                    return user;
                
            }
            }));            
        return user;
    }

    changePassword(username: string, oldPassword: string, password: string, confirmPassword: string) {
        const headers = { 'content-type': 'application/json' };

        // this line escapes the single \ used by domain users
        //  with the \\ character that it is stored in the database with
       // username = username.replace(/\\/, '\\\\');
        const body = JSON.parse(`{"username": "${username}", "oldPassword" : "${oldPassword}", "password": "${password}", "passwordConfirmation": "${confirmPassword}","rememberMe": false}`);

        return this.http.post(this.changePasswordUrl, body, { headers });
    }

    isUserLoggedIn(): boolean {
        return localStorage.getItem('user') !== null;
    }

    passwordNotExpired(): boolean {
        // the password expired value is set in localStorage in the login component
        return Boolean(localStorage.getItem('passwordExpired')) === false;
    }

    setUserAsAvailable(userId: string): void {
        const headers = { 'content-type': 'application/json' };
        const body = JSON.parse('{}');
        this.http
            .post(`${this.setavailableUrl}?userId=${userId}`, body, { 'headers': headers })
            .subscribe()

        this.userAvailability.next(true);
    }

    setUserAsUnavailable(userId: string): void {
        const headers = { 'content-type': 'application/json' };
        const body = JSON.parse('{}');
        this.http
            .post(`${this.setunavailableUrl}?userId=${userId}`, body, { 'headers': headers })
            .subscribe()
            
        this.userAvailability.next(false);
    }

    logout(redirect = true): void {
        if(localStorage.getItem('multivuetoken')){
            const headers = { 'content-type': 'application/json' };
            this.http.post(this.logoutUrl, {}, { headers }).subscribe();
        }

        localStorage.removeItem('user');
        localStorage.removeItem('multivuetoken');
        localStorage.removeItem('multivuetokenbackup');
        localStorage.removeItem('available');
        localStorage.removeItem('rolePermissions');
        localStorage.removeItem('requestCustomer');
        localStorage.removeItem('instanceName');
        this.userSubject.next(null);
        this.userLoggedIn.next(false);
        this.ngbModalService.dismissAll();
        
        if (redirect) {
            this.router.navigate(['login'], { queryParams: { returnUrl: '/dashboard' } });        
        }
    }

    autoLogout(redirect = true): void {
        if(localStorage.getItem('multivuetoken')){
            const headers = { 'content-type': 'application/json' };
            this.http.post(this.logoutUrl, {}, { headers }).subscribe();
        }

        localStorage.removeItem('user');
        localStorage.removeItem('multivuetoken');
        localStorage.removeItem('multivuetokenbackup');
        localStorage.removeItem('available');
        localStorage.removeItem('rolePermissions');
        localStorage.removeItem('requestCustomer');
        localStorage.removeItem('instanceName');
        this.userSubject.next(null);
        this.userLoggedIn.next(false);
        this.ngbModalService.dismissAll();
        if (redirect) {
            window.location.href = 'login';
        }
    }
    getDomainModelCount(): Observable<number> {
        return this.http.get<number>(this.getDomainModelCountUrl);
    }
    handleError(error: HttpErrorResponse) {
        console.log('Error Message: ' + error.message);
        return throwError(error);
    }
     private passwordExpired(data: Object) {
        const user = localStorage.getItem('user');
        if (data) {
            const passwordExpiryDate = JSON.parse(user).passwordHashExpiryDate;
            const daysLeft = this.calculateDiff(passwordExpiryDate);
            const expiryWarning = `Your password will expire in ${daysLeft} day(s)`;
            if (daysLeft > 0 && daysLeft <= 15) {
                this.modalService.displayWarningModal(expiryWarning);
                localStorage.setItem('passwordExpired', JSON.stringify(false));
                return false;
            }
            else if (daysLeft <= 0) {
                localStorage.setItem('passwordExpired', JSON.stringify(true));
                return true;
              
            }
            localStorage.setItem('passwordExpired', JSON.stringify(false));
            return false;
            
        }
    }
    private calculateDiff(passwordExpiryDate) {
        const currentDate = new Date();
        const expiryDate = new Date(passwordExpiryDate);

        const timeDiff = expiryDate.getTime() - currentDate.getTime();
        return timeDiff <= 0 ? 0 : Math.round(timeDiff / (1000 * 3600 * 24));
    }

    async tryRefreshingTokens(caller: any, successcallback, failurecallback): Promise<boolean> {

        let isRefreshSuccess = false;

        const token = this.getBackupToken();
        this.clearBackupToken();

        if (token === null || !this.refreshTokenNotExpired(token)) {
            this.clearUser();
            if (failurecallback !== null) {
                failurecallback(caller, this);                
            }
        }
        else {
       
            const body = JSON.stringify({ accessToken: token.token, refreshToken: token.refreshtoken });

            const prom = new Promise((resolve, reject) => {
                this.http.post<RefreshTokenResponse>(this.refreshTokenUrl, body, { headers: new HttpHeaders({ "Content-Type": "application/json"})})
                    .subscribe({
                        next: (res: RefreshTokenResponse) => 
                            { 
                                isRefreshSuccess = res.isAuthenticated;
                                if (isRefreshSuccess) {
                                    const multivuetoken = {
                                        token: res.token,
                                        expires: res.tokenValidTo,
                                        refreshtoken: token.refreshtoken,
                                        refreshtokenexpires: token.refreshtokenexpires
                                    };                    
                                    localStorage.setItem('multivuetoken', JSON.stringify(multivuetoken));
                                    if (successcallback != null) {
                                        successcallback(caller, this);
                                    }
                                    resolve('Success'); 
                                } else {
                                    this.clearUser();
                                    resolve('Failed'); 
                                }
                            },
                        error: (_) => 
                            { 
                                this.clearUser();
                                if (failurecallback != null) {
                                    failurecallback(caller, this);
                                }
                                reject('Failed');
                            }
                        });
                    });

                await prom.then(message => console.log("Refreshed token with " + message)).catch(message => console.log("Failed to refresh token"));
            }

        return isRefreshSuccess;
    }
}
