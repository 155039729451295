import { Component, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, OnInit } from '@angular/core';
import { TreeNode } from '../../models/tree-node';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hierarchical-left-pane',
  templateUrl: './hierarchical-left-pane.component.html',
  styleUrls: ['./hierarchical-left-pane.component.scss']
})
export class HierarchicalLeftPaneComponent implements OnInit {
  @Input() items: TreeNode[] = [];
  @Input() searchText: string = '';
  @Output() selectItem: EventEmitter<any> = new EventEmitter<any>();

  filteredItemsList: TreeNode[] = [];
  selectedItem: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef) {
    // Get the current navigation information
    const navigation = this.router.getCurrentNavigation();

    // Check if there was a previous route
    if (navigation && navigation.previousNavigation) {
      const fromUrl = navigation.previousNavigation.finalUrl.toString();

      // If the previous route was not the exempt route, clear localStorage
      if (fromUrl !== '/matches/matchdetails' && fromUrl !== '/matches/matchanalysisviewcombination' && 
        fromUrl !== '/relationships/relationshipmatchdetails' && fromUrl !== '/relationships/viewcombination') {
        localStorage.setItem('selectedLeftPaneItem', '');
      }
    } else {
      // If there is no previous navigation, clear localStorage
      localStorage.setItem('selectedLeftPaneItem', '');
    }
  }

  ngOnInit() {
    const savedItem = localStorage.getItem('selectedLeftPaneItem');
    if (savedItem)
      this.selectedItem = JSON.parse(savedItem);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && this.items && this.items.length > 0) {
      this.expandToSelectedNode(this.items, this.selectedItem);
    }
    this.applySearchFilter();
  }

  applySearchFilter() {
    if (this.searchText) {
      this.filteredItemsList = this.filterItems(this.items, this.searchText.toLowerCase());
    } else {
      this.filteredItemsList = this.items;
    }
  }

  filterItems(items: TreeNode[], searchText: string): TreeNode[] {
    return items.reduce((filtered, item) => {
      const match = item.name.toLowerCase().includes(searchText);

      let filteredChildren = [];
      if (item.children?.length > 0) {
        filteredChildren = this.filterItems(item.children, searchText);
      }

      if (match) {
        // If the parent matches, include all its children unconditionally
        filtered.push({
          ...item,
          children: item.children // Keep all children if parent matches
        });
      } else if (filteredChildren.length > 0) {
        // If children match but the parent doesn't, still include the parent but only with matching children
        filtered.push({
          ...item,
          children: filteredChildren
        });
      }

      return filtered;
    }, []);
  }

  toggleItem(item: TreeNode) {
    item.isExpanded = !item.isExpanded;
    this.changeDetectorRef.detectChanges();
  }

  onItemSelection(item: any, parentNode: TreeNode) {
    this.selectedItem = item;
    localStorage.setItem('selectedLeftPaneItem', JSON.stringify(item));

    const parent = parentNode.name
    this.selectItem.emit({ item, parent });
  }

  isItemSelected(item: any) {
    return this.selectedItem?.id === item?.id;
  }

  private expandToSelectedNode(items: any[], selectedItem: any) {
    if (!selectedItem) return;

    items.forEach(item => {
      if (item.children && item.children.length > 0) {
        if (item.children.some(child => child.name === selectedItem.name)) {
          item.isExpanded = true;
        }
      }
    });
  }
}
