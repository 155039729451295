import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { MatchComparisonComponent } from './components/match-comparison/match-comparison.component';
import { SearchResultsGridComponent } from './components/search-results-grid/search-results-grid.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CustomGridComponent } from './custom-grid/custom-grid.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxPaginationModule } from 'ngx-pagination';
import { registerLocaleData } from '@angular/common';
import localeGB from '@angular/common/locales/en-GB';
import { UnsavedChangesModalPopupComponent } from './unsaved-changes-modal-popup/unsaved-changes-modal-popup.component';
import { CustomPaginationComponent } from './custom-pagination/custom-pagination.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { CustomDatePickerComponent } from './components/custom-date-picker/custom-date-picker.component';
import { LeftPaneComponent } from './components/left-pane/left-pane.component';
import { HierarchicalLeftPaneComponent } from './components/hierarchical-left-pane/hierarchical-left-pane.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
defineLocale('en-gb', enGbLocale);

// PrimeNg Civica System Modules
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ModalModule } from 'primeng/modal';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';
import { ListboxModule } from 'primeng/listbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup'
import { ToastModule } from 'primeng/toast';
import { PasswordModule } from 'primeng/password';
import { SliderModule } from 'primeng/slider';
import { AuthorizeDirective } from './directives/authorize.directive';
import { TableModule } from 'primeng/table';
import { ErrorModalComponent } from './modal-popup/error-modal/error-modal.component';
import { InfoModalComponent } from './modal-popup/info-modal/info-modal.component';
import { WarningModalComponent } from './modal-popup/warning-modal/warning-modal.component';
import { ModalService } from './services/modal.service';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { SplitButtonModule } from 'primeng/splitbutton';
import { FieldsetModule } from 'primeng/fieldset';
import { ChipsModule } from 'primeng/chips'

import { CustomerService } from './services/customer.service';
import { UtilityService } from './services/utility.service';
import { PreventCopyPasteDirective } from './directives/preventCopyPaste.directive';
import { EnumToStringPipe } from './directives/EnumToString.pipe';
import { EditEntityAttributesComponent } from './components/edit-entity-attributes/edit-entity-attributes.component';
import { MatchReevaluationModalComponent } from './components/match-reevaluation-modal/match-reevaluation-modal.component';

defineLocale('en-gb', enGbLocale);

registerLocaleData(localeGB, 'en-GB');

@NgModule({
  declarations: [
    ContentHeaderComponent, CustomGridComponent, UnsavedChangesModalPopupComponent,
    CustomPaginationComponent, CustomDatePickerComponent, LeftPaneComponent, HierarchicalLeftPaneComponent,
    AuthorizeDirective, ErrorModalComponent, PreventCopyPasteDirective, InfoModalComponent, WarningModalComponent,EnumToStringPipe,
    MatchComparisonComponent, SearchResultsGridComponent,EditEntityAttributesComponent, MatchReevaluationModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    AutocompleteLibModule,
    NgxDatatableModule,
    MatTreeModule,
    MatIconModule,
    DragDropModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    ButtonModule,
    CardModule,
    CalendarModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    MultiSelectModule,
    ModalModule,
    MessagesModule,
    PaginatorModule,
    RadioButtonModule,
    TabViewModule,
    NgbModule,
    AccordionModule,
    DialogModule,
    RippleModule,
    ListboxModule,
    ConfirmPopupModule,
    ToastModule,
    PasswordModule,
    SliderModule,
    TableModule,
    FileUploadModule,
    SplitButtonModule,
    FieldsetModule,
    ChipsModule
  ],
  exports: [
    CommonModule,
    ContentHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    AutocompleteLibModule,
    NgxDatatableModule,
    CustomGridComponent,
    SearchResultsGridComponent, 
    MatTreeModule,
    MatIconModule,
    DragDropModule,
    NgxPaginationModule,
    CustomPaginationComponent,
    BsDatepickerModule,
    CustomDatePickerComponent,
    LeftPaneComponent,
    HierarchicalLeftPaneComponent,
    ButtonModule,
    CardModule,
    CalendarModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    MultiSelectModule,
    ModalModule,
    MessagesModule,
    PaginatorModule,
    RadioButtonModule,
    TabViewModule,
    NgbModule,
    AccordionModule,
    DialogModule,
    RippleModule,
    ListboxModule,
    PasswordModule,
    SliderModule,
    AuthorizeDirective,
    PreventCopyPasteDirective,
    ConfirmPopupModule,
    ToastModule,
    TableModule,
    FileUploadModule,
    SplitButtonModule,
    FieldsetModule,
    ChipsModule,
    EnumToStringPipe,
    MatchComparisonComponent,
    EditEntityAttributesComponent,
    MatchReevaluationModalComponent
  ]
  
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: ModalService, ConfirmationService, DialogService, CustomerService, UtilityService
         
        }
      ]
    }
  }
}
