import { AuditComponent } from './audit/audit/audit.component';
import { RolesGuard } from './_helpers/roles-guard.module';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { LoginGuard } from './_helpers/login-guard.module';
import { AutoLoginGuard } from './_helpers/autologin-guard.module';
import { AuthenticationGuard } from './_helpers/authentication-guard.module';
import { LoginComponent } from './account/login.component';
import { AzureLoginComponent } from './account/azure-login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppStatusComponent } from './dashboard/app-status/app-status.component';
import { TooManyRequestsComponent } from './dashboard/too-many-requests/too-many-requests.component';
import { ShareComponent } from './share/share/share.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { MsalGuard } from '@azure/msal-angular';

export const routes: Routes = [
  {
    path: 'forgot-password', 
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password', 
    component: ResetPasswordComponent 
  },
  { 
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full' 
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'autologin',
    component: AzureLoginComponent,
    canActivate: [AutoLoginGuard, MsalGuard]
  },
  {
    path: 'dashboard',
    component: AppStatusComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'workflow',
    loadChildren: () => import('./workflow/workflow.module').then(m => m.WorkflowModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'entity',
    loadChildren: () => import('./entity/entity.module').then(m => m.EntityModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'jobs',
    loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'sources',
    loadChildren: () => import('./sources/sources.module').then(m => m.SourcesModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'securityconfiguration',
    loadChildren: () => import('./security-configuration/security-configuration.module').then(m => m.SecurityConfigurationModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'configuration',
    loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'domainmodel',
    loadChildren: () => import('./domain-model/domain-model.module').then(m => m.DomainModelModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'matches',
    loadChildren: () => import('./matches/matches.module').then(m => m.MatchesModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'relationships',
    loadChildren: () => import('./relationships/relationships.module').then(m => m.RelationshipsModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'share',
    component: ShareComponent, 
    loadChildren: () => import('./share/share.module').then(m => m.ShareModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'audit',
    component: AuditComponent, 
    loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent, 
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'enrichment',
    loadChildren: () => import('./enrichment/enrichment.module').then(m => m.EnrichmentModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'types',
    loadChildren: () => import('./types/types.module').then(m => m.TypesModule),
    canActivate: [AuthenticationGuard, RolesGuard]
  },
  {
    path: 'too-many-requests',
    component: TooManyRequestsComponent, 
    canActivate: [AuthenticationGuard]
  },
  { 
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full' 
  },
  { 
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full' 
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, 
      {
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
