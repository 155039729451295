import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginationComponent implements OnInit {

  constructor() { }

  @Output() onPageChanged: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
  }

}
