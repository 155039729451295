import { Component, Input, OnInit } from "@angular/core";
import { Page } from "src/app/shared/models/page";
import { PermissionFunctions } from "src/app/shared/models/permission-functions";
import { ISearchCriteria } from "../../models/Search/search-criteria";
import { ISearchEntitiesCriteria } from "../../models/Search/search-entities-criteria";
import { SearchService } from "../../services/search.service";
import { CommonFunctionsService } from "../../services/common-functions.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DateValidatorService } from "../../custom-validators/date-validator.service";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { Router } from "@angular/router";
import { LazyLoadEvent } from "primeng/api";
import { ISearchCriteriaRequest } from "src/app/entity/models/search-criteria-request";
import { RelationshipModel } from "src/app/entity/models/relationship-model";
import { MyRecordsService } from "src/app/entity/my-records.service";
import { ModalService } from "../../services/modal.service";
import { RelationshipModelService } from "src/app/domain-model/relationship-model/relationship-model.service";
import { AddRelationshipComponent } from "src/app/entity/add-relationship/add-relationship.component";
declare let $: any;

@Component({
    selector: 'app-search-results-grid',
    templateUrl: './search-results-grid.component.html',
})
export class SearchResultsGridComponent implements OnInit {
    @Input() entityType: string;
    @Input() searchText: string
    @Input() pageNamesForAuthorization: PermissionFunctions[];
    @Input() isAdvancedSearch: boolean = false;
    @Input() advancedSearchParameters: ISearchEntitiesCriteria;
    @Input() relationshipSearchRequest: any;
    @Input() entityData: any;

    requestSearchEntities: ISearchEntitiesCriteria;
    page = new Page()
    searchResultsResponseData: any;
    referenceValues: any;
    columns: any[] = [];
    rows: any[] = [];
    loading: boolean;
    showSearchResultsPopup = true;
    language: string = navigator.language;
    relationshipSearch: boolean = false;
    showResultsAsVirtuals = true;
    totalRecords: number;
    searchCriteriaRequest:any;
    errorMessage: any;
    selectedEntityType:string;
    reloadGrid:boolean=false;
    reloadVirtualGrid:boolean=false;
    public entityTypes: { id: string; name: string }[];
    public fullAccess = 'full';
    public readOnlyAccess = 'read-only';
    showSearchResults= true;
    domainModelFields: any;

    constructor(private searchService: SearchService,
        private commonFunctionsService: CommonFunctionsService,
        private activeModal: NgbActiveModal,
        // this is not unused, it is being used in html template
        private dateValidatorService: DateValidatorService,
        private localeService: BsLocaleService,
        private router: Router,
        private _myRecordsService:MyRecordsService,
        private primeNgModalService:ModalService,
        private relationshipModelService:RelationshipModelService,
        private modalService:NgbModal) {
    }

    ngOnInit(): void {
        if (navigator.language === 'en-GB') {
            this.localeService.use('en-gb');
        }
        else {
            this.localeService.use('en');
        }
        this.showSearchResultsPopup = true;
        if (this.relationshipSearchRequest) {
            this.reloadGrid = false;
            this.reloadVirtualGrid =false;
            this.relationshipSearch = true;
            this.searchCriteriaRequest = new ISearchCriteriaRequest();
            this.searchCriteriaRequest.relationshipEntitySearchViewModel = new RelationshipModel();
            this.searchCriteriaRequest.isAdvancedSearch = false;
            this.searchCriteriaRequest.relationshipEntitySearchViewModel.OriginalEntityId = this.relationshipSearchRequest.originalEntityId,
              this.searchCriteriaRequest.relationshipEntitySearchViewModel.OriginalEntityType = this.relationshipSearchRequest.originalEntityType,
              this.searchCriteriaRequest.relationshipEntitySearchViewModel.SelectedRelationshipModel = this.relationshipSearchRequest.selectedRelationshipModel
              this.getSearchParameter(this.searchCriteriaRequest);
        this.showSearchResults = false;
            }
          else
          {
            this.showSearchResults = true;
            this.reloadGrid = false;
            this.reloadVirtualGrid = false;
            this.prepareSearchCriteriaObject();
            this.getSearchResult();
          }
    }

    getSearchParameter(searchCriteriaRequest: ISearchCriteriaRequest) {
        this.searchService.postSearchCriteria(searchCriteriaRequest).subscribe((data) => {
          if (this.relationshipSearch == true) {
            data = data.searchEntitiesCriteriaViewModel;
            this.entityTypes = data.entityTypes.map(x => ({
                id: x,
                name: x
              }));
          }
   
      });
    }
    populateEntityTypes() {
        this._myRecordsService.getEntityTypes().subscribe({
          next: domains => {
            this.entityTypes = domains.map(x => ({
              id: x,
              name: x
            }));
          }
        });
      }
    
      onSearch(event) {
        this.showSearchResults = true;
        if (this.selectedEntityType && event.target.value) {
            this.entityType = this.selectedEntityType;
            this.searchText = event.target.value;
            this.prepareSearchCriteriaObject();
            this.getSearchResult();
        }
        else {
          this.primeNgModalService.displayInfoModal("Entity type and search text is required");
        }
      }
      onAdvanceSearch(event) {
    
        this.searchCriteriaRequest = {
            originalEntityId: this.relationshipSearchRequest.originalEntityId,
            originalEntityType: this.relationshipSearchRequest.originalEntityType,
            selectedRelationshipModel: this.relationshipSearchRequest.selectedRelationshipModel
          };
      
          this.router.navigate(['./entity/search'], { state: { responseData: this.searchCriteriaRequest } });
      }
    onTabChange(event) {
        if (event.index == 0) {
            this.showResultsAsVirtuals = true;
        }
        else if (event.index == 1) {
            this.showResultsAsVirtuals = false
        }
        this.ngOnInit();
    }

    prepareSearchCriteriaObject(event = null) {
        this.showSearchResults = true;
        if (this.isAdvancedSearch == true) {
            if (event) {
                this.advancedSearchParameters.searchCriteria.showResultsAsVirtuals = this.showResultsAsVirtuals;
                this.requestSearchEntities = this.advancedSearchParameters;
                this.requestSearchEntities.page = (event.first / event.rows)
                this.requestSearchEntities.pageSize = event.rows;
                this.requestSearchEntities.sortOrder =  event.sortOrder == -1 ? "desc" : "asc";
                this.requestSearchEntities.sortColumn = event.sortField ? this.commonFunctionsService.formatText(event.sortField) : "VirtualId";
                }
            else{
            this.advancedSearchParameters.searchCriteria.showResultsAsVirtuals = this.showResultsAsVirtuals;
            this.requestSearchEntities = this.advancedSearchParameters;
            }
        }
        
        else {
            const searchCriteriaObj: ISearchCriteria = {
                entityType: this.entityType,
                sources: [],
                isAdvancedSearch: false,
                advancedSearchParameters: [],
                searchText: this.searchText,
                showResultsAsVirtuals: this.showResultsAsVirtuals
            };
            if (event) {
                this.requestSearchEntities = {
                    searchCriteria: searchCriteriaObj,
                    page: (event.first / event.rows),
                    pageSize: event.rows,
                    sortOrder: event.sortOrder == -1 ? "desc" : "asc",
                    sortColumn: event.sortField ? this.commonFunctionsService.formatText(event.sortField) : "VirtualId",
                };
            }
            else {
                this.requestSearchEntities = {
                    searchCriteria: searchCriteriaObj,
                    page: this.page.page,
                    pageSize: this.page.pageSize,
                    sortOrder: 'desc',
                    sortColumn: 'VirtualId',
                };

            }
        }
    }
    onSearchSubmitFromGrid(event: LazyLoadEvent) {
        if(this.reloadGrid){
        this.prepareSearchCriteriaObject(event);
        this.getSearchResult();
        }else{
            this.reloadGrid = true;
        }

    }
    onVirtualSearchSubmitFromGrid(event: LazyLoadEvent) {
                if(this.reloadVirtualGrid){
                this.prepareSearchCriteriaObject(event);
                this.getSearchResult();
                }else{
                    this.reloadVirtualGrid = true;
                }
        
            }
    getSearchResult() {
        if (this.requestSearchEntities) {
            this.page.page = this.requestSearchEntities.page;
            this.getPagedData(this.page.page);
        }
    }

    getPagedData(page: number) {
        this.requestSearchEntities.page = page;

        this.searchService.postSearchResult(this.requestSearchEntities).subscribe((response) => {
            this.searchResultsResponseData = response;
            if (this.searchResultsResponseData) {
                this.reloadGrid = true;
                this.reloadVirtualGrid = true;  
                this.columns = [];
                this.gridColumnsDataBinding(this.searchResultsResponseData.searchResult.fields);
                const rows = this.gridRowsDataBinding(this.searchResultsResponseData.searchResult.entities);
                this.rows = rows;
                this.totalRecords = parseInt(this.searchResultsResponseData.totalRecordsCount);;
            }
        });
    }

    gridColumnsDataBinding(searchResultColumns: any) {
        if (searchResultColumns) {
            const sortedColumns = this.commonFunctionsService.getSortedColumnName(searchResultColumns);
            sortedColumns.forEach(x => {
                this.columns.push({
                    name: x.headerName,
                    prop: x.field,
                    sortable: false,
                    canAutoResize: true,
                    draggable: true,
                    resizeable: true
                });
            });
            this.columns.push({
                name: "Actions",
                prop: 'Actions',
                sortable: false,
                canAutoResize: true,
                draggable: false,
                resizeable: true
            });
        }
    }

    gridRowsDataBinding(searchResultRows: any) {
        const rowsArray = [];
        if (searchResultRows) {
            searchResultRows.forEach(x => {
                const row: any = this.commonFunctionsService.replaceDotObjectKeysWithUnderscore(x.fields);
                row.id = x.id;
                row.isInBasket = x.isInBasket;
                row.isRestricted = x.isRestricted;
                row.entityType = x.entityType;
                row.sourceSystem = x.sourceSystem;
                row.identifier = x.identifier
                rowsArray.push(row);
            });
            return rowsArray;
        }
    }


    onSelectView(row: any) {
        const viewEntityModel = {
            Id: row.id,
            EntityType: this.entityType,
            IncludeEmptyFields: false
        }

        const response = {
            virtualId: viewEntityModel.Id,
            entityType: this.entityType,
            currentPage: "view",
            navigatedFrom: "Search",
            componentName: "searchResultsGrid"
        };

        this.closeModal();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./entity/entity'], {
            state: { responseData: response },
        });
    }

    onAddToMyRecords(value) {
        this.searchService.addToMyRecords(value.id, this.entityType).subscribe({
            next: () => {
                this.getSearchResult();
            },
        });
    }




    
    addRelationship(row: any) {
     const relationship = {
        entity1Identifier: this.relationshipSearchRequest.originalEntityId,
        entity1SourceSystem:this.relationshipSearchRequest.originalEntitySource,
        entity1EntityType:this.relationshipSearchRequest.originalEntityType,
        entity2Identifier:row.identifier,
        entity2SourceSystem:row.sourceSystem,
        entity2EntityType:row.entityType,
        relationshipModel:this.relationshipSearchRequest.selectedRelationshipModel
        }
                this.showSearchResultsPopup = false;
                this.activeModal.dismiss();
                const modalRef = this.modalService.open(AddRelationshipComponent, { centered: true });
                modalRef.componentInstance.relationship = relationship;
                modalRef.componentInstance.entityData = this.entityData;

    }
    
    getPageNamesForAuthorization() {
        return [PermissionFunctions.Entities];
    }
    onClose() {
        this.closeModal();
        $('#searchResultsModalPopup').modal('hide');
    }

    closeModal() {
        this.activeModal.close('Modal Closed');
    }
}
