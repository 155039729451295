import { GridParametersWithFilter } from './grid-parameters-with-filter';
import { MatchStatusEnum } from './match-status.enum';
import { TreeNode } from './tree-node';

export class SearchMatchesFilterModel extends GridParametersWithFilter {
    virtualEntityId: string;
    entityType: string;
    matchRule: string;
    threshold: number | null;
    matchStatus: MatchStatusEnum | null;
    scoringDetailsHash: number;
    scoringDetailsId: string;
    scoringCombinationDescription: string;
    scoringCombinationAccepted: boolean;
    scoringCombinationRejected: boolean;
    entityTypes: string[];
    matchRules: string[];
    matchSummaryPage: number;
    score: string;
    rowColor: string;
    combinationActions: any;
    entityTypesAndMatchRulesList: TreeNode[];
    constructor() {
        super();
        this.matchRules = [];
        this.entityTypesAndMatchRulesList = [];
    }
}