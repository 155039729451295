<div class="card">
    <div class="card-header">
        <h3 class="card-title">{{childGridTitle}}</h3>
    </div>
    <div class="mb-2">

        <ngx-datatable #customtable class="bootstrap" [rows]="childGridRows" [columnMode]="'force'" [rowHeight]="'auto'"
            [headerHeight]="50" [footerHeight]="50" [externalPaging]="true" [count]="childPage.totalElements"
            [offset]="childPage.page" [limit]="childPage.pageSize" (page)="setPage($event)"
            [loadingIndicator]="isLoading" [externalSorting]="true" (sort)="onSort($event)">

            <ngx-datatable-column *ngFor="let column of childGridColumns; let i = index;" [name]="column.name"
                [prop]="column.prop" [sortable]="column.sortable" [canAutoResize]="column.canAutoResize"
                [draggable]="column.draggable" [resizeable]="column.resizeable" [width]="column.width">
                <ng-template let-rowIndex="rowIndex" let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span *ngIf="column.columntype ==='button'">
                        <span *ngIf="childGridTitle ==='Jobs'">
                            <input type="button" class="btn btn-info btn-sm mr-2" value="View"
                                (click)='onActionButtonClick(row,"View",rowIndex)'>
                            <input type="button" class="btn btn-danger btn-sm mr-2"
                                *ngIf="row.jobState === 'NotStarted' || row.jobState === 'Started'" value="Cancel"
                                (click)='onActionButtonClick(row,"Cancel",rowIndex)'>
                        </span>
                        <span *ngIf="childGridTitle ==='Roles'">
                            <input type="button" class="btn btn-info btn-sm mr-2" value="View"
                                (click)='onActionButtonClick(row,"View",rowIndex)'>
                            <input type="button" class="btn btn-danger btn-sm mr-2" value="Delete"
                                (click)='onActionButtonClick(row,"Delete",rowIndex)'>
                        </span>
                        <span *ngIf="childGridTitle ==='Search Results'">
                            <input *ngIf="row.scoringCombinationResult !== 'Accept'" type="button"
                                class="btn btn-success btn-sm mr-2" value="Accept"
                                (click)='onActionButtonClick(row,"Accept",rowIndex)'
                                authorize [pages]="pageNamesForAuthorization" [accessType] = "fullAccess">
                            <input *ngIf="row.scoringCombinationResult !== 'Reject'" type="button"
                                class="btn btn-danger btn-sm mr-2" value="Reject"
                                (click)='onActionButtonClick(row,"Reject",rowIndex)'
                                authorize [pages]="pageNamesForAuthorization" [accessType] = "fullAccess">
                            <input type="button" class="btn btn-outline btn-sm mr-2" value="Reset"
                                (click)='onActionButtonClick(row,"Reset",rowIndex)'
                                authorize [pages]="pageNamesForAuthorization" [accessType] = "fullAccess">
                        </span>
                        <span *ngIf="childGridTitle ==='Relationship Search Results'">
                            <input type="button" class="btn btn-primary btn-sm mr-2" value="View"
                                (click)='onActionButtonClick(row,"View",rowIndex)'>
                            <input type="button" class="btn btn-success btn-sm mr-2" value="Accept"
                                (click)='onActionButtonClick(row,"Accept",rowIndex)'>
                            <input *ngIf="row.matchStatus !== 1" type="button" class="btn btn-danger btn-sm mr-2"
                                value="Reject" (click)='onActionButtonClick(row,"Reject",rowIndex)'>
                        </span>
                    </span>
                    <span *ngIf="column.columntype ==='date'">
                        {{ language === "en-US" ? (value | date: 'MM/dd/yyyy, h:mm a': 'UTC') : value | date: 'short' : 'UTC'}} 
                    </span>
                    <span *ngIf="column.prop == 'jobType'">
                        {{ jobTypeEnum[value] }}
                    </span>
                    <span *ngIf="column.columntype !=='date' && column.columntype !=='button' && column.prop !=='jobType'">
                        {{value}}
                    </span>                    
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>