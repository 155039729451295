import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-edit-entity-attributes',
  templateUrl: './edit-entity-attributes.component.html',
  styleUrl: './edit-entity-attributes.component.scss'
})
export class EditEntityAttributesComponent {
  @Input() domainModelFields: any[] = [];
  @Input() submitTrigger: boolean;
  @Input() viewEntityPage: boolean;
  @Output() updatedAttributes  = new EventEmitter<any[]>();
  @Output() isDirty  = new EventEmitter<boolean>();
  level = 0;
  ngOnChanges(changes: SimpleChanges) {
    if (changes.submitTrigger) {
      this.sendUpdatedAttributes();
    }
  }
  addRemoveMultipleAttribute(
    multiMultiAttributes: any,
    addRemoveValue: any,
    parent: any,
    entityfields: any,
    attributeName: null
  ) {

    if (multiMultiAttributes.fieldName == null) {
      multiMultiAttributes.fieldName = attributeName;

    }

    // don't set fieldname to multimulti array. It causes problem deserializing in API
    const fieldName = multiMultiAttributes.isMultiple ? null : multiMultiAttributes.fieldName;
    const test = JSON.parse(
      JSON.stringify({
        fieldName: fieldName,
        fullFieldName: multiMultiAttributes.fullFieldName,
        fields: entityfields,
        NameParent: multiMultiAttributes.fieldName + '1',
        value: entityfields,
      })
    );
    for (let i = 0; i < this.domainModelFields.length; i++) {
      var element = this.domainModelFields[i];
      this.level = 0;
      var result = this.searchTree(element, multiMultiAttributes);
      if (result != null) {
        let dummyFieldAttribute = '';
        for (let m = 0; m < this.level; m++) {
          dummyFieldAttribute = dummyFieldAttribute + 'fields';
        }
        if (dummyFieldAttribute == '') {
          if (addRemoveValue == 'add') {
            if (this.domainModelFields[i].value === undefined) {
              this.domainModelFields[i].value = [];
            }
            test.NameParent =
              multiMultiAttributes.fieldName +
              this.domainModelFields[i].value.length;
            if (multiMultiAttributes.isString) {
              test.$type = "DataAttributeString"
            } else if (multiMultiAttributes.isInteger) {
              test.$type = "DataAttributeInteger"
            } else if (multiMultiAttributes.isDecimal) {
              test.$type = "DataAttributeDecimal"
            } else if (multiMultiAttributes.isDate) {
              test.$type = "DataAttributeDate"
            } else if (multiMultiAttributes.isDateTime) {
              test.$type = "DataAttributeDateTime"
            } else if (multiMultiAttributes.isUrl) {
              test.$type = "DataAttributeUrl"
            } else if (multiMultiAttributes.isMultiple) {
              test.$type = "DataAttributeCollection"
            } else if (multiMultiAttributes.isMultiValue || multiMultiAttributes.multiVueDomainTypeName == "DataAttributeListModel") {
              test.$type = "DataAttributeList"
            } else if (multiMultiAttributes.isReference) {
              test.$type = "DataAttributeReference"
            }
            this.domainModelFields[i].value.push(test);
          } else {
            this.domainModelFields[i].value?.splice(addRemoveValue, 1);
          }
        } else {
          for (
            let n = 0;
            n < this.domainModelFields[i][dummyFieldAttribute].length;
            n++
          ) {
            var element = this.domainModelFields[i][dummyFieldAttribute][n];
            this.level = 0;
            var result = this.searchTree(
              element,
              multiMultiAttributes
            );
            if (result != null) {
              let dummyFieldAttributeChild = '';
              for (let m = 0; m < this.level; m++) {
                dummyFieldAttributeChild = dummyFieldAttributeChild + 'fields';
              }
              if (dummyFieldAttributeChild == '') {
                if (parent.NameParent != undefined) {
                  //for multi multi attribute in multi multi fields
                  const FieldsLevel = parent.NameParent.charAt(
                    parent.NameParent.length - 1
                  );
                  if (addRemoveValue == 'add') {
                    if (
                      this.domainModelFields[i].value[parseInt(FieldsLevel)][
                        dummyFieldAttribute
                      ][n].value === undefined
                    ) {
                      this.domainModelFields[i].value[parseInt(FieldsLevel)][
                        dummyFieldAttribute
                      ][n].value = [];
                    }
                    this.setParentFieldName(
                      test,
                      multiMultiAttributes,
                      i,
                      FieldsLevel,
                      dummyFieldAttribute,
                      n
                    );
                    this.domainModelFields[i].value[parseInt(FieldsLevel)][
                      dummyFieldAttribute
                    ][n].value.push(test);
                  } else {
                    this.domainModelFields[i].value[parseInt(FieldsLevel)][
                      dummyFieldAttribute
                    ][n].value.splice(addRemoveValue, 1);
                  }
                } else {
                  if (addRemoveValue == 'add') {
                    if (
                      this.domainModelFields[i][dummyFieldAttribute][n]
                        .value === undefined
                    ) {
                      this.domainModelFields[i][dummyFieldAttribute][n].value =
                        [];
                    }
                    this.domainModelFields[i][dummyFieldAttribute][
                      n
                    ].value.push(test);
                  } else {
                    this.domainModelFields[i][dummyFieldAttribute][
                      n
                    ].value.splice(addRemoveValue, 1);
                  }
                }
              } else {
                if (addRemoveValue == 'add') {
                  if (
                    this.domainModelFields[i][dummyFieldAttribute][n][
                      dummyFieldAttributeChild
                    ].value === undefined
                  ) {
                    this.domainModelFields[i][dummyFieldAttribute][n][
                      dummyFieldAttributeChild
                    ].value = [];
                  }
                  this.domainModelFields[i][dummyFieldAttribute][n][
                    dummyFieldAttributeChild
                  ].value.push(test);
                } else {
                  this.domainModelFields[i][dummyFieldAttribute][n][
                    dummyFieldAttributeChild
                  ].value.splice(addRemoveValue, 1);
                }
              }
            }
          }
        }
      }
    }
  }
  private setParentFieldName(
    test: any,
    multiMultiAttributes: any,
    i: number,
    FieldsLevel: any,
    dummyFieldAttribute: string,
    n: number
  ) {
    test.NameParent =
      multiMultiAttributes.fieldName +
      this.domainModelFields[i].value[parseInt(FieldsLevel)][
        dummyFieldAttribute
      ][n].value.length;
    if (multiMultiAttributes.isString) {
      test.$type = "DataAttributeString"
    } else if (multiMultiAttributes.isInteger) {
      test.$type = "DataAttributeInteger"
    } else if (multiMultiAttributes.isDecimal) {
      test.$type = "DataAttributeDecimal"
    } else if (multiMultiAttributes.isDate) {
      test.$type = "DataAttributeDate"
    } else if (multiMultiAttributes.isDateTime) {
      test.$type = "DataAttributeDateTime"
    } else if (multiMultiAttributes.isUrl) {
      test.$type = "DataAttributeUrl"
    } else if (multiMultiAttributes.isMultiValue) {
      test.$type = "DataAttributeList"
    } else if (multiMultiAttributes.isMultiple) {
      test.$type = "DataAttributeCollection"
    } else if (multiMultiAttributes.isReference) {
      test.$type = "DataAttributeReference"
    }

  }
  
  searchTree(element, attributes) {
    if (element.fieldName === attributes.fieldName || element.fieldName === attributes.placeholder) {
      if (attributes.fullFieldName) {
        if (element.fullFieldName === attributes.fullFieldName) {
          return element;
        }
      }
      else {
        return element;
      }

    } else if (element.fields != null) {
      let i;
      let result = null;
      for (i = 0; result == null && i < element.fields.length; i++) {
        result = this.searchTree(element.fields[i], attributes);
      }
      if (result != null) {
        this.level++;
      }
      return result;
    }
    return null;
  }
  sendUpdatedAttributes() {
    const updatedAttributes = this.domainModelFields; // Assuming the updated values are here

    // Emit the updated attributes to the parent
    this.updatedAttributes.emit(updatedAttributes);
  }
  setDirty(){
    this.isDirty.emit(true);

  }

}
