import { Injectable } from '@angular/core';
import { AuditFilterCriteria } from './models/audit-filter-criteria';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuditService {
    auditUrl = '/api/audit';
    constructor(private http: HttpClient) { }

    searchAudit(auditFilterCriteria: AuditFilterCriteria): Observable<any> {
        const headers = { 'content-type': 'application/json' };
        return this.http.post<any>(this.auditUrl + '/search', auditFilterCriteria, { headers })
            .pipe(catchError(this.handleError));
    }

    getSystemUsers(): any {
        return this.http.get(this.auditUrl + '/getsystemusers').pipe(catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse) {
        console.log('Error Message: ' + error.message);
        return throwError(error);
    }
}