<app-header *ngIf="user"></app-header>

<div class="container-fluid">
  <div class="row">
    <app-sidebar *ngIf="user" (menuOpenChange)="updateMenuOpen($event)"></app-sidebar>
      <main role="main"  class="col-md-12  px-md-4" [ngClass]="{ 'main-content-wrapper': user!==null,'open': (menuOpen && user!==null)}">
      
      <router-outlet></router-outlet>
    </main>
  </div>
</div>

<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">Your session is about to expire</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
         {{idleState}}
        <div class="row mrgbtm">
          <div class="col-md-6">
              <p-button label="Stay" (click)="stay()" class=" pull-left"></p-button>
          </div>
          <div class="col-md-6">
              <p-button label="Logout" (click)="logout()" class=" p-button-ghost pull-right"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-modal [style]="{width: '30vw'}" key="positionDialog" position="top"  rejectButtonStyleClass="p-button-outlined"></p-modal> 
