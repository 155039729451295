import { AccountService } from './account/account.service';
import { Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Keepalive } from '@ng-idle/keepalive';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { User } from './account/models/user';
import { MsalService } from '@azure/msal-angular';
import { PrimeNGConfig } from 'primeng/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnDestroy, OnInit {
  title = 'MultiVue';
  user: User;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  public modalRef: BsModalRef;
  menuOpen: boolean = true; // Set to true to have the menu open by default

  @ViewChild('childModal', {static: false}) childModal: ModalDirective;

  constructor(
              private accountService: AccountService,
              private idle: Idle, 
              private keepalive: Keepalive,
              private router: Router,
              private modalService: BsModalService, 
              private authService: MsalService,
              private primengConfig: PrimeNGConfig,
              private ngbModalService: NgbModal) {

      idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.';
        this.reset();
      });
      
      idle.onTimeout.subscribe(() => {
        this.idleState = 'Session expired';
        this.timedOut = true;
        this.accountService.autoLogout(false);
        this.hideChildModal();
        this.ngbModalService.dismissAll();
        this.router.navigate(['/login']);
      });
      
      idle.onIdleStart.subscribe(() => {
          localStorage.setItem('timeout','true');
          this.idleState = 'You\'ve gone idle!';
          this.childModal.show();
          
      });
      
      idle.onTimeoutWarning.subscribe((countdown: string): void => {
        this.idleState = 'You will be logged out in ' + countdown + ' seconds';
      });

      this.accountService.observableUser.subscribe(x => {
          this.user = x;
          if (this.user) {
            this.setupIdleTimer();
          } else {
            this.idle.stop();
          }
      });

      // sets the ping interval to 15 seconds
      this.keepalive.interval(15);
      this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
      this.reset();
    }

    updateMenuOpen(menuOpen: boolean) {
      this.menuOpen = menuOpen;
    }  

  private setupIdleTimer() {
    this.idle.setIdle(900);
    this.idle.setTimeout(60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.watch();
    this.timedOut = false;
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Paused';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  ngOnInit(): void {

    this.primengConfig.ripple = true;

    // This is to refresh all other tabs as per the updated value of the token
    window.addEventListener('storage', (event) => {
        if (event.key === 'multivuetoken') {
          if (event.newValue == null || event.oldValue.toString() !== event.newValue.toString()){
            // Redirect to login. If user is already logged in then login will further redirect it to dashboard
            if (window.location.href != 'login') {
              if (sessionStorage.getItem('autoLoginAvailable') !== null) {
                window.location.href = 'autologin';
              }
              else {
                window.location.href = 'login';
              } 
            }
          }
        }
      }); 
    }

    @HostListener('window:beforeunload')
    ngOnDestroy(): void {
    }

    logout(): void {
        this.childModal.hide();
        if (sessionStorage.getItem('autoLogin') !== null) {
          const redirect = { postLogoutRedirectUri: localStorage.getItem('autoLoginRedirect') };
          this.authService.logoutRedirect(redirect);
          sessionStorage.removeItem('autoLogin');
        }        
        this.accountService.autoLogout();
        this.ngbModalService.dismissAll();
      }
  }
