<pagination-template #p="paginationApi" maxSize="5" (pageChange)="onPageChanged.emit($event)">

    <ul class="ngx-pagination">
      <li [class.disabled]="p.isFirstPage()">
        <a role="button" [class.disabled]="p.isFirstPage()" (click)="!p.isFirstPage() && p.setCurrent(1)"> <i class="ti ti ti-control-skip-backward"></i> </a>
      </li>

      <li [class.disabled]="p.isFirstPage()">
        <a role="button" [class.disabled]="p.isFirstPage()" (click)="!p.isFirstPage() && p.previous()">
          <span><</span> </a>
        </li>

      <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
        <a role="button" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
          <span>{{ page.label }}</span>
        </a>
        <div *ngIf="p.getCurrent() === page.value">
          <span>{{ page.label }}</span>
        </div>
      </li>

      <li [class.disabled]="p.isLastPage()">
        <a role="button" [class.disabled]="p.isLastPage()" (click)="!p.isLastPage() && p.next()"> <span>></span> </a>
      </li>

      <li [class.disabled]="p.isLastPage()">
        <a role="button" [class.disabled]="p.isLastPage()" (click)="!p.isLastPage() && p.setCurrent(p.getLastPage())"> <i class="ti ti ti-control-skip-forward"></i></a>
      </li>
    </ul>

  </pagination-template>