
<div class="row back-to-wrap" *ngIf="showViewUpdate">
    <a class="back-to" id="backToSearch" (click)="onReturnToSearchResults()"><span class="fa fa-chevron-left"></span>
        Back To Search</a>
</div>
<div class="card mt-4 mb-4" *ngIf="showViewUpdate">   
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3 class="card-title"> Share Update Details</h3>
    </div>

    <div class="content-text">
        <div class="float-right">
            <button *ngIf="hasPreviousUpdates()" type="button" class="btn btn-info mr-2"
                (click)="onPreviousUpdate()">Previous</button>
            <button *ngIf="hasNextUpdates()" type="button" class="btn btn-info mr-2"
                (click)="onNextUpdate()">Next</button>

            <button *ngIf="hasAcceptUpdate()" type="button" class="btn btn-success mr-2"
                (click)="onAcceptUpdate()">Accept</button>

            <a *ngIf="hasRejectUpdate()" class="btn btn-danger mr-3 dropdown-toggle" data-toggle="dropdown" href="#"
                role="button" aria-haspopup="true" aria-expanded="false" [attr.id]="'ab_reject_' + i">Reject</a>
            <ul class="dropdown-menu">
                <ng-container *ngFor="let i = index; let reason of rejectionReasons.reasons">
                    <li>
                        <a role="button" class="dropdown-item" id="al_performaction_{{m + 1}}"
                            (click)="onRejectUpdate(reason.key)">{{ reason.value }}</a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <ul class="list-group">
                <div class="card-header">
                    <h4 class="card-title">Update</h4>
                </div>
                    <li class="list-group-item">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <label class="control-label">Consumer</label>
                                        </div>
                                        <div class="col-sm-9">
                                            <label class="control-label">{{ currentUpdate.consumer }}</label>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <label class="control-label">Status</label>
                                        </div>
                                        <div class="col-sm-9">
                                            <label class="control-label">{{ getStatusText(currentUpdate.status) }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </li>
                    <li class="list-group-item">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <label class="control-label">Source</label>
                                        </div>
                                        <div class="col-sm-9">
                                            <label class="control-label">{{ currentUpdate.source }}</label>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <label class="control-label">Processed</label>
                                        </div>
                                        <div class="col-sm-9">
                                            <label class="control-label">{{ getProcessedText(currentUpdate.processedStatus)
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </li>
                    <li class="list-group-item">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <label class="control-label">Date Created</label>
                                        </div>
                                        <div class="col-sm-9">
                                            <label class="control-label">
                                                {{ language === "en-US" ? (currentUpdate.dateCreated | date: 'MM/dd/yyyy, h:mm a': 'UTC') : currentUpdate.dateCreated | date: 'short' : 'UTC'}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <label class="control-label">User</label>
                                        </div>
                                        <div class="col-sm-9">
                                            <label class="control-label">{{ currentUpdate.user }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </li>
                    <li class="list-group-item">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <label class="control-label">Last Updated</label>
                                        </div>
                                        <div class="col-sm-9">
                                            <label class="control-label">
                                                {{ language === "en-US" ? (currentUpdate.lastUpdated | date: 'MM/dd/yyyy, h:mm a': 'UTC') : currentUpdate.lastUpdated | date: 'short' : 'UTC'}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <label class="control-label">Consumer Unique Id</label>
                                        </div>
                                        <div class="col-sm-9">
                                            <label class="control-label">{{ currentUpdate.consumerUniqueId }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </li>
                    <li class="list-group-item">
                            <div class="row">
                                <div class="col-md-6">
                                    <app-share-view-entity-attributes [UpdateValueAttributes]="currentUpdate.oldValue" Title="Current">
                                    </app-share-view-entity-attributes>
                                </div>
                    
                                <div class="col-md-6">
                                    <app-share-view-entity-attributes [UpdateValueAttributes]="currentUpdate.newValue" Title="New">
                                    </app-share-view-entity-attributes>
                                </div>
                            </div>
                    </li>
            </ul>
        </div>

    </div>