import { AuditViewDetailComponent } from './../audit-view-detail/audit-view-detail.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuditResult } from './../models/audit-result';
import { AuditFilterCriteria } from './../models/audit-filter-criteria';
import { AuditService } from './../audit.service';
import { GridParameters } from './../../shared/models/grid-parameters';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Audit } from '../models/audit';
import { FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-audit-search-grid',
  templateUrl: './audit-search-grid.component.html',
  styleUrls: ['./audit-search-grid.component.scss']
})
export class AuditSearchGridComponent implements OnInit {

  criteria: AuditFilterCriteria = new AuditFilterCriteria();

  rows = new AuditResult();
  page = new GridParameters();
  loading = false;
  showAuditSearchGrid = false;
  showAuditDetailsModelPopup = false;
  language: string = navigator.language;
  searchRequest: any;
  reloadGrid: any;
  gridEvent: LazyLoadEvent;
  @ViewChild('dt') dt: Table;
  jumpToPage: number = 1;

  constructor(private auditService: AuditService, private modalService: NgbModal, private config: NgbModalConfig, private changeDetectorRef: ChangeDetectorRef) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.criteria.page++;
    this.postAuditSearch(this.criteria);
  }

  searchAudits(event: LazyLoadEvent) {
    if (this.reloadGrid) {
      this.loading = true;
      this.criteria.page = (event.first / event.rows) + 1;
      this.criteria.pageSize = event.rows;
      this.criteria.sortColumn = event.sortField == null ? "Timestamp" : event.sortField;
      this.criteria.sortOrder = event.sortOrder == -1 ? "desc" : "asc";
      this.criteria.filters = event.filters;
      this.postAuditSearch(this.criteria, event);
    } else {
      this.reloadGrid = true;
    }
  }

  postAuditSearch(auditSearchRequest, event = null): void {
    this.gridEvent = event;
    this.auditService.searchAudit(auditSearchRequest).subscribe((response) => {
      this.showAuditSearchGrid = true;
      this.loading = false;
      this.changeDetectorRef.detectChanges();
      this.rows = response;
    });
    this.changeDetectorRef.detectChanges();
  }

  setGridPageAndFilter(): void {
    this.dt.first = this.gridEvent.first == 1 ? 0 : this.gridEvent.first;
    this.dt.filters = this.gridEvent.filters;
  }

  onRowClick(row: Audit): void {
    this.showAuditDetailsModelPopup = true;
    const modalRef = this.modalService.open(AuditViewDetailComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.audit = row;
  }

  public show(): void {
    this.showAuditSearchGrid = true;
  }

  public hide(): void {
    this.showAuditSearchGrid = false;
  }

  searchAuditsFromPaging(event: LazyLoadEvent) {
    if (this.gridEvent) {
      this.searchAudits({ first: event.first, rows: event.rows, filters: this.gridEvent.filters, sortField: this.gridEvent.sortField, sortOrder: this.gridEvent.sortOrder });
    }
    else {
      this.searchAudits({ first: event.first, rows: event.rows });
    }

  }

  onJumpToPage() {
    if (this.jumpToPage && this.jumpToPage > 0) {
      const pageNumber = this.jumpToPage - 1;
      this.dt.first = pageNumber * this.dt.rows;
      if (this.gridEvent) {
        this.searchAudits({ first: this.dt.first, rows: this.dt.rows, filters: this.gridEvent.filters, sortField: this.gridEvent.sortField, sortOrder: this.gridEvent.sortOrder });
      }
      else {
        this.searchAudits({ first: this.dt.first, rows: this.dt.rows });
      }
    }
  }
}
