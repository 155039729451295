<div class="modal-header">
    <h4 class="header float-left" id="audit-detail-title">Audit Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <table class="table table-sm table-striped table-bordered fixed">
                    <tbody>
                        <tr>
                            <td class="text-bold col-sm-3 td-width">ID</td>
                            <td class="col-sm-3">{{audit.id}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold col-sm-3">Status</td>
                            <td class="col-sm-3">{{audit.status}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold col-sm-3">Url</td>
                            <td class="col-sm-3">{{audit.url}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold col-sm-3">Query String</td>
                            <td class="col-sm-3">{{audit.queryString}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold col-sm-3">Method</td>
                            <td class="col-sm-3">{{audit.method}}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="col-md-6">
                <table class="table table-sm table-striped table-bordered fixed">
                    <tbody>
                        <tr>
                            <td class="text-bold td-width">Scope</td>
                            <td>{{audit.scope}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold">Username</td>
                            <td>{{audit.username}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold">IP Address</td>
                            <td>{{audit.ipAddress}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold">Timestamp</td>
                            <td>{{ language === "en-US" ? (audit.timestamp | date: 'MM/dd/yyyy, h:mm a' : 'UTC') : audit.timestamp | date: 'short' : 'UTC'}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold">Tags</td>
                            <td>{{tags.toString()}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>