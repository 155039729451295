import { Injectable } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { ErrorModalComponent } from "../components/error-modal/error-modal.component";

@Injectable({
  providedIn: 'root',
  
})
export class ModalService {
  subject: Subject<boolean>;
  private _dialogService;
  constructor(private dialogService: DialogService) { 
 
  }

  displayErrorModal(errorMessage: string) {
    this.dialogService.open(ErrorModalComponent, {
      header: 'Error',
      width: '30vw',
      position:'center',
      resizable:true,
    
      data: {
        message: errorMessage
      },
      
    });
  }
}