import { Component, Inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrl: './error-modal.component.scss',
})
export class ErrorModalComponent {
  errorMessage: string;

  constructor(private ref: DynamicDialogRef, @Inject(DynamicDialogConfig) private config: DynamicDialogConfig) {
  if(this.config.data.message == "Internal Server Error"){
    this.config.data.message = "An error has occurred, please contact support";
  }
    this.errorMessage = this.config.data.message;
  }

  close() {
    this.ref.close();
  }
  
}
