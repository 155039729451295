<!-- <div class="main-bg"></div>
<div class="overlay"></div> -->

<div class="login-wrapper">
    <div class="row no-gutters">
        <div class="col-md-5 no-gutters">
            <div class="login-left-section d-flex">
                <div class="login-header">
                    <img src="../../assets/images/mdm-logo-white.png" />
                    <h1>MDM</h1>
                    <span>Master Data Management</span>
                </div>
            </div>
        </div>
        <div class="col-md-7 no-gutters">
            <div class="login-right-section d-flex">

                <div class="login-right-section-content">
                  
                    <div class="row back-to-wrap pull-right">
                    <a class="back-to" [routerLink]="'/login'"><span class="fa fa-chevron-left"></span> Back To Login</a>
                </div>
                <h3>Forgot your password?</h3>
                    <div class="login-form">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <div class="input-group mb-3 d-block">
                                    <span class="p-input-icon-left d-block">
                                        <i class="ti ti-email"></i>
                                        <input placeholder="Username or email" id="username" aria-label="Username"
                                            aria-describedby="basic-addon1" type="text" pInputText
                                            formControlName="username" class="form-control input-lg"
                                            [ngClass]="{ 'is-invalid': submitted && fields.username.errors }"
                                            autofocus />
                                    </span>


                                    <div *ngIf="submitted && fields.username.errors" id="warningUsername"
                                        class="invalid-feedback">
                                        <div *ngIf="fields.username.errors.required">Username or email address is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <p-button type="submit" styleClass="p-button-lg signin-btn" id="signin" label="Reset My Password">
                                   
                                </p-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>