import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-unsaved-changes-modal-popup',
  templateUrl: './unsaved-changes-modal-popup.component.html',
  styleUrls: ['./unsaved-changes-modal-popup.component.scss']
})
export class UnsavedChangesModalPopupComponent {

  subject: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) { }

  action(value: boolean) {
    this.bsModalRef.hide();
    this.subject.next(value);
    this.subject.complete();
  }

}
