import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewUpdateModel } from '../models/share-view-update-model';
import { ShareService } from '../share.service';
import { DownstreamUpdateDetailsModel } from '../models/downstream-update-details-model'

@Component({
  selector: 'app-share-view-update',
  templateUrl: './share-view-update.component.html',
  styleUrls: ['./share-view-update.component.scss']
})
export class ShareViewUpdateComponent implements OnInit {

  _viewUpdateModel: ViewUpdateModel;

  @Input()
  get ViewUpdateModel(): ViewUpdateModel { return this._viewUpdateModel; }
  set ViewUpdateModel(viewUpdateModel: ViewUpdateModel) {
    this._viewUpdateModel = viewUpdateModel;

    // set a default currentUpdate, this prevents errors from occuring
    //  while the form is initalising
    this.currentUpdate = new DownstreamUpdateDetailsModel(this._viewUpdateModel.criteria);
    if (this._viewUpdateModel.rowIndex === 1) {
      this.currentUpdate.hasPreviousRecords = false;
    }

    this.getCurrentUpdate();
  }

  @Output() returnToSearchResults = new EventEmitter();

  showViewUpdate: boolean;
  currentUpdate: DownstreamUpdateDetailsModel;

  public rejectionReasons = {
    reasons: [
      { key: 0, value: 'Verified By Subject' },
      { key: 1, value: 'Incorrect Details' }
    ]
  };

  public statuses = ['Pending', 'Accepted', 'Rejected', 'Auto Accepted', 'Auto Rejected', 'Superceded'];

  public processes = ['Not Processed', 'Processed', 'Process Failure'];

  downstreamUpdateForm: UntypedFormGroup;
  language: string = navigator.language;

  constructor(private formBuilder: UntypedFormBuilder, private shareService: ShareService) { }

  ngOnInit(): void {
    this.getCurrentUpdate();
    this.initFilterFormControls();
  }

  initFilterFormControls(): void {
    this.downstreamUpdateForm = this.formBuilder.group({
      consumer: [null],
      status: [null],
      process: [null]
    });
  }

  // sets the currentUpdate correctly once it has been received from the
  //  service, use this to set the currentUpdate
  setCurrentUpdate(data: DownstreamUpdateDetailsModel) {
    this.currentUpdate = data;
    this.currentUpdate.oldValue = JSON.parse(data.oldValue);
    this.currentUpdate.newValue = JSON.parse(data.newValue);
  }

  // gets the currentUpdate from the server
  getCurrentUpdate() {
    if (this._viewUpdateModel) {
      this.shareService
        .getUpdateDetail(this._viewUpdateModel.pageIndex, this._viewUpdateModel.rowIndex, this._viewUpdateModel.criteria)
        .subscribe((data: DownstreamUpdateDetailsModel) => {
          this.setCurrentUpdate(data);
        })
    }
  }

  // returns to the search results
  onReturnToSearchResults() {
    this.currentUpdate = null;
    this._viewUpdateModel = null;
    this.returnToSearchResults.emit();
  }

  public hasNextUpdates(): boolean {
    return this.currentUpdate.hasMoreRecords;
  }

  public onNextUpdate() {
    this.shareService
      .getNextUpdateDetail(this.currentUpdate.page, this._viewUpdateModel.criteria)
      .subscribe((data: DownstreamUpdateDetailsModel) => {
        this.setCurrentUpdate(data);
      })
  }

  public hasPreviousUpdates(): boolean {
    return this.currentUpdate.hasPreviousRecords;
  }

  public onPreviousUpdate() {
    this.shareService
      .getPreviousUpdateDetail(this.currentUpdate.page, this._viewUpdateModel.criteria)
      .subscribe((data: DownstreamUpdateDetailsModel) => {
        this.setCurrentUpdate(data);
      })
  }

  public hasAcceptUpdate(): boolean {
    // if status is either pending (0) or rejected (2) 
    if (this.currentUpdate.status === 0 || this.currentUpdate.status === 2) {
      // if process status is either not-processed (0) or process-failure (2) 
      if (this.currentUpdate.processedStatus == 0 || this.currentUpdate.processedStatus == 2) {
        return true;
      }
    }
    return false;
  }

  public onAcceptUpdate() {
    this.shareService.acceptUpdate(this.currentUpdate.id).subscribe(() => {
      this.navigateAfterAcceptOrReject();
    });
  }

  public hasRejectUpdate(): boolean {
    // if status is either pending (0) or accepted (1) 
    if (this.currentUpdate.status === 0 || this.currentUpdate.status === 1) {
      // if process status is either not-processed (0) or process-failure (2) 
      if (this.currentUpdate.processedStatus == 0 || this.currentUpdate.processedStatus == 2) {
        return true;
      }
    }
    return false;
  }

  public onRejectUpdate(rejectReason: number) {
    this.shareService.rejectUpdate(this.currentUpdate.id, rejectReason).subscribe(() => {
      this.navigateAfterAcceptOrReject();
    });
  }

  // navigate after accept or reject
  //  if there are moreRecords then the currentUpdate is refreshed with the same pageIndex and rowIndex
  //  if there are previousRecords then then currentUpdate is refreshed with previous pageIndex
  //  if neither conditions are true then return to the search grid
  navigateAfterAcceptOrReject() {
    if (this.currentUpdate.hasMoreRecords) {
      this.getCurrentUpdate();
    }
    else if (this.currentUpdate.hasPreviousRecords) {
      this.onPreviousUpdate();
    }
    else {
      this.returnToSearchResults.emit();
    }
  }

  public show() {
    this.showViewUpdate = true;
  }

  public hide() {
    this.showViewUpdate = false;
  }

  public getStatusText(index: number) {
    return this.statuses[index];
  }

  public getProcessedText(index: number) {
    return this.processes[index];
  }
}
