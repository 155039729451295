import { AccountService } from './account.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()

export class WithCredentialsInterceptorService implements HttpInterceptor {

    private request: any;
    private next: any;

    constructor(private accountService: AccountService, private router: Router){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      if (['/api/account/GetWinUser'].includes(request.url)) {
        request = request.clone({ withCredentials: true });
        return next.handle(request);
      }

      if (['/api/authentication/login', '/api/antiforgery', '/api/users/resetpassword', '/api/authentication/refreshtoken'].includes(request.url)){
          return next.handle(request);
      }

      if (request.url.includes("assets/")) {
        return next.handle(request);
      }

      if (this.accountService.getToken() !== null && this.accountService.tokenExpired(this.accountService.getToken())) {   
        this.request = request;
        this.next = next;
        this.accountService.tryRefreshingTokens(this, this.refreshSuccess, this.refreshFailure);
      } 
      else {       
        if (this.accountService.isAuthenticated()) {
            const token = this.accountService.getToken().token;
            let requestCustomer = this.accountService.getRequestCustomer();

            if (request.headers.has("Customer")) {
              requestCustomer = request.headers.get("Customer");
            }

            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
                Customer: requestCustomer
            },
          });
        }
        else {
          this.accountService.logout();
        }
        return next.handle(request);
      }
  }

  refreshSuccess(caller:any, accountService:any) {
    if (caller.accountService.isAuthenticated()) {
      
      const token = caller.accountService.getToken().token;
      let requestCustomer = caller.accountService.getRequestCustomer();

      if (caller.request.headers.has("Customer")) {
        requestCustomer = caller.request.headers.get("Customer");
      }

      caller.request = caller.request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          Customer: requestCustomer
          },
        });
      }
      else {
        caller.accountService.logout();
      }
  
      return caller.next.handle(caller.request);

    }

    refreshFailure(caller:any, accountService:any) {
      caller.accountService.logout();
    }
}

