import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';

if (environment.production) {
  enableProdMode();
}

function loggerCallback(logLevel: LogLevel, message: string) {
  console.log("MSAL Angular: ", message);
}

fetch('/api/authentication/GetAzureAdConfigForUI')
  .then(response => response.json())
  .then(json => {
      sessionStorage.setItem('autoLoginAvailable', json.adLogin);
      sessionStorage.setItem('autoLoginRedirect', json.msal.auth.redirectUri);
      if (json.adLogin != true) {
        sessionStorage.removeItem('autoLoginAvailable');
        sessionStorage.removeItem('autoLoginRedirect');
        sessionStorage.removeItem('autoLogin');
      }
      platformBrowserDynamic([
        { 
          provide: MSAL_INSTANCE, 
          useValue: new PublicClientApplication({
            auth: json.msal.auth,
            cache: json.msal.cache,
            system: {
              loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
              }
            }
          }) 
        },
        { 
          provide: MSAL_GUARD_CONFIG, useValue: {
          interactionType: json.guard.interactionType,
          authRequest: json.guard.authRequest,
          loginFailedRoute: json.guard.loginFailedRoute
          } as MsalGuardConfiguration 
        },
        { 
          provide: MSAL_INTERCEPTOR_CONFIG, useValue: {
          interactionType: json.interceptor.interactionType,
          protectedResourceMap: new Map(json.interceptor.protectedResourceMap)
        } as MsalInterceptorConfiguration },
      ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
      });
