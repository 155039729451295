<div class="modal" id="searchResultsModalPopup" [ngStyle]="{
    display: showSearchResultsPopup === true ? 'block' : 'none'
  }">
  
    <div class="modal-dialog modal-xl modal-dialog-scrollable">

        <div class="modal-content">
            <div class="modal-header">
                    <div *ngIf="relationshipSearch" class="input-group">
                      <div class="p-inputwrapper d-flex">
                        <p-dropdown class="domain-dd-width" [options]="entityTypes" placeholder="Domain Model" [(ngModel)]="selectedEntityType" optionLabel="name"
                        optionValue="id" [ngModelOptions]="{standalone: true}" title="Select domain to search on"> </p-dropdown>
                        <span class="p-input-icon-left">
                          <i class="ti ti-search"></i>
                          <input type="text" id="txt-header-search" pInputText class="p-inputtext" (keydown.enter)="onSearch($event)"/>
                        </span>
                      </div>
                      <div class="input-group-append">
                        <p-button label="Advanced Search" styleClass="p-button-sm" (click)="onAdvanceSearch()" routerLinkActive="active"></p-button>
                      </div>
                    </div>
                <h5 *ngIf="showSearchResults && !relationshipSearch" class="modal-title">Search Results</h5>
                <button type="button" id="bRequestClose" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="card-header" *ngIf="rows.length <= 0">
                <span class="card-title">Entity Not Found</span>
            </div>
            <div *ngIf="showSearchResults && rows.length > 0" class="modal-body">
                <p-tabView (onChange)="onTabChange($event)">
                    <p-tabPanel header="Virtuals" [selected]="true">
                        <div class="content-height d-block">
                            <div class="mt-3">

                                <p-table id="resultTable" class="custom-table"
                                [value]="rows"
                                dataKey="id"
                                [tableStyle]="{ 'min-width': '75rem' }"
                                [paginator]="true"
                                [rows]="10"
                                [totalRecords]="totalRecords"
                                [loading]="loading"   
                                [columns]="columns"                            
                                >

                                <ng-template pTemplate="header" let-row let-columns>
                                    <tr>                
                                      <th  *ngFor="let column of columns" [pSortableColumn]="column.prop" scope="col" style="cursor: pointer;"> {{column.name}} 
                                        <p-sortIcon *ngIf="column.name !=='Actions'" [field]="column.prop"></p-sortIcon>              
                                        </th>
                                    
                                    </tr>
                                  </ng-template>

                              
                                    <ng-template pTemplate="body" let-rowIndex="rowIndex"  let-row let-columns="columns" let-value="value"
                                    >
                                  <tr>
                                    <td *ngFor="let col of columns">
                                        <span 
                                        *ngIf="col.name ==='Actions'; then actionBtnTemplate; else columnTemplate;"></span>
                                  
                                    
                                    <ng-template #actionBtnTemplate>
                                        <input type="button" class="btn btn-primary mr-2 btn-sm" value="View"
                                            (click)='onSelectView(row)'
                                            authorize [pages]="getPageNamesForAuthorization()" [accessType] = "readOnlyAccess">

                                        <ng-container *ngIf="relationshipSearch == true">
                                            <input type="button" class="btn btn-success btn-sm"
                                                value="Add Relationship" (click)="addRelationship(row)"
                                                authorize [pages]="getPageNamesForAuthorization()" [accessType] = "fullAccess">
                                        </ng-container>
                                        <input type="button"
                                            *ngIf="row.isInBasket === false && relationshipSearch !== true"
                                            class="btn btn-primary btn-sm" value="+"
                                            (click)='onAddToMyRecords(row)'authorize [pages]="getPageNamesForAuthorization()" [accessType] = "readOnlyAccess">
                                    </ng-template>
                                    <ng-template #columnTemplate>
                                        <span *ngIf="dateValidatorService.isDate(row[col.prop]); else checkDateTime">
                                            {{ language === "en-US" ? (row[col.prop] | date: 'MM/dd/yyyy') : row[col.prop] |
                                            date:
                                            'shortDate'}}
                                        </span>
                                    </ng-template>
                                    <ng-template #checkDateTime>
                                        <span *ngIf="dateValidatorService.isDateTime(row[col.prop]); else noCheck">
                                            {{ language === "en-US" ? (row[col.prop] | date: 'MM/dd/yyyy, h:mm a') :
                                            row[col.prop] |
                                            date:
                                            'short'}}
                                        </span>
                                    </ng-template>

                                    <ng-template #noCheck>
                                        <span>
                                            {{row[col.prop]}}
                                        </span>
                                    </ng-template>
                                </td>
                            </tr>
                                </ng-template>
                         
               </p-table>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Real Entities" [selected]="false">
                        <div class="content-height d-block">
                            <div class="mt-3">

                                <p-table id="resultTable" class="custom-table"
                                [value]="rows"
                                dataKey="id"
                                [tableStyle]="{ 'min-width': '75rem' }"
                                [paginator]="true"
                                [rows]="10"
                                [totalRecords]="totalRecords"
                                [loading]="loading"   
                                [columns]="columns"                            
                                >

                                <ng-template pTemplate="header"  let-row let-columns>
                                    <tr>                
                                      <th  *ngFor="let column of columns" [pSortableColumn]="column.prop" scope="col" style="cursor: pointer;"> {{column.name}} 
                                        <p-sortIcon *ngIf="column.name != 'Actions' " [field]="column.prop"></p-sortIcon>              
                                        </th>
                                    
                                    </tr>
                                    <!-- <tr>

                                        <th  *ngFor="let column of columns">
                                            <p-columnFilter *ngIf="!dateValidatorService.isDateTime(row[column.prop]) && !dateValidatorService.isDate(row[column.prop]) && column.name != 'Actions' " type="text" [field]="column.prop"></p-columnFilter>  
                                            <p-columnFilter *ngIf="(dateValidatorService.isDateTime(row[column.prop])||dateValidatorService.isDate(row[column.prop])) && column.name != 'Actions' " type="date" [field]="column.prop"></p-columnFilter> 
                                        </th>
                                  </tr> -->
                                  </ng-template>

                              
                                    <ng-template pTemplate="body" let-rowIndex="rowIndex"  let-row let-columns="columns" let-value="value"
                                    >
                                  <tr>
                                    <td *ngFor="let col of columns">
                                        <span 
                                        *ngIf="col.name ==='Actions'; then actionBtnTemplate; else columnTemplate;"></span>
                                  
                                    
                                    <ng-template #actionBtnTemplate>
                                        <input type="button" class="btn btn-primary mr-2 btn-sm" value="View"
                                            (click)='onSelectView(row)'
                                            authorize [pages]="getPageNamesForAuthorization()" [accessType] = "readOnlyAccess">

                                        <ng-container *ngIf="relationshipSearch == true">
                                            <input type="button" class="btn btn-success btn-sm"
                                                value="Add Relationship" (click)="addRelationship(row)"
                                                authorize [pages]="getPageNamesForAuthorization()" [accessType] = "fullAccess">
                                        </ng-container>
                                        <input type="button"
                                            *ngIf="row.isInBasket === false && relationshipSearch !== true"
                                            class="btn btn-primary btn-sm" value="+"
                                            (click)='onAddToMyRecords(row)'authorize [pages]="getPageNamesForAuthorization()" [accessType] = "readOnlyAccess">
                                    </ng-template>
                                    <ng-template #columnTemplate>
                                        <span *ngIf="dateValidatorService.isDate(row[col.prop]); else checkDateTime">
                                            {{ language === "en-US" ? (row[col.prop] | date: 'MM/dd/yyyy') : row[col.prop] |
                                            date:
                                            'shortDate'}}
                                        </span>
                                    </ng-template>
                                    <ng-template #checkDateTime>
                                        <span *ngIf="dateValidatorService.isDateTime(row[col.prop]); else noCheck">
                                            {{ language === "en-US" ? (row[col.prop] | date: 'MM/dd/yyyy, h:mm a') :
                                            row[col.prop] |
                                            date:
                                            'short'}}
                                        </span>
                                    </ng-template>

                                    <ng-template #noCheck>
                                        <span>
                                            {{row[col.prop]}}
                                        </span>
                                    </ng-template>
                                </td>
                            </tr>
                                </ng-template>
                         
               </p-table>
                            </div>
                        </div>
                    </p-tabPanel>
                </p-tabView>

            </div>
            <div class="modal-footer">
                <div class="dropdown float-right"> 
                    <p-button label="Close" styleClass="btn-sm mr-2" id="bRequestSubmit" aria-haspopup="true"
                        aria-expanded="false" (click)="onClose()">
                    </p-button>
                </div>
            </div>
        </div>
    </div>
</div>
