/// <summary>
/// List of the services that has their configuration controlled by core
/// </summary>
export enum ServiceName {
    /// <summary>
    /// The core service
    /// </summary>
    Core,

    /// <summary>
    /// The HL7v3 service
    /// There are a number of ServiceName enumerations.
    /// Although the HL7v3 service was removed this enumeration remains
    /// because removing it may change the numbering of the remaining enumeration values
    /// </summary>
    HL7v3,

    /// <summary>
    /// The HL7 v2 service
    /// </summary>
    HL7v2,

    /// <summary>
    /// The Integration Web
    /// </summary>
    Soap,

    /// <summary>
    /// The Data service
    /// </summary>
    Data,

    /// <summary>
    /// The data synchronize
    /// </summary>
    DataSync,

    /// <summary>
    /// The MSMQ service
    /// </summary>
    Msmq,

    /// <summary>
    /// The Audit service
    /// </summary>
    Audit,

    /// <summary>
    /// The bulk
    /// </summary>
    Bulk,

    /// <summary>
    /// The integration test
    /// </summary>
    IntegrationTest,

    /// <summary>
    /// The shell
    /// </summary>
    Shell,

    /// <summary>
    /// The web UI
    /// </summary>
    WebUI,

    /// <summary>
    /// The downstream
    /// </summary>
    Downstream,

    /// <summary>
    /// The dynamics CRM
    /// </summary>
    DynamicsCrm,

    /// <summary>
    /// The relationship
    /// </summary>
    Relationship,

    /// <summary>
    /// The amqp
    /// </summary>
    Amqp,

    /// <summary>
    /// The rest
    /// </summary>
    Rest,

    /// <summary>
    /// The MyAccount
    /// </summary>
    MyAccount,

    /// <summary>
    /// The FHIR Service
    /// </summary>
    FHIR
}