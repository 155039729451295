import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IInboxGrid } from './models/inbox-grid';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GridParameters } from 'src/app/shared/models/grid-parameters';

@Injectable({
  providedIn: 'root'
})
export class InboxService {

  inboxUrl = '/api/workflowtasks';
  inboxDetailsUrl = '/api/inbox';

  inboxGridRows: Observable<IInboxGrid>;
  constructor(private http: HttpClient) { }

  getInboxGrid(gridParameters:GridParameters): any {
    let body = gridParameters;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

  return this.http.post<any>(this.inboxDetailsUrl + '/GetTasks', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  
}

  getSummary(index: number, page: number): any {
    return this.http.get<IInboxGrid>(this.inboxDetailsUrl + '/summary?index=' + index + '&page=' + page);
  }

  getInvalidDataCount(): any {
    return this.http.get<any>(this.inboxDetailsUrl + '/invalidDataCount');
  }

  workflowDetails(InboxFilterModel: any): any {
    let body = InboxFilterModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/Details', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }
  delete(taskId:string): any {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/Delete?taskId='+taskId, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }
  deleteAll(): any {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/DeleteAll', {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

  reject(taskId: any, matchId: any, domain: any,inboxFilterModel: any): any {
    let body = inboxFilterModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/Reject?taskId=' + taskId + '&matchId=' + matchId + '&domain=' + domain, body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

  accept(taskId: any, matchId: any, domain: any,inboxFilterModel: any): any {
    let body = inboxFilterModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/Accept?taskId=' + taskId + '&matchId=' + matchId + '&domain=' + domain, body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

  rejectNewEntity(taskId: any, matchId: any, domain: any, inboxFilterModel: any): any {
    let body = inboxFilterModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/RejectNewEntity?taskId=' + taskId + '&entityId=' + matchId + '&domain=' + domain , body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

  acceptNewEntity(taskId: any, matchId: any, domain: any,inboxFilterModel: any,enableVerus: any): any {
    let body = inboxFilterModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/AcceptNewEntity?taskId=' + taskId + '&entityId=' + matchId + '&domain=' + domain + '&enableVerus=' + enableVerus, body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

  next(inboxFilterModel: any): any {
    let body = inboxFilterModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/Next', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

  previous(inboxFilterModel: any): any {
    let body = inboxFilterModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/Previous', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

    closeReviewTask(closeReviewRequest: any): any {
    let body = closeReviewRequest;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/CloseReviewRequest', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }


  closeReviewRejectedEntityTask(taskId,inboxFilterModel): any {
    let headers = new HttpHeaders();
    let body = inboxFilterModel;

    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/CloseRejectedEntity?taskId=' + taskId,body,{
      headers: headers,
    }).pipe(catchError(this.handleError));
  }


 forward(taskId: any): any {
    return this.http.get<any>(this.inboxDetailsUrl + '/Forward?taskId=' + taskId);
  }

escalate(taskId: any): any {
    return this.http.get<any>(this.inboxDetailsUrl + '/Escalate?taskId=' + taskId);
  }
getCreateNote(taskId: any): any {
    return this.http.get<any>(this.inboxDetailsUrl + '/CreateNote?taskId=' + taskId);
  }

   createNote(createNoteModel: any): any {
    let body = createNoteModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/CreateNote', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }


   forwardTask(forwardTaskModel: any): any {
    let body = forwardTaskModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/ForwardTask', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

    escalateTask(escalateTaskModel: any): any {
    let body = escalateTaskModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.inboxDetailsUrl + '/EscalateTask', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

  getWorkflowTaskCount(): any {
    const headers = { 'no-spinner': '' };

    return this.http.get<any>(this.inboxDetailsUrl + '/GetTasksCount', {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.log('Error Message :' + error.message);
    return throwError(error);
  }

}
