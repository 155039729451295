import { AccountService } from './../account/account.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root'})
export class LoginGuard {

    constructor(private router: Router, private accountService: AccountService){}
        
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.accountService.isAuthenticated())
        {
            if (state.url.includes('/login')){
                this.router.navigate(['/dashboard']);
                return false;
            }
        }
        return true;
    }
}