import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-too-many-requests',
  templateUrl: './too-many-requests.component.html',
  styleUrls: ['./too-many-requests.component.scss']
})
export class TooManyRequestsComponent implements OnInit {

  countdown : number = 30; // 30 seconds

  private subscription: Subscription;

  constructor(private router: Router) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.router.navigate(['./dashboard']);
    }, 30000); // 30 seconds

    this.subscription = interval(1000)
           .subscribe(x => { this.countdown--; });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
