
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig: any;
  constructor(private http: HttpClient) { }
 

  loadAppConfig() {
    return this.http.get('assets/config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }

  // This is an example property ... you can make it however you want.
 
  get maxLengthNames(): number {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.maxLengthNames;
  }
  get maxLengthDescription(): number {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.maxLengthDescription;
  }
  get maxLengthDataAttributeFieldNames(): number {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.maxLengthDataAttributeFieldNames;
  }
  get maxLengthNumber(): number {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.maxLengthNumber;
  }
  get maxPaginatorRowCount(): number {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.maxCustomPaginatorRowCount;
  }
}

