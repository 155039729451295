<div class="card content-height-12rem">

    <div class="card-body">
        <div class="p-inputwrapper d-flex flex-column">
            <span class="p-input-icon-left">
                <i class="ti ti-search"></i>
                <input type="text" pInputText [(ngModel)]="searchText" id="inputwithlefticon"
                    (input)="applySearchFilter()" />
            </span>
        </div>
        <div class="list-group" id="itemsList" style="margin-top: 5px;">
            <a *ngFor="let item of filteredItemsList; let i = index" id="item_row_{{i}}"
                class="list-group-item list-group-item-action single-line"
                [ngClass]="{'active': selectedItem?.name == item?.name}" (click)="onItemSelection(item,i)" [title]="item.name">{{ item.name }}
            </a>
        </div>

    </div>
</div>