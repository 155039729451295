
     <div class="modal-header modal-block-primary">
        <h4 id="dialog-child-name" class="modal-title pull-left">Are you sure?</h4>
        <button type="button" class="close pull-right" aria-label="Close"  (click)="bsModalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <label> Your unsaved changes will be lost. Are you sure you want to continue?</label>
      </div>
      <div class="modal-footer">
        <div class="dropdown float-right">
          <p-button type="button" label="No" (click)="action(false)" styleClass="p-button-ghost  mr-2"></p-button>
          <p-button type="button" label="Yes" (click)="action(true)" ></p-button>
        </div>
      </div>
 