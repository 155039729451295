import { AuditViewDetailComponent } from './../audit-view-detail/audit-view-detail.component';
import { AuditSearchGridComponent } from './../audit-search-grid/audit-search-grid.component';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {

  @ViewChild(AuditSearchGridComponent) searchResultsComponent: AuditSearchGridComponent;
  @ViewChild(AuditViewDetailComponent) viewAuditFormComponent: AuditViewDetailComponent;

  constructor() { }

  ngOnInit(): void {
  }
}
