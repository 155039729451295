<div class="content-header">
    <div class="container-fluid">
        <div class="d-flex justify-content-between">
            <h1 class="mb-0"><i class="ti ti-key"></i>Change Password</h1>
        </div>
    </div>
</div>

<!-- Change Password -->
<div class="card" id="changePasswordPopup">

    <form class="multivue-common-form" [formGroup]="changePasswordForm" (ngSubmit)="onChangePasswordSave()" novalidate
        id="changePasswordForm">

        <div class="card-body">

            <div class="row">
                <div class="col-md-2">
                    <label for="Previous" class="col-form-label mx-1">Current Password</label>
                    <span class="label-asterisk">*</span>
                </div>
                <div class="col-md-4">
                    <div class="form-group no-margin-hr">
                        <div class="input-group">
                            <div class="input-group-append">
                                <!-- <span class="p-input-icon-right d-block">
                                    <i class="ti ti-eye-slash" [ngClass]="{
                                        'ti-eye-slash': oldPasswordTextInput,
                                        'ti-eye': !oldPasswordTextInput
                                      }" (click)="toggleOldPasswordInputType()"></i>
                                      <input pInputText [type]="oldPasswordTextInput ? 'text' : 'password'" class="form-control"
                                      formControlName="OldPassword" id="OldPassword" required autocomplete="off" />
                                </span> -->
                                <p-password [toggleMask]="true" formControlName="OldPassword" id="OldPassword" autocomplete="off"> </p-password>
                            </div>
                        </div>
                        <div *ngIf="isValidInputControl('OldPassword')" class="p-invalidtext-wrapper">                         
                                <span class="p-invalidtext" *ngIf="changePasswordForm.controls['OldPassword'].errors.required">
                                    The Current Password field is required
                                </span>                            
                        </div>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <label for="Previous" class="col-form-label mx-1">New Password</label>
                    <span class="label-asterisk">*</span>
                </div>
                <div class="col-md-4">
                    <div class="form-group no-margin-hr">

                        <div class="input-group">
                            <div class="input-group-append">
                                <p-password [toggleMask]="true" formControlName="Password" id="Password" required minlength="12" [pattern]="passwordPattern" autocomplete="off"> </p-password>

                            </div>
                        </div>

                        <div *ngIf="changePasswordForm.hasError('containsInvalidChar')" class="p-invalidtext-wrapper">
                            Password cannot contain a \ character
                        </div>    

                        <div *ngIf="isValidInputControl('Password')" class="p-invalidtext-wrapper">
        
                                <span class="p-invalidtext" *ngIf="changePasswordForm.controls['Password'].errors.required">
                                    The New Password field is required
                                </span>
                                <span class="p-invalidtext" *ngIf="changePasswordForm.controls['Password'].errors.minlength">
                                    Password must be at least 12 characters
                                </span>
                                <span class="p-invalidtext" *ngIf="changePasswordForm.controls['Password'].errors.pattern">
                                    Password must have at least one lowercase (a-z), one uppercase (A-Z), one
                                    digit (0-9), and one of the following symbols (&#64;$!%*#?~|+:;-)
                                </span>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <label for="Previous" class="col-form-label mx-1">Confirm Password</label>
                    <span class="label-asterisk">*</span>
                </div>
                <div class="col-md-4">
                    <div class="form-group no-margin-hr">

                        <div class="input-group">
                            <div class="input-group-append">
                              
                                <p-password preventCopyPaste [toggleMask]="true" formControlName="ConfirmPassword" id="ConfirmPassword" required notSame minlength="12" [pattern]="passwordPattern"> </p-password>
                            </div>
                        </div>

                        <div *ngIf="isValidInputControl('ConfirmPassword')" class="p-invalidtext-wrapper">
                                <div>
                                <span class="p-invalidtext" *ngIf="changePasswordForm.controls['ConfirmPassword'].errors.required">
                                    The Confirm Password field is required
                                </span>
                                </div>
                                <div>
                                <span class="p-invalidtext" *ngIf="changePasswordForm.controls['ConfirmPassword'].errors.minlength">
                                    Password must be at least 12 characters
                                </span>
                                </div>
                                <div>
                                <span class="p-invalidtext" *ngIf="changePasswordForm.controls['ConfirmPassword'].errors.pattern">
                                    Password must have at least one lowercase (a-z), one uppercase (A-Z),
                                    digit (0-9), and one of the following symbols (&#64;$!%*#?&~|+£:;-)
                                </span>
                                </div>
                                </div>
                                <div *ngIf="changePasswordForm.hasError('notSame')" class="p-invalidtext-wrapper">
                                <span class="p-invalidtext">
                                    Passwords do not match
                                </span>
                                </div>
                       
                    </div>
                </div>

            </div>
        </div>

        <div class="card-footer">
            <p-button type="submit" id="changePasswordSubmit" 
               >Change</p-button>
        </div>

    </form>
</div>