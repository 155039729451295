import { ShareFilterFormComponent } from '../share-filter-form/share-filter-form.component';
import { ShareFilterCriteria } from './share-filter-criteria'

// the filter for search plus pagination information

export class FilterUpdatesModel extends ShareFilterCriteria
{
    constructor(shareFilterCriteria: ShareFilterCriteria)  {
        super();
        this.domain = shareFilterCriteria.domain;
        this.consumer = shareFilterCriteria.consumer;
        this.source = shareFilterCriteria.source;
        this.status = shareFilterCriteria.status;
        this.processedStatus = shareFilterCriteria.processedStatus;
        this.user = shareFilterCriteria.user;
    }

    page: number;
    pageSize: number;
}