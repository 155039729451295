import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({
    providedIn: 'root'
})
export class DataSanitisationService {

 
    constructor(private sanitizer: DomSanitizer) { }

    public sanitiseFormValues(form: any) {
        Object.keys(form.controls).forEach(key => {
            if (form.value[key] && typeof form.value[key] !== "boolean" && !Array.isArray(form.value[key]) && typeof form.value[key] !== "number" ) {
                form.value[key] = this.sanitizer.sanitize(SecurityContext.HTML, form.value[key]);
                form.value[key] = form.value[key] == "" ? form.value[key] : form.value[key].replace(/<[^>]+>/ig, "");
            }
        });
    }

    
}
