<div class="modal" id="matchRevaluationPopup" [ngStyle]="{display: showMatchRevaluationPopup === true ? 'block' : 'none'}">
  
    <div class="modal-dialog modal-xl modal-dialog-centered">

        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Re-Evaluation Results</h5>
                <button type="button" id="bRequestClose" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="dismissModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p-table [value]="evaluationData.scoreAndDescription" class="custom-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th></th> <!-- Empty header for row labels -->
                            <th>Current</th>
                            <th>Updated</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td><strong>{{ rowData.label }}</strong></td>
                            <td>{{ rowData.current }}</td>
                            <td>{{ rowData.updated }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="modal-footer">
                <button pButton [disabled]="evaluationData.matchThreshold < evaluationData.scoreAndDescription[0].updated"
                    label="Reject" (click)="acceptReEvaluation(actionEnumValues.Delete)" class="mr-2"></button>
                <button pButton type="button" label="Update" [disabled]="evaluationData.scoreAndDescription[0].current == evaluationData.scoreAndDescription[0].updated" 
                    (click)="acceptReEvaluation(actionEnumValues.Update)" class="mr-2"></button>
                <button pButton type="button" [disabled]="evaluationData.mergeThreshold > evaluationData.scoreAndDescription[0].updated"
                     label="Merge" (click)="acceptReEvaluation(actionEnumValues.Merge)" class="mr-2"></button>
                <button pButton type="button" label="Cancel" (click)="dismissModal()"></button>
            </div>
        </div>
    </div>
</div>