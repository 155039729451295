import { first } from 'rxjs/operators';
import { ResetPassword } from './../models/reset-password';
import { AccountService } from './../account.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { PasswordConfirmationValidatorService } from './../../shared/custom-validators/password-confirmation-validator.service';
import { DataSanitisationService } from 'src/app/shared/custom-validators/data-sanitisation.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: UntypedFormGroup;
  public showSuccess: boolean;
  public showError: boolean;
  public errorMessage: string;
  public passwordPattern = "^(?=.*\\d)(?=.*[a-zA-Z])(?=.*[@$!%*#?&~|+£:;-]).*$";

  private token: string;
  private id: string;

  constructor(private accountService: AccountService, 
    private passConfValidator: PasswordConfirmationValidatorService, 
    private route: ActivatedRoute, 
    private dataSanitisation:DataSanitisationService) { }

  ngOnInit(): void {
    this.resetPasswordForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required]),
      confirm: new UntypedFormControl('')
    }, { validators: [this.checkPasswordForInvalidCharacters] });

    this.resetPasswordForm.get('confirm').setValidators([Validators.required,
    this.passConfValidator.validateConfirmPassword(this.resetPasswordForm.get('password'))]);
    
    this.token = this.route.snapshot.queryParams['code'];
    this.id = this.route.snapshot.queryParams['userId'];
  }

  checkPasswordForInvalidCharacters(group: UntypedFormGroup) {
    const password:string = group.get('password').value;
    return password.includes("\\") ? { containsInvalidChar: true } : null;
  }

  isValidResetPasswordControl(fieldName): boolean {
    return (
      this.resetPasswordForm.controls[fieldName].invalid &&
      (this.resetPasswordForm.controls[fieldName].dirty ||
        this.resetPasswordForm.controls[fieldName].touched)
    );
  }

  public validateControl = (controlName: string) => {
    return this.resetPasswordForm.controls[controlName].invalid && this.resetPasswordForm.controls[controlName].touched
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.resetPasswordForm.controls[controlName].hasError(errorName)
  }

  public resetPassword = (resetPasswordFormValue: any) => {
    this.dataSanitisation.sanitiseFormValues(this.resetPasswordForm);
    this.showError = false;
    this.showSuccess = false;

    const resetPass = { ... resetPasswordFormValue };
    const resetPassword: ResetPassword = {
      password: resetPass.password,
      confirmPassword: resetPass.confirm,
      token: this.token,
      id: this.id
    }

    this.accountService
        .resetPassword('api/account/resetpassword', resetPassword)
        .pipe(first())
        .subscribe({
          next: () => {
            this.showSuccess = true;
          },
          error: err => {
            this.showError = true;
            this.errorMessage = err;
          }
      });
  }
}
