<section class="content">

  <div *ngIf="isSingleValue">
    <ul class="list-group">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h3 class="card-title">{{ Title }} {{ singleValueFieldName }}</h3>
    </div>

    <div class="card-text">
          <li class="list-group-item">
      <div class="row">
        <div class="col-sm-3">
          <label class="control-label">{{ singleValueFieldName }}</label>
        </div>
        <div class="col-sm-9">
          <div *ngIf="isDate(singleValueFieldType) === true" class="col-sm-9">
            <label class="control-label">
              {{ language === "en-US" ? (singleValueFieldValue | date: 'MM/dd/yyyy, h:mm a': 'UTC') : singleValueFieldValue | date: 'short' : 'UTC'}}
            </label>
          </div>
          <div *ngIf="isInteger(singleValueFieldType) === true" class="col-sm-9">
            <label class="control-label">{{ singleValueFieldValue | number: '1.0-0' }}</label>
          </div>
          <div *ngIf="isDecimal(singleValueFieldType) === true" class="col-sm-9">
            <label class="control-label">{{ singleValueFieldValue | number: '1.0-3' }}</label>
          </div>
          <div *ngIf="isString(singleValueFieldType) === true" class="col-sm-9">
            <label class="control-label">{{ singleValueFieldValue }}</label>
          </div>
        </div>
      </div>
      </li>
    </div>

    <div class="card-footer">

    </div>
</ul>
  </div>

  <div *ngIf="isComplexValue">
      <ul class="list-group">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h3 class="card-title">{{ Title }} {{ complexValueFieldName }}</h3>
    </div>

    <div class="card-text" *ngFor="let value of this.complexValueValues">
            <li class="list-group-item">
      <div class="row">
        <div class="col-sm-3">
          <label class="control-label">{{ value.FieldName }}</label>
        </div>
        <div *ngIf="isDate(value.Type) === true" class="col-sm-9">
          <label class="control-label">
            {{ language === "en-US" ? (value.Value | date: 'MM/dd/yyyy, h:mm a') : value.Value | date: 'short' : 'UTC'}}
          </label>
        </div>
        <div *ngIf="isInteger(value.Type) === true" class="col-sm-9">
          <label class="control-label">{{ value.Value | number: '1.0-0' }}</label>
        </div>
        <div *ngIf="isDecimal(value.Type) === true" class="col-sm-9">
          <label class="control-label">{{ value.Value | number: '1.0-3' }}</label>
        </div>
        <div *ngIf="isString(value.Type) === true" class="col-sm-9">
          <label class="control-label">{{ value.Value }}</label>
        </div>
      </div>
      </li>
    </div>

    <div class="card-footer">

    </div>
  </ul>
  </div>

</section>
