import { Injectable } from '@angular/core';
import { ConfirmEventType, ConfirmationService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorModalComponent } from '../modal-popup/error-modal/error-modal.component';
import { InfoModalComponent } from '../modal-popup/info-modal/info-modal.component';


@Injectable({
  providedIn: 'root',
  
})
export class ModalService {
  subject: Subject<boolean>;
  private _dialogService;
  constructor(private confirmationService:ConfirmationService, private dialogService: DialogService) { 
 
  }

  confirmDelete(): Observable<boolean> {
  
    return new Observable<boolean>((observer) => {
      this.confirmationService.confirm({
        message: 'Do you want to delete this record?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          observer.next(true);
        },
        reject: (type) => {
            switch (type) {
                case ConfirmEventType.REJECT:
                  observer.next(false);

                    break;
                case ConfirmEventType.CANCEL:
                  observer.next(false);
                    break;
            }
        },
        
        key: 'positionDialog'
        
    });
    
    });
  }
  displayErrorModal(errorMessage: string, p0='', p1: { enableHtml: boolean; }) {
    this.dialogService.open(ErrorModalComponent, {
      header: 'Error',
      width: '30vw',
      position:'center',
      resizable:true,
    
      data: {
        message: errorMessage
      },
      
    });
  }

  displayInfoModal(infoMessage: string) {
    this.dialogService.open(InfoModalComponent, {
      header: 'Info',
      width: '30vw',
      position:'center',
      resizable: true,
    
      data: {
        message: infoMessage
      },
      
    });
  }

  displayWarningModal(warningMessage: string) {
    this.dialogService.open(InfoModalComponent, {
      header: 'Info',
      width: '30vw',
      position:'center',
      resizable: true,
    
      data: {
        message: warningMessage
      },
      
    });
  }
}
