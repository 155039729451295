import { IDataSummary } from './models/data-summary';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppStatus } from './models/app-status';
import { ISourceSummary } from './models/source-summary';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  appStatusUrl = "/api/Dashboard/AppStatus";
  domainModelUrl = "/api/Dashboard/DataSummary";
  sourceSummaryUrl = "/api/Dashboard/SourceSummary";
  licenseexpirydaysUrl: string = '/api/Licence/ExpiryDaysLeft';

  licenseExpiryDays: Observable<number>;
  appStatuses: Observable<IAppStatus[]>;
  sourceSummary: Observable<ISourceSummary[]>;
  domainModels: Observable<IDataSummary[]>;

  constructor(private http: HttpClient) { }

  getLicenseExpiryDays(): Observable<number> {
    this.licenseExpiryDays = this.http.get<number>(this.licenseexpirydaysUrl);
    return this.licenseExpiryDays;
  }

  getAppStatus(): Observable<IAppStatus[]> {
    this.appStatuses = this.http.get<IAppStatus[]>(this.appStatusUrl);
    return this.appStatuses;
  }

  getDomainModels(): Observable<IDataSummary[]> {
    this.domainModels = this.http.get<IDataSummary[]>(this.domainModelUrl);
    return this.domainModels;
  }

  getSourceSummary(): Observable<ISourceSummary[]> {
   this.sourceSummary = this.http.get<ISourceSummary[]>(this.sourceSummaryUrl);
   return this.sourceSummary;
  }
}