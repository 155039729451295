<div class="main-bg"></div>
<div class="overlay"></div>
<div class="login-wrapper">
    <div class="login-box">
        <div class="login-left-section">
            <div class="login-header">
                <h1>Civica MDM</h1>
                <span>Master Data Management</span>
            </div>
            <div class="login-content">
                <ul>
                    <li>
                        <span class="data-icon"><i class="ti ti-loop"></i></span>
                        <span>Complete Data Lifecycle</span>
                    </li>
                    <li>
                        <span class="data-icon"><i class="ti ti-control-forward"></i></span>
                        <span>Faster Decision Making</span>
                    </li>
                    <li>
                        <span class="data-icon"><i class="ti ti-zoom-in"></i></span>
                        <span>Explore Your Data</span>
                    </li>
                    <li>
                        <span class="data-icon"><i class="ti ti-bolt"></i> </span>
                        <span>Scale And Performance</span>
                    </li>
                </ul>
                <div class="pull-right">
                    <small class="text-muted" id="buildId">Build Number: {{buildNumber}}</small>
                </div>
            </div>
        </div>
        <div class="login-right-section">
            <h3>Signing into your account - please wait...</h3>
        </div>
    </div>
</div>


