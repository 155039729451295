import { User } from 'src/app/account/models/user';

import { AccountService } from './../account/account.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { PermissionFunctions } from '../shared/models/permission-functions';
import { Role } from '../security-configuration/role-administration/models/roles';

@Injectable({ providedIn: 'root' })
export class RolesGuard  {

    private rolePermissions: Role[];
    
    constructor(private router: Router, private accountService: AccountService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.rolePermissions = JSON.parse(localStorage.getItem('rolePermissions'));
        switch (route.routeConfig.path) {
            case 'inbox': {
                return this.currentUserHasAccess([PermissionFunctions.Inbox], 'read-only')
            }
            case 'matches': {
                return this.currentUserHasAccess([PermissionFunctions.Matches, PermissionFunctions.MatchAnalysis, PermissionFunctions.ScoringCombinations], 'read-only')
            }
            case 'match':
            case 'matchdetails': {
                return this.currentUserHasAccess([PermissionFunctions.Matches], 'read-only')
            }
            case 'matchanalysis':
            case 'matchanalysisgrid':
            case 'matchanalysisviewcombination': {
                return this.currentUserHasAccess([PermissionFunctions.MatchAnalysis], 'read-only')
            }
            case 'scoringcombination': {
                return this.currentUserHasAccess([PermissionFunctions.ScoringCombinations], 'read-only')
            }
            case 'relationships': {
                return this.currentUserHasAccess([PermissionFunctions.RelationshipMatches, PermissionFunctions.RelationshipMatchAnalysis], 'read-only')
            }
            case 'relationshipmatch':
            case 'relationshipmatchdetails': {
                return this.currentUserHasAccess([PermissionFunctions.RelationshipMatches], 'read-only')
            }
            case 'relationshipmatchanalysis':
            case 'viewcombination': {
                return this.currentUserHasAccess([PermissionFunctions.RelationshipMatchAnalysis], 'read-only')
            }
            case 'entity': {
                return this.currentUserHasAccess([PermissionFunctions.Entities], 'read-only')
            }
            case 'jobs': {
                return this.currentUserHasAccess([PermissionFunctions.BulkJobs], 'read-only');
            }
            case 'domainmodel': {
                return this.currentUserHasAccess([PermissionFunctions.DomainModels], 'read-only')
            }
            case 'relationshipmodel/create': {
                return this.currentUserHasAccess([PermissionFunctions.RelationshipModels], 'read-only')
            }
            case 'sources': {
                return this.currentUserHasAccess([PermissionFunctions.Sources], 'read-only')
            }
            case 'securityconfiguration': {
                return this.currentUserHasAccess([PermissionFunctions.Users, PermissionFunctions.Roles], 'read-only')
            }
            case 'useradministration': {
                return this.currentUserHasAccess([PermissionFunctions.Users], 'read-only')
            }
            case 'roleadministration': {
                return this.currentUserHasAccess([PermissionFunctions.Roles], 'read-only')
            }
            case 'share': {
                return this.currentUserHasAccess([PermissionFunctions.Share], 'read-only')
            }
            case 'workflow': {
                const lastItem = state.url.substring(state.url.lastIndexOf('/') + 1)
                if (lastItem == 'inbox') {
                    return this.currentUserHasAccess([PermissionFunctions.Inbox], 'read-only')
                } else if (lastItem == 'taskdetails') {
                    return this.currentUserHasAccess([PermissionFunctions.Inbox], 'read-only')

                }
                else if (lastItem == 'inboxdetails') {
                    return this.currentUserHasAccess([PermissionFunctions.Inbox], 'read-only')
                }
                else {
                    return this.currentUserHasAccess([PermissionFunctions.Inbox], 'read-only')
                }
            }
            case 'configuration': {
                return this.currentUserHasAccess([PermissionFunctions.Configuration], 'read-only')
            }
            case 'types': {
                return this.currentUserHasAccess([PermissionFunctions.Configuration], 'read-only');
            }
            case 'audit': {
                return this.currentUserHasAccess([PermissionFunctions.Audit], 'read-only')
            }
            case 'enrichment': {
                return this.currentUserHasAccess([PermissionFunctions.EnrichStewardship], 'read-only')
            }
            case 'enrichmentconfiguration': {
                return this.currentUserHasAccess([PermissionFunctions.EnrichConfiguration], 'read-only')
            }
        }
    }

    currentUserHasAccess(pages: PermissionFunctions[], accessType: 'full' | 'read-only'): boolean {
        for (const page of pages) {
            for (const rolePermission of this.rolePermissions) {
                const uiPermission = rolePermission.uiPermissions.find(uiPerm => uiPerm.page.toString() === page.toString());
                if (uiPermission) {
                    if (accessType === 'full' && uiPermission.isFullAccess) {
                        return true;
                    }
                    if (accessType === 'read-only' && (uiPermission.isReadOnlyAccess || uiPermission.isFullAccess)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
