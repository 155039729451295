import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss']
})
export class CustomDatePickerComponent implements OnInit {

  @Input() id: string;
  @Input() name: string;
  @Input() placeHolder: string;
  @Output() dateSelectEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private localeService: BsLocaleService) { }

  ngOnInit(): void {
    if (navigator.language === 'en-GB')
    {
      this.localeService.use('en-gb');
    }
    else //(navigator.language === 'en-US'){
    {
      this.localeService.use('en');
    }
  }
}
