export class GridParameters {
    pageSize?: number;
    page?: number;
    sortOrder?: string;
    sortColumn?: string;
    constructor() {
        this.pageSize = 10;
        this.page = 0;
        this.sortOrder = 'desc';
        this.sortColumn = '';
    }
}
