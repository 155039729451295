<div class="card mt-4 mb-4" *ngIf="showResultsGrid">
    <div class="card-header">
        <h3 class="card-title"> Search Results - {{totalRecordsCount | number:0}} Matches Found</h3>
    </div>
    <div class=card-text>
        <table class="table table-sm entity-matrix-striped table-bordered">
            <thead>
                <tr>
                    <th *ngFor="let col of resultColumns.columns">{{col.columnHeader}}</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let i= index; let update of downstreamUpdates | 
                    paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalRecordsCount }">
                    <tr>
                        <td>{{ update.consumer }}</td>
                        <td>{{ update.source }}</td>
                        <td>{{ update.dataModelFieldName }}</td>
                        <td>
                            <span *ngIf="dateValidatorService.isDate(update.oldValue); else checkDateTime">
                                {{ language === "en-US" ? (update.oldValue | date: 'MM/dd/yyyy') : update.oldValue |
                                date: 'shortDate'}}
                            </span>
                        </td>
                        <ng-template #checkDateTime>
                          <span *ngIf="dateValidatorService.isDateTime(update.oldValue); else noCheck">
                            {{
 language === "en-US" ? (update.oldValue | date: 'MM/dd/yyyy, h:mm a': 'UTC') :
                                update.oldValue | date: 'short': 'UTC'
                            }}
                          </span>
                        </ng-template>
                        <ng-template #noCheck>
                            {{update.oldValue}}
                        </ng-template>
                        <td>
                            <span *ngIf="dateValidatorService.isDate(update.newValue); else checkDateTime">
                                {{ language === "en-US" ? (update.newValue | date: 'MM/dd/yyyy') : update.newValue |
                                date: 'shortDate'}}
                            </span>
                        </td>
                        <ng-template #checkDateTime>
                          <span *ngIf="dateValidatorService.isDateTime(update.newValue); else noCheck">
                            {{
 language === "en-US" ? (update.newValue | date: 'MM/dd/yyyy, h:mm a': 'UTC') :
                                update.newValue | date: 'short': 'UTC'
                            }}
                          </span>
                        </ng-template>
                        <ng-template #noCheck>
                            {{update.newValue}}
                        </ng-template>
                        <td>
                            <div class="float-left">

                                <button [attr.id]="'ab_details_' + i" type="button" class="btn btn-info btn-sm mr-1"
                                    (click)="onViewRow(i)">View</button>

                                <button [attr.id]="'ab_accept_' + i" type="button" class="btn btn-success btn-sm mr-1"
                                    *ngIf="hasAcceptButton(update)" (click)="onAcceptRow(update)">Accept</button>

                                <a [attr.id]="'ab_reject_' + i" class="btn btn-danger btn-sm dropdown-toggle"
                                    data-toggle="dropdown" href="#" *ngIf="hasRejectButton(update)" role="button"
                                    aria-haspopup="true" aria-expanded="false">Reject</a>
                                <ul class="dropdown-menu">
                                    <ng-container *ngFor="let i = index; let reason of rejectionReasons.reasons">
                                        <li>
                                            <a role="button" class="dropdown-item" id="al_performaction_{{m + 1}}"
                                                (click)="onRejectRow(update, reason.key)">{{ reason.value }}</a>
                                        </li>
                                    </ng-container>
                                </ul>

                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div *ngIf="totalRecordsCount > 10" class="card-footer">
        <div class="float-right">
            <app-custom-pagination (onPageChanged)="onPageChanged($event)"></app-custom-pagination>
        </div>
    </div>
</div>
