import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ISearchCriteriaResponse } from '../../entity/models/search-criteria-response';
import { ISearchCriteriaRequest } from '../../entity/models/search-criteria-request';
import { ISearchEntitiesCriteria } from '../models/Search/search-entities-criteria';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  appStatusUrl = '/api/entity';
  searchUrl = '/api/search';
  unmergeUrl = '/api/search/unmerge';
  saveurl = '/api/entity/Update';
  myRecordsUrl = '/api/myRecords';

  constructor(private http: HttpClient) {}

  postSearchCriteria(
    searchCriteriaRequest: ISearchCriteriaRequest
  ): Observable<any> {
    const body = JSON.stringify(searchCriteriaRequest);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<ISearchCriteriaResponse>(
        this.searchUrl + '/search-criteria',
        body,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }

  postSearchResult(searchEntitiesCriteria: ISearchEntitiesCriteria) {
    const body = JSON.stringify(searchEntitiesCriteria);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<any>(this.searchUrl + '/ui-search', body, {
        headers: headers,
      })
      .pipe(catchError(this.handleError));
  }

  addToMyRecords(virtualId: string, entityType: string) {
    const body = null;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<any>(
        this.myRecordsUrl +
          '/AddToMyRecords?virtualId=' +
          virtualId +
          '&entityType=' +
          entityType,
        body,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }

  viewEntity(EntityViewModel: any, workflowTasks: any) {
    const body = EntityViewModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<any>(
        this.appStatusUrl + '/View?workflowTask=' + workflowTasks,
        body,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }

  clearPriorities(parameters) {
    const body = parameters;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<any>(this.appStatusUrl + '/ClearPriorities', body, {
        headers: headers,
      })
      .pipe(catchError(this.handleError));
  }
  updateEntity(parameters) {
    const body = parameters;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<any>(this.appStatusUrl + '/UpdateMetadata', body, {
        headers: headers,
      })
      .pipe(catchError(this.handleError));
  }

  editEntity(virtualId, entityType, matchesPage) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .get<any>(
        this.appStatusUrl +
          '/Edit?virtualId=' +
          virtualId +
          '&entityType=' +
          entityType +
          '&matchesPage=' +
          matchesPage,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }
  addEntity(virtualId, entityType, matchesPage) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .get<any>(
        this.appStatusUrl +
          '/Add?virtualId=' +
          virtualId +
          '&entityType=' +
          entityType +
          '&matchesPage=' +
          matchesPage,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }
  getReferenceValues(searchText: any, domainName: any, category: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .get(
        this.appStatusUrl +
          '/GetReferenceValuesSearch?searchText=' +
          searchText +
          '&domainName=' +
          domainName +
          '&category=' +
          category,
        { headers: headers }
      )
      .pipe(catchError(this.handleError));
  }

  update(entitySaveRequest: any): Observable<any> {
    const body = entitySaveRequest;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/multivue+json');

    return this.http
      .post<any>(this.saveurl
        ,body, {
        headers: headers
      })
      .pipe(catchError(this.handleError));
  }

  acceptMatch(matchId: any, entityType: any, id: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const body = null;
    return this.http
      .post(
        this.appStatusUrl +
          '/AcceptMatch?matchId=' +
          matchId +
          '&entityType=' +
          entityType +
          '&id=' +
          id,
        body,
        { headers: headers }
      )
      .pipe(catchError(this.handleError));
  }


  rejectMatch(matchId: any, entityType: any, id: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const body = null;
    return this.http
      .post(
        this.appStatusUrl +
          '/RejectMatch?matchId=' +
          matchId +
          '&entityType=' +
          entityType +
          '&id=' +
          id,
        body,
        { headers: headers }
      )
      .pipe(catchError(this.handleError));
  }

  holdMatch(matchId: any, entityType: any, id: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const body = null;
    return this.http
      .post(
        this.appStatusUrl +
          '/HoldMatch?matchId=' +
          matchId +
          '&entityType=' +
          entityType +
          '&id=' +
          id,
        body,
        { headers: headers }
      )
      .pipe(catchError(this.handleError));
  }

 
  updateRelationship(
    relationship: any,
    entityType: any,
    entityId: any,
    index: any
  ) {
    const headers = { 'content-type': 'application/json' };
    const body = relationship;
    return this.http
      .post(
        this.appStatusUrl +
          '/UpdateRelationship?entityType=' +
          entityType +
          '&entityId=' +
          entityId +
          '&selectcedRelationIndex=' +
          index,
        body,
        { headers: headers }
      )
      .pipe(catchError(this.handleError));
  }

   acceptRelationship(
    relationship: any
  ) {
    const headers = { 'content-type': 'application/json' };
    const body = relationship;
    return this.http
      .post(
        this.appStatusUrl +
          '/AcceptRelationship',
        body,
        { headers: headers }
      )
      .pipe(catchError(this.handleError));
  }

  acceptRelationshipMatch(
    relationship: any
  ) {
    const headers = { 'content-type': 'application/json' };
    const body = relationship;
    return this.http
      .post(
        this.appStatusUrl +
        '/AcceptRelationshipMatch',
        body,
        { headers: headers }
      )
      .pipe(catchError(this.handleError));
  }

  rejectRelationshipMatch(
    relationship: any
  ) {
    const headers = { 'content-type': 'application/json' };
    const body = relationship;
    return this.http
      .post(
        this.appStatusUrl +
        '/RejectRelationshipMatch',
        body,
        { headers: headers }
      )
      .pipe(catchError(this.handleError));
  }
  handleError(error: HttpErrorResponse) {
    console.log('Error Message :' + error.message);
    return throwError(error);
  }
}
