import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ShareFilterCriteria } from '../models/share-filter-criteria';
import { ShareFilterOptions } from '../models/share-filter-options';
import { ShareService } from '../share.service';

@Component({
  selector: 'app-share-filter-form',
  templateUrl: './share-filter-form.component.html',
  styleUrls: ['./share-filter-form.component.scss']
})
export class ShareFilterFormComponent implements OnInit {

  @Output() searched = new EventEmitter<ShareFilterCriteria>();
  @Output() cleared = new EventEmitter();

  showSearchFilter = true;

  public domains;
  public sources;
  public users; 

  public statuses = { items: [
    { key: 0, value: 'Pending'},
    { key: 1, value: 'Accepted'},
    { key: 2, value: 'Rejected'},
    { key: 3, value: 'Auto Accepted'},
    { key: 4, value: 'Auto Rejected'},
    { key: 5, value: 'Superceded'}
  ]};
 
  public processes = { items: [
    { key: 0, value: 'Not Processed'},
    { key: 1, value: 'Processed'},
    { key: 2, value: 'Process Failure'}
  ]};

  filterForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private shareService: ShareService) { }

  ngOnInit(): void {
    this.initFilterFormControls();

    this.shareService.getFilterOptions().subscribe((data: ShareFilterOptions) => {
      this.domains = data.domainNames;
      this.sources = data.systemNames;
      this.users = data.userNames;
    });
  }

  initFilterFormControls(): void {
    this.filterForm = this.formBuilder.group({
      domain: [null, Validators.required],
      consumer: [null],
      source: [null],
      status: [null],
      process: [null],
      user: [null]
    });

    this.filterForm.get("status").setValue(0);
    this.filterForm.get("process").setValue(0);
  }

  clearFilterForm(): void {
    this.initFilterFormControls();
    this.cleared.emit();
  }

  onSearch(): void {
    let criteria : ShareFilterCriteria = new ShareFilterCriteria();
    
    criteria.domain = this.filterForm.get("domain").value;
    criteria.consumer = this.filterForm.get("consumer").value;
    criteria.source = this.filterForm.get("source").value;
    criteria.status = Number(this.filterForm.get("status").value);
    criteria.processedStatus = Number(this.filterForm.get("process").value);
    criteria.user = this.filterForm.get("user").value;
    
    this.searched.emit(criteria)
  }

  public show()
  {
    this.showSearchFilter = true;
  }

  public hide()
  {
    this.showSearchFilter = false;
  }

}
