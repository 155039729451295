import { AccountService } from './../../../account/account.service';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { User } from 'src/app/account/models/user';
import { BehaviorSubject, Subscription } from 'rxjs';
import { InboxService } from 'src/app/workflow/inbox/inbox.service';
import { EnrichmentService } from 'src/app/enrichment/enrichment.service';
import { Role } from 'src/app/security-configuration/role-administration/models/roles';
import { PermissionFunctions } from 'src/app/shared/models/permission-functions';
import { ContextSwitchingService } from '../../services/context-switching.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public ShowInbox: boolean;
  public ShowMatches: boolean;
  public ShowRelationships: boolean;
  public ShowSearch: boolean;
  public ShowJobs: boolean;
  public ShowDomainModels: boolean;
  public ShowRelationshipModels: boolean;
  public ShowSources: boolean;
  public ShowSecurity: boolean;
  public ShowShare: boolean;
  public ShowWorkflow: boolean;
  public ShowConfiguration: boolean;
  public ShowAudit: boolean;
  public ShowEnrichment: boolean;
  public ShowEnrichmentConfiguration: boolean;
  public InboxCount: any;
  private domainModels: BehaviorSubject<boolean>;
  private hasDomainModels: boolean;
  private user: User;
  private rolePermissions: Role[];
  errorMessage: any;
  status: boolean = false;
  enrichmentProviders: any;

  MatchStatus: boolean = false;
  RelationshipMatchStatus: boolean = false;
  SourcesStatus: boolean = false;
  SecurityStatus: boolean = false;
  WorkflowStatus: boolean = false;
  ConfigurationStatus: boolean = false;
  EnrichmentStatus: boolean = false;
  hasEnrichmentLicence: boolean = false;
  menuOpen: boolean = true; // Set to true to have the menu open by default
  searchText: string = '';
  subscription: Subscription;
  
  //This is changed in the installer build release pipeline
  public buildNumber = environment.version;
  public currentYear:number;

  @Output() menuOpenChange = new EventEmitter<boolean>();
  ShowMatchScoringCombination: boolean;
  ShowMatchesParent: boolean;
  ShowMatchAnalysis: boolean;
  ShowRelationshipsParent: boolean;
  ShowRelationshipsMatchAnalysis: boolean;
  ShowSecurityParent: boolean;
  ShowUsers: boolean;
  ShowRoles: boolean;
  ShowScoringCombination: boolean;
  constructor(private accountService: AccountService, 
    private inboxService: InboxService, 
    private enrichService: EnrichmentService,
    private contextSwitchingService: ContextSwitchingService,
    private router: Router) {
      this.currentYear = new Date().getFullYear();
      // Subscribe to context change events
    this.subscription = this.contextSwitchingService.contextChanged$.subscribe(() => {
      
      // Reload menu
      this.rolePermissions = JSON.parse(localStorage.getItem('rolePermissions'));
      this.configureMenu();
      this.reloadComponent();
    });

  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);

    // const currentUrl = this.router.url;
    // const navigationExtras: NavigationExtras = {
    //   queryParamsHandling: 'preserve',
    //   preserveFragment: true
    // };
    // this.router.navigate([currentUrl], navigationExtras);
  }

  ngOnDestroy(): void {
    // Unsubscribe from the context change subscription to avoid memory leaks
    this.subscription.unsubscribe();
  }

  menuToggle(): any {
    this.menuOpen = !this.menuOpen;
    this.menuOpenChange.emit(this.menuOpen);
  }

  isMenuItemVisible(menuItemTitle: string): boolean {
    return menuItemTitle.toLowerCase().includes(this.searchText.toLowerCase());
  }

  childMenuToggle(menuName: string): any {
    if (menuName == "match") {
      this.MatchStatus = !this.MatchStatus;

    }
    else if (menuName == "relationship") {
      this.RelationshipMatchStatus = !this.RelationshipMatchStatus;
    }
    else if (menuName == "sources") {
      this.SourcesStatus = !this.SourcesStatus;
    }
    else if (menuName == "security") {
      this.SecurityStatus = !this.SecurityStatus;
    }
    else if (menuName == "workflow") {
      this.WorkflowStatus = !this.WorkflowStatus;
    }
    else if (menuName == "configuration") {
      this.ConfigurationStatus = !this.ConfigurationStatus;
    }
    else if (menuName == "enrichment") {
      this.EnrichmentStatus = !this.EnrichmentStatus;
    }

  }
  ngOnInit(): void {
    const userString = localStorage.getItem('user');
    this.rolePermissions = JSON.parse(localStorage.getItem('rolePermissions'));
    this.user = JSON.parse(userString);
    
    this.accountService.getDomainModelCount().subscribe({
      next: result => {
        if(result > 0)
          localStorage.setItem('hasDomainModels', JSON.stringify(true));
        else
          localStorage.setItem('hasDomainModels', JSON.stringify(false));

        this.hasDomainModels = localStorage.getItem('hasDomainModels') == 'true' ? true : false;
        this.getEnrichmentServices();
      },
      error: err => err
    });
  }

  configureMenu() {
    this.hasDomainModels = localStorage.getItem('hasDomainModels') == 'true' ? true : false;
    this.ShowInbox = this.hasDomainModels && this.currentUserHasAccess([PermissionFunctions.Inbox], 'read-only');
    this.ShowMatchesParent = this.hasDomainModels && this.currentUserHasAccess([PermissionFunctions.Matches, PermissionFunctions.MatchAnalysis, PermissionFunctions.ScoringCombinations], 'read-only');
    this.ShowMatches = this.hasDomainModels && this.currentUserHasAccess([PermissionFunctions.Matches], 'read-only');
    this.ShowMatchAnalysis = this.hasDomainModels && this.currentUserHasAccess([PermissionFunctions.MatchAnalysis], 'read-only');
    this.ShowScoringCombination = this.hasDomainModels && this.currentUserHasAccess([PermissionFunctions.ScoringCombinations], 'read-only');
    this.ShowRelationshipsParent = this.hasDomainModels && this.currentUserHasAccess([PermissionFunctions.RelationshipMatches, PermissionFunctions.RelationshipMatchAnalysis], 'read-only');
    this.ShowRelationships = this.hasDomainModels && this.currentUserHasAccess([PermissionFunctions.RelationshipMatches], 'read-only');
    this.ShowRelationshipsMatchAnalysis = this.hasDomainModels && this.currentUserHasAccess([PermissionFunctions.RelationshipMatchAnalysis], 'read-only');
    this.ShowSearch = this.hasDomainModels && this.currentUserHasAccess([PermissionFunctions.Search], 'read-only');
    this.ShowJobs = this.hasDomainModels && this.currentUserHasAccess([PermissionFunctions.BulkJobs], 'read-only');
    this.ShowDomainModels = this.currentUserHasAccess([PermissionFunctions.DomainModels], 'read-only');
    this.ShowRelationshipModels = this.currentUserHasAccess([PermissionFunctions.RelationshipModels], 'read-only');
    this.ShowSources = this.currentUserHasAccess([PermissionFunctions.Sources], 'read-only');
    this.ShowSecurityParent = this.currentUserHasAccess([PermissionFunctions.Users, PermissionFunctions.Roles], 'read-only');
    this.ShowUsers = this.currentUserHasAccess([PermissionFunctions.Users], 'read-only');
    this.ShowRoles = this.currentUserHasAccess([PermissionFunctions.Roles], 'read-only');
    this.ShowShare = this.currentUserHasAccess([PermissionFunctions.Share], 'read-only');
    this.ShowWorkflow = this.currentUserHasAccess([PermissionFunctions.WorkflowRules], 'read-only');
    this.ShowConfiguration = this.currentUserHasAccess([PermissionFunctions.Configuration], 'read-only');
    this.ShowAudit = this.currentUserHasAccess([PermissionFunctions.Audit], 'read-only');
    this.ShowEnrichment = this.hasEnrichmentLicence && this.currentUserHasAccess([PermissionFunctions.EnrichStewardship], 'read-only');
    this.ShowEnrichmentConfiguration = this.hasEnrichmentLicence && this.currentUserHasAccess([PermissionFunctions.EnrichConfiguration], 'read-only');
  }

  currentUserHasAccess(pages: PermissionFunctions[], accessType: 'full' | 'read-only'): boolean {
    if (this.rolePermissions && this.rolePermissions !== null) {
      for (const page of pages) {
          for (const rolePermission of this.rolePermissions) {
              const uiPermission = rolePermission.uiPermissions.find(uiPerm => uiPerm.page.toString() === page.toString());
              if (uiPermission) {
                  if (accessType === 'full' && uiPermission.isFullAccess) {
                      return true;
                  }
                  if (accessType === 'read-only' && (uiPermission.isReadOnlyAccess || uiPermission.isFullAccess)) {
                      return true;
                  }
              }
          }
      }
    }
    return false;
}

  getEnrichmentServices() {
    this.enrichService.getEnrichmentServices().subscribe({
      next: (response) => {
        this.enrichmentProviders = response;
        if (this.enrichmentProviders && this.enrichmentProviders.length > 0) {
          this.hasEnrichmentLicence = true;
          this.ShowEnrichment = this.hasEnrichmentLicence && this.currentUserHasAccess([PermissionFunctions.EnrichStewardship], 'read-only');
          this.ShowEnrichmentConfiguration = this.hasEnrichmentLicence && this.currentUserHasAccess([PermissionFunctions.EnrichConfiguration], 'read-only');
          
        }
        this.configureMenu();
      },
      error: (err) => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      },
    });

  }
}
