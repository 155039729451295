import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AccountService } from './account.service';
import { MsalGuardConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular'
import { MsalBroadcastService } from '@azure/msal-angular'
import { MsalService } from '@azure/msal-angular'
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser'


declare var $: any;

@Component({
    selector: 'azure-login',
    templateUrl: './azure-login.component.html'
})
export class AzureLoginComponent implements OnInit  {
    returnUrl: string;
    public message: string; 

    // This is changed in the installer build pipeline
    public buildNumber = '7.0.0.0';

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalBroadCastService: MsalBroadcastService,
        private authService: MsalService,     
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
    ) { }

    ngOnInit() { 

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        
        // Can we auto-login?
        if (sessionStorage.getItem('autoLoginAvailable') !== null) {
            // Attempt login?
            if (sessionStorage.getItem('autoLoginInProgress') === null && sessionStorage.getItem('autoLogin') === null) {

                // Start login
                sessionStorage.setItem('autoLoginInProgress', 'true');
                if (this.msalGuardConfig.authRequest) {
                    this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest)
                }
                else {
                    this.authService.loginRedirect();
                }       
            }

            // Try and login
            this.msalBroadCastService.inProgress$.subscribe(x => {

                // Check for login
                if (x == InteractionStatus.None) {
                    
                    // Got an account?
                    if (this.authService.instance.getAllAccounts().length > 0) {

                        // Log in successful
                        sessionStorage.setItem('autoLogin', 'true');

                        // Login with object id if present
                        var oid = this.authService.instance.getAllAccounts()[0].idTokenClaims.oid;

                        const accessTokenRequest = {
                            scopes: ["user.read"],
                            account: this.authService.instance.getAllAccounts()[0],
                        };     

                        // Store this for use in login
                        var comp = this;
                        
                        // Get token for validation by server
                        this.authService.instance.acquireTokenSilent(accessTokenRequest)
                            .then(function (accessTokenResponse) {
                                comp.accountService.login(oid, accessTokenResponse.accessToken.toString(), 'AzureAdInteractive')
                                .pipe(first())
                                .subscribe(
                                    data => {
                                        localStorage.setItem('passwordExpired', JSON.stringify(false));
                                        sessionStorage.removeItem('autoLoginInProgress');
                                        comp.router.navigate([comp.returnUrl]);
                                    },
                                    error => {
                                        comp.message = error;
                                        sessionStorage.removeItem('autoLoginInProgress');
                                        sessionStorage.removeItem('autoLogin');
                                    });                
                        })
                        .catch(function (error) {
                            console.log(error);
                            sessionStorage.removeItem('autoLoginInProgress');
                            sessionStorage.removeItem('autoLogin');
                            comp.router.navigate(["/login?returnUrl=dashboard"]);                        
                        });                        
                    }
                }
            });
        }
        else {
            this.router.navigate(["/login?returnUrl=dashboard"]);
        }
    }
}
