import { ShareFilterCriteria } from '../models/share-filter-criteria';

// this class contains the information needed to
//  view an update and to navigate between the updates 
//  that were in the share-search-grid
export class ViewUpdateModel
{
    // store the search criteria that produced the updates
    //  this will be used to search for indivdual updates
    criteria: ShareFilterCriteria;

    // the row index of the record that will be viewed first
    rowIndex: number;

    // the page index of the record that will be viewed first
    pageIndex: number;
}