import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/security-configuration/user-administration/models/user';
import { DataSanitisationService } from 'src/app/shared/custom-validators/data-sanitisation.service';
import { AccountService } from '../account.service';
import { first } from 'rxjs';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public changePasswordForm: UntypedFormGroup;

  public oldPasswordTextInput = false;
  public newPasswordTextInput = false;
  public confirmPasswordTextInput = false;
  public showError: boolean;
  public errorMessage: string;

  public passwordPattern = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&~|+£:;-])(?!.*[<>])(?!.*[\"])(?!.*[*])(?!.*[£]).*$";

  constructor(private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private dataSanitisation:DataSanitisationService,
    private modalService:ModalService) { }

  ngOnInit(): void {

    this.changePasswordForm = this.formBuilder.group({
      OldPassword: ['', Validators.required],
      Password: ['', Validators.required],
      ConfirmPassword: ['', Validators.required]
    }, { validators: [this.checkPasswords, this.checkPasswordForInvalidCharacters] });
  }

  checkPasswordForInvalidCharacters(group: UntypedFormGroup) {
    const password:string = group.get('Password').value;
    return password.includes("\\") ? { containsInvalidChar: true } : null;
  }

  private checkPasswords(group: UntypedFormGroup) {
    const password = group.get('Password').value;
    const confirmPassword = group.get('ConfirmPassword').value;
    return password === confirmPassword ? null : { notSame: true };
  }

  onChangePasswordSave(): void {
    this.showError = false;
    this.dataSanitisation.sanitiseFormValues(this.changePasswordForm);
    if (this.changePasswordForm.valid) {
      let user: User = JSON.parse(localStorage.getItem("user"));

      let oldPassword = this.changePasswordForm.value.OldPassword;
      let password = this.changePasswordForm.value.Password;
      let confirmPassword = this.changePasswordForm.value.ConfirmPassword;
        this.accountService
        .changePassword(user.username, oldPassword, password, confirmPassword)
        .pipe(first())
        .subscribe({
          next: () => {
             this.modalService.displayInfoModal("Password Changed");
          },
          error: err => {
            this.showError = true;
            this.errorMessage = err;
            this.modalService.displayErrorModal(this.errorMessage, '', { enableHtml: true });
          }
      });
    }
    else {
      this.validateAllFields(this.changePasswordForm);
    }
  }


  private validateAllFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  public isValidInputControl(fieldName): boolean {
    return (

      this.changePasswordForm.controls[fieldName].invalid &&
      (this.changePasswordForm.controls[fieldName].dirty ||
        this.changePasswordForm.controls[fieldName].touched)
    );
  }

}
