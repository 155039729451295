import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IDomainResult } from './models/domain-result';
import { Observable, catchError, throwError } from 'rxjs';
import { RelationShipModel } from './models/relationship-model';
import { AcceptRelationshipModel } from './relationship';

@Injectable({
  providedIn: 'root'
})
export class RelationshipModelService {

  getRelationshipModelsUrl = "/api/relationshipModels";
  deleteRelationshipModelUrl = "/api/relationshipModels?id=";
  getRelationshipModelCreateUrl = "/api/relationshipModels/Template";
  getRelationshipModelEditUrl = "/api/RelationshipModels/";
  getEntityTypeAttributesUrl = "/api/domainmodels/GetEntityTypeAttributes";
  getProvidersUrl = "/api/domainmodels/providers";
  getBreakingCombinationsUrl = "/api/domainModels/getBreakingCombinations";
  saveRelationshipModelUrl = "/api/relationshipModels";
  getDomainFieldsUrl = "/api/relationshipModels/DomainFields/";
  exportRelationshipModelUrl = "/api/relationshipModels/exportxml/";
  exportRelationshipModelJsonUrl = "/api/relationshipModels/exportJson/";
  importRelationshipModelUrl = "/api/relationshipModels/importxml";
  addRelationshipUrl = "/api/relationship";
  getRelationshipModelAddUrl = "/api/Relationship/";
  editRelationshipModelAddUrl = "/api/Relationship";
  rejectRelationshipModelUrl = "/api/Relationship";
  getRelatedEntitiesUrl = "/api/entity/GetRelatedEntities";

  constructor(private http: HttpClient) { }

  getRelationshipModels(): Observable<IDomainResult> {
    return this.http.get<IDomainResult>(this.getRelationshipModelsUrl);
  }

  deleteRelationshipModel(id) : Observable<any>{
    return this.http.delete<any>(this.deleteRelationshipModelUrl + id);
  }

  getRelationshipModelCreate(): Observable<RelationShipModel> {
    return this.http.get<RelationShipModel>(this.getRelationshipModelCreateUrl);
  }

  getRelationshipModelEdit(entityType : string): Observable<RelationShipModel> {;
    return this.http.get<RelationShipModel>(this.getRelationshipModelEditUrl + entityType);
  }
  getAddRelationshipModel(entityType : string): Observable<any> {
    return this.http.get<any>(this.getRelationshipModelAddUrl + entityType);
  }


  getEntityTypeAttributes(requestObj) : Observable<any>{
    return this.http.post<any>(this.getEntityTypeAttributesUrl, requestObj);
  }

  getProviders() : Observable<any>{
    return this.http.get<any>(this.getProvidersUrl);
  }

  getBreakingCombinations(requestObj) : Observable<any>{
    return this.http.post<any>(this.getBreakingCombinationsUrl, requestObj);
  }

  saveRelationshipModel(requestObj) : Observable<any>{
    return this.http.post<any>(this.saveRelationshipModelUrl, requestObj);
  }

  getDomainFields(entityType) : Observable<any>{
    return this.http.get<any>(this.getDomainFieldsUrl + entityType);
  }

  getRelationshipModelJson(name: string): Observable<string> {
    return this.http.get(this.exportRelationshipModelJsonUrl + name, { responseType: 'text' })
  }

  getRelationshipModelXML(name: string): Observable<string> {
    const headers = new HttpHeaders().set('Accept', 'application/xml');
    return this.http.get(this.exportRelationshipModelUrl + name, {
      headers: headers,
      responseType: 'text'
    });
  }

  importRelationshipModel(requestObj): Observable<any> {
    return this.http.post(this.saveRelationshipModelUrl, requestObj)
      .pipe(catchError(this.handleError));
  }

  importRelationshipModelXml(requestObj: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/xml');

    // Use headers in the HTTP request options
    return this.http.post(this.importRelationshipModelUrl, requestObj, {
      headers: headers
    }).pipe(
      catchError(this.handleError)
    );
  }
  addRelationship(relationship: AcceptRelationshipModel): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    // Use headers in the HTTP request options
    return this.http.post(this.addRelationshipUrl, relationship, {
      headers: headers
    }).pipe(
      catchError(this.handleError)
    );
  }
  editRelationship(viewModel: any, entityType: any, id: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const body = viewModel;
    return this.http
      .post(
        this.editRelationshipModelAddUrl +
          '/EditRelationship?entityType=' +
          entityType +
          '&virtualId=' +
          id,
        body,
        { headers: headers }
      )
      .pipe(catchError(this.handleError));
  }
  rejectRelationship(
    relationship: any
  ) {
    const headers = { 'content-type': 'application/json' };
    const body = relationship;
    return this.http
      .post(
        this.rejectRelationshipModelUrl +
          '/RejectRelationship',
        body,
        { headers: headers }
      )
      .pipe(catchError(this.handleError));
  }
  getRelatedEntities(
    viewModel: any
  ) {
    const headers = { 'content-type': 'application/json' };
    const body = viewModel;
    return this.http
      .post(
        this.getRelatedEntitiesUrl,
        body,
        { headers: headers }
      )
      .pipe(catchError(this.handleError));
  }
  handleError(error: HttpErrorResponse) {
    console.log('Error Message: ' + error.message);
    return throwError(error);
  }
}