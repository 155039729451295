import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { SpinnerInterceptor } from './spinner-overlay/spinner-overlay/spinner-interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { WithCredentialsInterceptorService } from './account/with-credentials-interceptor.service';
import { LoginComponent } from './account/login.component';
import { AzureLoginComponent } from './account/azure-login.component';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay/spinner-overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ChangePasswordComponent } from './account/change-password/change-password.component';

import { MsalModule, MsalService } from '@azure/msal-angular'
import { MsalGuard } from '@azure/msal-angular';
import { MsalInterceptor } from '@azure/msal-angular';
import { MsalRedirectComponent } from '@azure/msal-angular';

import { LOCALE_ID } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeGB from '@angular/common/locales/en-GB';
import localeAUS from '@angular/common/locales/en-AU';
import localeDE from '@angular/common/locales/de';
import localeFR from '@angular/common/locales/fr';
import localeIN from '@angular/common/locales/en-IN';
import { RateInterceptor } from './_helpers/rate-interceptor';
import { SharedModule } from './shared/shared.module';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AppConfigService } from './app-config.service';

registerLocaleData(localeGB, 'en-GB');
registerLocaleData(localeDE, 'de');
registerLocaleData(localeFR, 'fr');
registerLocaleData(localeAUS, 'en-AUS');
registerLocaleData(localeIN, 'en-IN');

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        AzureLoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        SpinnerOverlayComponent,
        ChangePasswordComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        DashboardModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        NgIdleKeepaliveModule.forRoot(),
        ModalModule.forRoot(),
        MatProgressSpinnerModule,
        OverlayModule,
        HttpClientModule,
        MsalModule,
        SharedModule.forRoot()
    ],
    providers: [
        { provide: LOCALE_ID, useValue: navigator.language },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi:true },
        { provide: HTTP_INTERCEPTORS, useClass: RateInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [AppConfigService],
            useFactory: (appConfigService: AppConfigService) => {
              return () => {
                //Make sure to return a promise!
                return appConfigService.loadAppConfig();
              };
            }
        },
        DatePipe, MsalGuard, MsalService,ConfirmationService,DialogService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
