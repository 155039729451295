import { Component, Input, OnInit, SecurityContext } from "@angular/core"; 
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Router } from '@angular/router';
import { ModalService } from '../../shared/services/modal.service';
import * as moment from "moment";
import { RelationshipModelService } from "src/app/domain-model/relationship-model/relationship-model.service";
import { AcceptRelationshipModel } from "src/app/domain-model/relationship-model/relationship";
import { DomSanitizer } from "@angular/platform-browser";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonFunctionsService } from "src/app/shared/services/common-functions.service";
import { SearchService } from "src/app/shared/services/search.service";
declare let $: any;

@Component({
  selector: 'app-add-relationship',
  templateUrl: './add-relationship.component.html',
})

export class AddRelationshipComponent {

  @Input() relationship: any;
  @Input() editRelationship: boolean;
  @Input() relatedEntity: any;
  @Input() viewEntityModel: any;
  @Input() entityData: any;

  showAddRelationshipPopup:boolean = true;
  dataModel: any;
  level = 0;
  errorMessage:any;
  domainModelFields:any;
  domainModelAttributes:any;
  addRelationshipTypeForm:FormGroup;
  isEdit:boolean = false;
  
  constructor(
    private commonFunctionsService: CommonFunctionsService,
    private activeModal: NgbActiveModal,
    // this is not unused, it is being used in html template
    private localeService: BsLocaleService,
    private router: Router,
    private primeNgModalService:ModalService,
    private modalService:NgbModal,
    private relationshipModelService:RelationshipModelService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private searchService:SearchService
    ) {
}

  ngOnInit(): void {
    if(this.editRelationship){
    this.relationshipModelService
      .editRelationship(
        this.viewEntityModel,
        this.relatedEntity.entity.entityType,
        this.relatedEntity.entity.id
      )
      .subscribe({
        next: (response) => {
          this.addRelationshipTypeForm.controls['entity1RelationshipType'].setValue(response.relationship.entity1RelationshipType);
          this.addRelationshipTypeForm.controls['entity2RelationshipType'].setValue(response.relationship.entity2RelationshipType);
          this.getEditAttributesWithValue(response);
        },
        error: (err) => {
          this.errorMessage = err;
        },
      });

    this.isEdit = true;
    }else{
    this.relationshipModelService.getAddRelationshipModel(this.relationship.relationshipModel).subscribe({
      next: response => {
          this.getEditAttributesWithValue(response);
          this.addRelationshipTypeForm.controls['entity1RelationshipType'].setValue(response.relationshipType1);
          this.addRelationshipTypeForm.controls['entity2RelationshipType'].setValue(response.relationshipType2);
          
     
      },
      error: err => this.errorMessage = err
  });
}
    this.showAddRelationshipPopup = true;
    this.initRelationshipControls();

  }
  initRelationshipControls(): void {
    this.addRelationshipTypeForm = this.formBuilder.group({
      entity1RelationshipType:[""],
      entity2RelationshipType:[""]
    });
  }
  getEditAttributesWithValue(response: any) {
    var data = response;
    this.domainModelAttributes = data;
    var entityAttributes = data.dataAttributes;
    this.dataModel = [];
    var dataModelFields = data.relationshipDataModel.fields;

    dataModelFields.forEach((element) => {
      this.dataModel.push(element);
    });

    var generateProperties = entityAttributes.value;
    entityAttributes.value = [];

    generateProperties.forEach((element) => {
      var _ref = element;
      this.setFieldProperties(response, _ref, null, null, null);
      entityAttributes.value.push(_ref);
    });
    this.domainModelFields = entityAttributes.value;
  }
  setFieldProperties(
    response,
    branch,
    dataModel,
    parentFieldName,
    metadataKey
  ) {
    //datamodel needs to be checked
    if (!this.dataModel) {
      return false;
    }

    if (branch == null) {
      return false;
    }

    var internalDataModel =
      dataModel != null && dataModel != ''
        ? dataModel
        : response.relationshipDataModel.fields;
    var compareToFieldName = parentFieldName
      ? parentFieldName
      : branch.fieldName;
    var dataModelField: any;
    dataModelField = this.getObject(
      compareToFieldName,
      internalDataModel,
      true,
      ''
    );
    if (dataModelField) {
      var fieldName =
        metadataKey != null ? metadataKey : dataModelField.fieldName;
      var metaData: any;
      metaData = this.getObject(
        fieldName,
        response.relationshipDataModel.fieldMetadata,
        true,
        ''
      );
      var setNullValue =
        dataModelField.isDateTime ||
        dataModelField.isDate ||
        dataModelField.isInteger ||
        dataModelField.isDecimal;
      branch.isMultiValue = dataModelField.isMultiValue;
      branch.isSingleValue = dataModelField.isSingleValue;
      branch.isMultiple = dataModelField.isMultiple;
      branch.isString = dataModelField.isString;
      branch.isDateTime = dataModelField.isDateTime;
      branch.isInteger = dataModelField.isInteger;
      branch.isDecimal = dataModelField.isDecimal;
      branch.isDate = dataModelField.isDate;
      branch.isUrl = dataModelField.isUrl;
      branch.typesList = this.getPotentialValues(
        dataModelField.potentialValues
      );
      branch.isReference = dataModelField.isReference;
      branch.isClassificationAttribute =
        dataModelField.isClassificationAttribute;
      branch.isHierarchical = dataModelField.isHierarchical;
      branch.isDisabled = metaData.enriched || metaData.verified;

      if (branch.isUrl) {
        if (branch.value) {
          var _value = branch.value;

          if (branch.isSingleValue) {
            branch.value = _value.urlString.toString();
          } else if (branch.isMultiValue) {
            branch.value = [];

            _value.forEach((element) => {
              var _urlFeild = element;
              if (branch.value.urlString) {
                branch.value.push({
                  fieldName: null,
                  value: _urlFeild.value.urlString.toString(),
                });
              }
            });
          }
        }
      }

      if (
        branch.isReference &&
        branch.value &&
        !branch.isMultiple &&
        !parentFieldName
      ) {
        var _value = branch.value;
        var displayValues = [];

        if (branch.isSingleValue) {
          if (branch.isSingleValue) {
            if (
              _value.referencedEntity &&
              _value.referencedEntity.primaryAttribute != null &&
              _value.uniqueIdentifier != null &&
              _value.uniqueIdentifier.identifier != null &&
              _value.uniqueIdentifier.sourceSystem != null
            ) {
              branch.valueObject = {
                display: _value.referencedEntity.primaryAttribute.toString(),
                value:
                  _value.uniqueIdentifier.sourceSystem.toString() +
                  '|' +
                  _value.uniqueIdentifier.identifier.toString(),
              };

              branch.value = branch.valueObject.value;
            } else {
              branch.valueObject = {
                display: '',
                value: '',
              };
              branch.value = null;
            }
          }
        } else if (branch.isMultiValue) {
          branch.value = [];
          _value.forEach((element) => {
            var _refFeild = element;

            if (_refFeild.value) {
              if (_refFeild.value.uniqueIdentifier) {
                var valueObject = {
                  display:
                    _refFeild.value.referencedEntity.primaryAttribute.toString(),
                  value:
                    _refFeild.value.uniqueIdentifier.sourceSystem.toString() +
                    '|' +
                    _refFeild.value.uniqueIdentifier.identifier.toString(),
                };

                _refFeild.valueObject = valueObject;
                _refFeild.value = valueObject.value;
                displayValues.push(valueObject.display);
              }
            } else {
              _refFeild.valueObject = null;
              _refFeild.value = null;
            }

            branch.value.push(_refFeild);
          });
        }
      }

      if (
        dataModelField.isMultiValue &&
        !dataModelField.isMultiple &&
        !parentFieldName
      ) {
        this.setAdded(branch);
        if (!this.hasValues(branch)) {
          branch.value = [];
        }

        branch.value.forEach((element) => {
          if (!this.hasOwnProperty('isMultiValue')) {
            this.setFieldProperties(
              response,
              element,
              internalDataModel,
              branch.fieldName,
              null
            );
          }
        });
      }

      if (dataModelField.isMultiple) {
        if (typeof branch.value !== 'string') {
          branch.value.forEach((element) => {
            // Multiple - Multi Value
            if (branch.isMultiValue) {
              element.value.forEach((elementVal) => {
                this.setFieldProperties(
                  response,
                  elementVal,
                  dataModelField.fields,
                  null,
                  null
                );
              });
            }
            this.setFieldProperties(
              response,
              element,
              dataModelField.fields,
              // $.map(dataModelField.fields, function (value, index) {
              //   return [value];
              // }),
              null,
              null
            );

            // Multiple - Single Value
          });
        }
      }
      if (dataModelField.isDateTime) {
        var _value = branch.value;
        if (_value != null && typeof _value === 'object' && _value.dateTime) {
          branch.value = _value.dateTime;
        }

        this.transform(branch);
      }

      if (dataModelField.isDate) {
        var _value = branch.value;
        if (
          _value != null &&
          typeof _value === 'object' &&
          _value.day &&
          _value.month &&
          _value.year
        ) {
          branch.value = {
            day: _value.day,
            month: _value.month,
            year: _value.year,
          };
        }

        this.transform(branch);
      }

      if (
        branch.typesList != null &&
        branch.typesList.length > 0 &&
        (!branch.isMultiValue || (parentFieldName && branch.isMultiValue))
      ) {
        var temp = branch.value;
        branch.valueObject = {
          display:
            this.isNotNull(branch.typesList[0].display) && !this.isNotNull(temp)
              ? branch.typesList[0].display
              : this.isNotNull(temp)
                ? temp
                : '',
          value:
            this.isNotNull(branch.typesList[0].value) && !this.isNotNull(temp)
              ? branch.typesList[0].value
              : this.isNotNull(temp)
                ? temp
                : '',
        };

        branch.value = branch.valueObject.value;
        branch.display = branch.valueObject.display;
        if (!this.containsObject(branch.valueObject, branch.typesList, true)) {
          branch.typesList.push(branch.valueObject);
        }
      }

      if (dataModelField.isReference) {
        branch.filterKeyword = '';
        if (
          branch.referenceList != undefined &&
          branch.referenceList.displayAsTypeahead
        ) {
          branch.placeholder = 'Please type to view options';
        } else if (
          branch.referenceList != undefined &&
          branch.referenceList.values.length < 1
        ) {
          branch.placeholder = 'No references available';
          branch.isDisabled = true;
        }

        branch.referenceList = this.getReferences(
          dataModelField.dataModelFieldReferencesModel,
          branch
        );
      } else if (dataModelField.isDate || dataModelField.isDateTime) {
        branch.placeholder = 'dd/MM/yyyy';
      } else {
        branch.placeholder = dataModelField.fieldName;
      }
    }

    return false;
  }
  addRemoveMultipleAttribute(
    multiMultiAttributes: any,
    addRemoveValue: any,
    parent: any,
    entityfields: any,
    attributeName: null
  ) {
    if (multiMultiAttributes.fieldName == null) {
      multiMultiAttributes.fieldName = attributeName;

    }

    // don't set fieldname to multimulti array. It causes problem deserializing in API
    const fieldName = multiMultiAttributes.isMultiple ? null : multiMultiAttributes.fieldName;
    const test = JSON.parse(
      JSON.stringify({
        fieldName: fieldName,
        fullFieldName: multiMultiAttributes.fullFieldName,
        fields: entityfields,
        NameParent: multiMultiAttributes.fieldName + '1',
        value: entityfields,
      })
    );
    for (let i = 0; i < this.domainModelFields.length; i++) {
      var element = this.domainModelFields[i];
      this.level = 0;
      var result = this.searchTree(element, multiMultiAttributes);
      if (result != null) {
        let dummyFieldAttribute = '';
        for (let m = 0; m < this.level; m++) {
          dummyFieldAttribute = dummyFieldAttribute + 'fields';
        }
        if (dummyFieldAttribute == '') {
          if (addRemoveValue == 'add') {
            if (this.domainModelFields[i].value === undefined) {
              this.domainModelFields[i].value = [];
            }
            test.NameParent =
              multiMultiAttributes.fieldName +
              this.domainModelFields[i].value.length;
            if (multiMultiAttributes.isString) {
              test.$type = "DataAttributeString"
            } else if (multiMultiAttributes.isInteger) {
              test.$type = "DataAttributeInteger"
            } else if (multiMultiAttributes.isDecimal) {
              test.$type = "DataAttributeDecimal"
            } else if (multiMultiAttributes.isDate) {
              test.$type = "DataAttributeDate"
            } else if (multiMultiAttributes.isDateTime) {
              test.$type = "DataAttributeDateTime"
            } else if (multiMultiAttributes.isUrl) {
              test.$type = "DataAttributeUrl"
            } else if (multiMultiAttributes.isMultiple) {
              test.$type = "DataAttributeCollection"
            } else if (multiMultiAttributes.isMultiValue || multiMultiAttributes.multiVueDomainTypeName == "DataAttributeListModel"|| multiMultiAttributes.multiVueDomainTypeName == "DataAttributeList") {
              test.$type = "DataAttributeList"
            } else if (multiMultiAttributes.isReference) {
              test.$type = "DataAttributeReference"
            }
            this.domainModelFields[i].value.push(test);
          } else {
            this.domainModelFields[i].value?.splice(addRemoveValue, 1);
          }
        } else {
          for (
            let n = 0;
            n < this.domainModelFields[i][dummyFieldAttribute].length;
            n++
          ) {
            var element = this.domainModelFields[i][dummyFieldAttribute][n];
            this.level = 0;
            var result = this.searchTree(
              element,
              multiMultiAttributes
            );
            if (result != null) {
              let dummyFieldAttributeChild = '';
              for (let m = 0; m < this.level; m++) {
                dummyFieldAttributeChild = dummyFieldAttributeChild + 'fields';
              }
              if (dummyFieldAttributeChild == '') {
                if (parent.NameParent != undefined) {
                  //for multi multi attribute in multi multi fields
                  const FieldsLevel = parent.NameParent.charAt(
                    parent.NameParent.length - 1
                  );
                  if (addRemoveValue == 'add') {
                    if (
                      this.domainModelFields[i].value[parseInt(FieldsLevel)][
                        dummyFieldAttribute
                      ][n].value === undefined
                    ) {
                      this.domainModelFields[i].value[parseInt(FieldsLevel)][
                        dummyFieldAttribute
                      ][n].value = [];
                    }
                    this.setParentFieldName(
                      test,
                      multiMultiAttributes,
                      i,
                      FieldsLevel,
                      dummyFieldAttribute,
                      n
                    );
                    this.domainModelFields[i].value[parseInt(FieldsLevel)][
                      dummyFieldAttribute
                    ][n].value.push(test);
                  } else {
                    this.domainModelFields[i].value[parseInt(FieldsLevel)][
                      dummyFieldAttribute
                    ][n].value.splice(addRemoveValue, 1);
                  }
                } else {
                  if (addRemoveValue == 'add') {
                    if (
                      this.domainModelFields[i][dummyFieldAttribute][n]
                        .value === undefined
                    ) {
                      this.domainModelFields[i][dummyFieldAttribute][n].value =
                        [];
                    }
                    this.domainModelFields[i][dummyFieldAttribute][
                      n
                    ].value.push(test);
                  } else {
                    this.domainModelFields[i][dummyFieldAttribute][
                      n
                    ].value.splice(addRemoveValue, 1);
                  }
                }
              } else {
                if (addRemoveValue == 'add') {
                  if (
                    this.domainModelFields[i][dummyFieldAttribute][n][
                      dummyFieldAttributeChild
                    ].value === undefined
                  ) {
                    this.domainModelFields[i][dummyFieldAttribute][n][
                      dummyFieldAttributeChild
                    ].value = [];
                  }
                  this.domainModelFields[i][dummyFieldAttribute][n][
                    dummyFieldAttributeChild
                  ].value.push(test);
                } else {
                  this.domainModelFields[i][dummyFieldAttribute][n][
                    dummyFieldAttributeChild
                  ].value.splice(addRemoveValue, 1);
                }
              }
            }
          }
        }
      }
    }
  }
  private setParentFieldName(
    test: any,
    multiMultiAttributes: any,
    i: number,
    FieldsLevel: any,
    dummyFieldAttribute: string,
    n: number
  ) {
    test.NameParent =
      multiMultiAttributes.fieldName +
      this.domainModelFields[i].value[parseInt(FieldsLevel)][
        dummyFieldAttribute
      ][n].value.length;
    if (multiMultiAttributes.isString) {
      test.$type = "DataAttributeString"
    } else if (multiMultiAttributes.isInteger) {
      test.$type = "DataAttributeInteger"
    } else if (multiMultiAttributes.isDecimal) {
      test.$type = "DataAttributeDecimal"
    } else if (multiMultiAttributes.isDate) {
      test.$type = "DataAttributeDate"
    } else if (multiMultiAttributes.isDateTime) {
      test.$type = "DataAttributeDateTime"
    } else if (multiMultiAttributes.isUrl) {
      test.$type = "DataAttributeUrl"
      } else if (multiMultiAttributes.isMultiValue) {
      test.$type = "DataAttributeList"
      } else if (multiMultiAttributes.isMultiple) {
      test.$type = "DataAttributeCollection"
    } else if (multiMultiAttributes.isReference) {
      test.$type = "DataAttributeReference"
    }

  }

  removeMultipleAttribute(k: number, multiMultiAttributes: any) {
    for (let i = 0; i < this.domainModelFields.length; i++) {
      if (
        this.domainModelFields[i].fieldName === multiMultiAttributes.fieldName
      ) {
        this.domainModelFields[i].value.splice(k, 1);
      }
    }
  }
  
  searchTree(element, attributes) {
    if (element.fieldName === attributes.fieldName || element.fieldName === attributes.placeholder) {
      if (attributes.fullFieldName) {
        if (element.fullFieldName === attributes.fullFieldName) {
          return element;
        }
      }
      else {
        return element;
      }

    } else if (element.fields != null) {
      let i;
      let result = null;
      for (i = 0; result == null && i < element.fields.length; i++) {
        result = this.searchTree(element.fields[i], attributes);
      }
      if (result != null) {
        this.level++;
      }
      return result;
    }
    return null;
  }

  // setFieldProperties(
  //   response,
  //   branch,
  //   dataModel,
  //   parentFieldName,
  //   metadataKey
  // ) {
  //   //datamodel needs to be checked
  //   if (!this.dataModel) {
  //     return false;
  //   }

  //   if (branch == null) {
  //     return false;
  //   }

  //   const internalDataModel =
  //     dataModel != null && dataModel != ''
  //       ? dataModel
  //       : response.dataModel.fields;
  //   const compareToFieldName = parentFieldName
  //     ? parentFieldName
  //     : branch.fieldName;
  //   let dataModelField: any;
  //   dataModelField = this.getObject(
  //     compareToFieldName,
  //     internalDataModel,
  //     true,
  //     ''
  //   );
  //   if (dataModelField) {
  //     const fieldName =
  //       metadataKey != null ? metadataKey : dataModelField.fieldName;
  //     let metaData: any;
  //     metaData = this.getObject(
  //       fieldName,
  //       response.dataModel.fieldMetadata,
  //       true,
  //       ''
  //     );
  //     const setNullValue =
  //       dataModelField.isDateTime ||
  //       dataModelField.isDate ||
  //       dataModelField.isInteger ||
  //       dataModelField.isDecimal;
  //     branch.isMultiValue = dataModelField.isMultiValue;
  //     branch.isSingleValue = dataModelField.isSingleValue;
  //     branch.isMultiple = dataModelField.isMultiple;
  //     branch.isString = dataModelField.isString;
  //     branch.isDateTime = dataModelField.isDateTime;
  //     branch.isInteger = dataModelField.isInteger;
  //     branch.isDecimal = dataModelField.isDecimal;
  //     branch.isDate = dataModelField.isDate;
  //     branch.isUrl = dataModelField.isUrl;
  //     branch.typesList = this.getPotentialValues(
  //       dataModelField.potentialValues
  //     );
  //     branch.isReference = dataModelField.isReference;
  //     branch.isClassificationAttribute =
  //       dataModelField.isClassificationAttribute;
  //     branch.isHierarchical = dataModelField.isHierarchical;
  //     branch.isDisabled = metaData.enriched || metaData.verified;

  //     if (branch.isUrl) {
  //       if (branch.value) {
  //         var _value = branch.value;

  //         if (branch.isSingleValue) {
  //           branch.value = _value.urlString.toString();
  //         } else if (branch.isMultiValue) {


  //           if (Array.isArray(_value)) {
  //             branch.value = [];
  //             _value.forEach((element) => {
  //               const _urlFeild = element;
  //               if (element.value.urlString) {
  //                 branch.value.push({
  //                   fieldName: null,
  //                   value: _urlFeild.value.urlString.toString(),
  //                 });
  //               }
  //             });
  //           }
  //           else if (_value == String) {
  //             branch.value = _value.urlString.toString();
  //           }
  //         }
  //       }
  //     }

  //     if (
  //       branch.isReference &&
  //       branch.value &&
  //       !branch.isMultiple &&
  //       !parentFieldName
  //     ) {
  //       var _value = branch.value;
  //       const displayValues = [];
  //       if (branch.isSingleValue) {
  //         if (branch.isSingleValue) {
  //           if (
  //             _value.referencedEntity &&
  //             //_value.referencedEntity.primaryAttribute != null &&
  //             _value.uniqueIdentifier != null &&
  //             _value.uniqueIdentifier.identifier != null &&
  //             _value.uniqueIdentifier.sourceSystem != null
  //           ) {
  //             branch.valueObject = {
  //               //display: _value.referencedEntity.primaryAttribute.toString() ? _value.referencedEntity.primaryAttribute.toString() : _value.uniqueIdentifier.identifier,
  //               display: _value.uniqueIdentifier.identifier,
  //               value:
  //               _value.uniqueIdentifier.entityType.toString() +
  //                 '|' +
  //                 _value.uniqueIdentifier.sourceSystem.toString() +
  //                 '|' +
  //                 _value.uniqueIdentifier.identifier.toString(),
  //             };

  //             branch.value = branch.valueObject.value;
  //           } else {
  //             branch.valueObject = {
  //               display: '',
  //               value: '',
  //             };
  //             branch.value = null;
  //           }
  //         }
  //       } else if (branch.isMultiValue) {
  //         branch.value = [];
  //         _value.forEach((element) => {
  //           const _refFeild = element;

  //           if (_refFeild.value) {
  //             if (_refFeild.value.uniqueIdentifier) {
  //               const valueObject = {
  //                 display:
  //                   _refFeild.value.referencedEntity.primaryAttribute.toString(),
  //                 value:
  //                   _refFeild.value.uniqueIdentifier.sourceSystem.toString() +
  //                   '|' +
  //                   _refFeild.value.uniqueIdentifier.identifier.toString(),
  //               };

  //               _refFeild.valueObject = valueObject;
  //               _refFeild.value = valueObject.value;
  //               displayValues.push(valueObject.display);
  //             }
  //           } else {
  //             _refFeild.valueObject = null;
  //             _refFeild.value = null;
  //           }

  //           branch.value.push(_refFeild);
  //         });
  //       }
  //     }

  //     if (
  //       dataModelField.isMultiValue &&
  //       !dataModelField.isMultiple &&
  //       !parentFieldName
  //     ) {
  //       this.setAdded(branch);
  //       if (!this.hasValues(branch)) {
  //         branch.value = [];
  //       }

  //       branch.value.forEach((element) => {
  //         if (!this.hasOwnProperty('isMultiValue')) {
  //           this.setFieldProperties(
  //             response,
  //             element,
  //             internalDataModel,
  //             branch.fieldName,
  //             null
  //           );
  //         }
  //       });
  //     }

  //     if (dataModelField.isMultiple) {
  //       if (typeof branch.value !== 'string') {
  //         branch.value.forEach((element) => {
  //           // Multiple - Multi Value
  //           if (branch.isMultiValue) {
  //             element.value.forEach((elementVal) => {
  //               this.setFieldProperties(
  //                 response,
  //                 elementVal,
  //                 dataModelField.fields,
  //                 null,
  //                 null
  //               );
  //             });
  //           }
  //           this.setFieldProperties(
  //             response,
  //             element,
  //             dataModelField.fields,
  //             // $.map(dataModelField.fields, function (value, index) {
  //             //   return [value];
  //             // }),
  //             null,
  //             null
  //           );

  //           // Multiple - Single Value
  //         });
  //       }
  //     }
  //     if (dataModelField.isDateTime) {
  //       var _value = branch.value;
  //       if (_value != null && typeof _value === 'object' && _value.dateTime) {
  //         branch.value = _value.dateTime;
  //       }

  //       this.transform(branch);
  //     }

  //     if (dataModelField.isDate) {
  //       var _value = branch.value;
  //       if (
  //         _value != null &&
  //         typeof _value === 'object' &&
  //         _value.day &&
  //         _value.month &&
  //         _value.year
  //       ) {
  //         branch.value = {
  //           day: _value.day,
  //           month: _value.month,
  //           year: _value.year,
  //         };
  //       }

  //       this.transform(branch);
  //     }

  //     if (
  //       branch.typesList != null &&
  //       branch.typesList.length > 0 &&
  //       (!branch.isMultiValue || (parentFieldName && branch.isMultiValue))
  //     ) {
  //       const temp = branch.value;
  //       branch.valueObject = {
  //         display:
  //           this.isNotNull(branch.typesList[0].display) && !this.isNotNull(temp)
  //             ? branch.typesList[0].display
  //             : this.isNotNull(temp)
  //               ? temp
  //               : '',
  //         value:
  //           this.isNotNull(branch.typesList[0].value) && !this.isNotNull(temp)
  //             ? branch.typesList[0].value
  //             : this.isNotNull(temp)
  //               ? temp
  //               : '',
  //       };

  //       branch.value = branch.valueObject.value;
  //       branch.display = branch.valueObject.display;
  //       //branch.valueObject.disabled = true;
  //       if (!this.containsObject(branch.valueObject, branch.typesList, true)) {
  //         branch.typesList.push(branch.valueObject);
  //       }
  //     }

  //     if (dataModelField.isReference) {
  //       branch.filterKeyword = '';
  //       if (
  //         branch.referenceList != undefined &&
  //         branch.referenceList.displayAsTypeahead
  //       ) {
  //         branch.placeholder = 'Please type to view options';
  //       } else if (
  //         branch.referenceList != undefined &&
  //         branch.referenceList.values.length < 1
  //       ) {
  //         branch.placeholder = 'No references available';
  //         branch.isDisabled = true;
  //       }

  //       branch.referenceList = this.getReferences(
  //         dataModelField.dataModelFieldReferencesModel,
  //         branch
  //       );
  //     } else if (dataModelField.isDate || dataModelField.isDateTime) {
  //       branch.placeholder = 'dd-MM-yyyy';
  //     } else {
  //       branch.placeholder = dataModelField.fieldName;
  //     }
  //   }

  //   return false;
  // }

  getObject(key, list, useFieldName, indexreturn) {

    let i;
    const dot = '.';

    let foundItem = false,
      index = -1;
    if (typeof list === 'undefined' || list == null) {
      return false;
    }

    for (i = 0; i < list.length; i++) {
      if (useFieldName) {
        if (list[i].fieldName !== null && list[i].fieldName === key) {
          index = i;
          foundItem = list[i];
          break;
        }
      }

      if (list[i].key == null || typeof list[i].key === 'undefined') {
        if (list[i] === key) {
          index = i;
          foundItem = list[i];
          break;
        }
      } else if (
        list[i].key === key ||
        list[i].key.indexOf(dot.concat(key)) != -1
      ) {
        index = i;
        foundItem = list[i];
        break;
      }

      if (list[i].sourceField !== null && list[i].sourceField === key) {
        index = i;
        foundItem = list[i];
        break;
      }

      if (list[i].value !== null) {
        if (list[i].value === key) {
          index = i;
          foundItem = list[i];
          break;
        }
      }

      if (list[i].display !== null) {
        if (list[i].display === key) {
          index = i;
          foundItem = list[i];
          break;
        }
      }
    }

    if (indexreturn) {
      indexreturn(index);
    }

    return foundItem;
  }

  getPotentialValues(potentialValues) {
    if (potentialValues == null) {
      return null;
    }

    const valueList = [];
    valueList[0] = { display: '', value: '' };

    $.each(potentialValues, function () {
      valueList.push({ display: this.toString(), value: this.toString() });
    });

    return valueList;
  }
  setAdded(field) {
    if (field == null) {
      return;
    }

    if (typeof field.value === 'string') {
      field.added = true;
      return;
    }

    if (field && field.value && Array.isArray(field.value)) {
      field.value.forEach((element) => {
        this.setAdded(element);
      });
    }
  }
  hasValues(branch) {
    if (!branch.value) {
      return false;
    }

    const _ref = branch.value;
    branch.value = [];

    let valuesExist = false;
    _ref.forEach((element) => {
      if (element.added) {
        valuesExist = true;
        branch.value.push(element);
      } else if (element.value != null && element.value !== '') {
        valuesExist = true;
        branch.value.push(element);
      }
    });

    return valuesExist;
  }

  transform(field) {

    if (field == null || field == '0001-01-01T00:00:00' || field == '0001-01-01' || field.value == '0001-01-01T00:00:00Z') {
      field = ''
      return;
    }
    if (field.value == '0001-01-01T00:00:00' || field.value == '0001-01-01' || field.value == '0001-01-01T00:00:00Z') {
      field.value = ''
      return;
    }
    let date, _value;
    // transform date field from "/Date([int])/" format to localeDateString
    if (typeof field.value === 'string') {
      field.value = moment(field.value).toDate();
      return;
    }

    else if (typeof field.value?.getMonth === 'function') {
      return;
    }

    else if (
      field.value != null &&
      typeof field.value === 'object' &&
      field.value.day &&
      field.value.month &&
      field.value.year
    ) {

      date = new Date(field.value.year, field.value.month - 1, field.value.day);
      if (this.isValidDate(date)) {
        field.value = date;
        return;
      }
      field.value = null;
      return;
    }

    if (field && field.value && typeof field.value === 'object') {
      field.value.forEach((element) => {
        this.transform(element);
      });
    }
  }

  containsObject(obj, list, useValue) {
    let i;
    for (i = 0; i < list.length; i++) {
      if (!useValue) {
        if (list[i].key === obj.key) {
          return true;
        }
      } else {
        if (list[i].value === obj.value) {
          return true;
        }
      }
    }

    return false;
  }

  getReferences(dataModelFieldReferencesViewModel, branch) {
    let displayValues = [];
    if (branch.value && Array.isArray(branch.value)) {
      displayValues = this.getDisplayValues(branch.value);
    }

    if (dataModelFieldReferencesViewModel != null) {
      const referencesObject = {
        values: [],
        displayAsTypeahead: dataModelFieldReferencesViewModel.isTypeAhead,
        referenceDomain: dataModelFieldReferencesViewModel.domain,
        referenceCategory: dataModelFieldReferencesViewModel.category,
      };

      branch.selectedItems = [];
      dataModelFieldReferencesViewModel.references.forEach((element) => {
        const _value = element;
        const isSelected = this.isSelectedItem(_value.displayName, displayValues);
        const item = {
          display: _value.displayName,
          name: _value.displayName,
          isExpanded: false,
          hasChildren: _value.hasChildren,
          children: [],
          value:
            _value.uniqueIdentifier.entityType.toString() +
            '|' +
            _value.uniqueIdentifier.sourceSystem.toString() +
            '|' +
            _value.uniqueIdentifier.identifier.toString(),
          selected: isSelected,
        };
        referencesObject.values.push(item);
      });

      branch.selectedItems = this.generateSelectedList(branch.value);
      return referencesObject;
    }

    return null;
  }
  getDisplayValues(items) {
    const displayValues = [];
    $.each(items, function () {
      displayValues.push(
        this.valueObject ? this.valueObject.display : this.display
      );
    });

    return displayValues;
  }

  generateSelectedList(list) {
    const items = [];
    if (list && Array.isArray(list)) {
      list.forEach((element) => {
        const _value = element;
        if (_value.valueObject) {
          const item = {
            display: _value.valueObject.display,
            name: _value.valueObject.display,
            value: _value.valueObject.value,
            selected: true,
          };

          items.push(item);
        }
      });
    }

    return items;
  }

  isSelectedItem(item, items) {
    return this.getObject(item, items, '', '') !== false;
  }

  isValidDate(date) {
    // This is a HORRIBLE hack but it fixes the madness that is Microsoft Edge...
    if (date.getFullYear() === 1 || date.getFullYear() === 0) {
      return false;
    } else {
      const dateString = this.formatDate(date);
      return (
        dateString !== '/Date(-62135596800000)/' &&
        dateString !== '01/01/0001' &&
        dateString !== '31/12/1' &&
        dateString !== 'Invalid Date'
      );
    }

    return true;
  }
  formatDate(date) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString(navigator.language, options);
  }
  isNotNull(value) {
    if (typeof value === 'object' && value != null && value != undefined) {
      return !this.isEmptyObject(value);
    }

    if (typeof value === 'number') {
      return Number.isFinite(value);
    }

    return value != null && value.trim() !== '';
  }
  isDateTime(element: any) {
    if (element.multiVueDomainType.indexOf('DataAttributeDateTimeModel') < 0) {
      return false;
    }
    // element.dateFormat represents a Date field and not a DateTime field
    else if (element.dateFormat || element.value.dateFormat) {
      return false;
    }
    else if (moment(element.value, moment.ISO_8601, true).isValid() || moment(element, moment.ISO_8601, true).isValid()) {
      return true;
    } else {
      return false;
    }
  }
  isDate(element: any) {
    if (element.multiVueDomainType.indexOf('DataAttributeDateModel') < 0) {
      return false;
    }
    else if (element.dateFormat) {
      return true;
    } else {
      return false;
    }
  }
  isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }
  onClose() {
    this.closeModal();
    $('#addRelationshipModalPopup').modal('hide');
}

closeModal() {
    this.activeModal.close('Modal Closed');
}
  onSubmit(){
    const attributesCopy = JSON.parse(JSON.stringify(this.domainModelAttributes.dataAttributes));
    attributesCopy.value.forEach((obj) => {
      const attribute = obj;
      this.sanitizeValue(attribute);
      if (
        (attribute.value == null ||
          attribute.value == '' ||
          attribute.value == undefined) 
      ){
        attribute.value='';
      }
      
    }
    );
  
    const jsonData = this.replaceProperty(attributesCopy, 'multiVueDomainTypeName', '$type');
    //"Patient|MultiVue|89909-57512345"
 //   this.relationship.attributes = this.domainModelFields;
    if(this.editRelationship){
      this.relationship = this.domainModelAttributes.relationship;
    
    }
 this.relationship.entity1RelationshipType = this.addRelationshipTypeForm.controls['entity1RelationshipType'].value;
 this.relationship.entity2RelationshipType = this.addRelationshipTypeForm.controls['entity2RelationshipType'].value
    this.relationship.attributes = jsonData;
       this.relationshipModelService.addRelationship(this.relationship).subscribe({
           next: response => {
            if(response == "Relationship Accepted"){
            this.showAddRelationshipPopup = false;
            this.activeModal.dismiss();
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['./entity/entity'], {
              state: { responseData: this.entityData },
            });
          }
           },
           error: err => this.errorMessage = err
       });
  }

  sanitizeValue(attribute) {
    if (Array.isArray(attribute.value)) {
      attribute.value.forEach((obj1) => {
        this.sanitizeValue(obj1);
      });
    } else {
      attribute.value = this.sanitizer.sanitize(SecurityContext.HTML, attribute.value);
      attribute.value = attribute.value == "" ? attribute.value : attribute.value?.replace(/<[^>]+>/ig, "");
    }
   }
   replaceProperty(json: any, oldPropertyName: string, newPropertyName: string): any {
    if (typeof json === 'object') {
      if (Array.isArray(json)) {
        json.forEach((item: any) => {
          this.replaceProperty(item, oldPropertyName, newPropertyName);
        });
      } else {
        for (const key in json) {
          if (json.hasOwnProperty(key)) {
            if (key === oldPropertyName) {
              json[newPropertyName] = json[key];
              delete json[key];
              if (newPropertyName === '$type' && typeof json[newPropertyName] === 'string' && json[newPropertyName].endsWith('Model')) {
                json[newPropertyName] = json[newPropertyName].substring(0, json[newPropertyName].length - 'Model'.length);

              }
              if (newPropertyName === '$type') {
               if (json.isMultiValue) {
                  json[newPropertyName] = "DataAttributeList"
                  }
                else if (json.isString) {
                  json[newPropertyName] = "DataAttributeString"
                } else if (json.isInteger) {
                  json[newPropertyName] = "DataAttributeInteger"
                } else if (json.isDecimal) {
                  json[newPropertyName] = "DataAttributeDecimal"
                } else if (json.isDate) {
                  json[newPropertyName] = "DataAttributeDate"
                } else if (json.isDateTime) {
                  json[newPropertyName] = "DataAttributeDateTime"
                } else if (json.isUrl) {
                  json[newPropertyName] = "DataAttributeUrl"
                }else if (json.isMultiple) {
                  json[newPropertyName] = "DataAttributeCollection"
                } else if (json.isReference) {
                  json[newPropertyName] = "DataAttributeReference"
                }
              }
            } else {
              this.replaceProperty(json[key], oldPropertyName, newPropertyName);
            }
          }
        }
      }
    }
    return json;
  }
}
