import { IHistoryItem } from './../../../account/models/history-item';
import { User } from 'src/app/account/models/user';
import { AccountService } from './../../../account/account.service';
import { Component, OnInit, Injectable, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionFunctions } from 'src/app/shared/models/permission-functions';
import { Role } from 'src/app/security-configuration/role-administration/models/roles';
import { NameIdModel } from 'src/app/account/models/access-to';
import { ContextSwitchingService } from '../../services/context-switching.service';
import { MyRecordsService } from 'src/app/entity/my-records.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchResultsGridComponent } from 'src/app/shared/components/search-results-grid/search-results-grid.component';
import { ModalService } from '../../services/modal.service';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private user: User;
  public userName: string;
  public userAvailability: string;
  public targetAvailability: string;
  public historyItems: IHistoryItem[];
  private updatedItems: IHistoryItem[];
  private userAvailable: boolean;
  public showMyRecords: boolean;
  public simpleLogin: boolean;
  public showAddNewEntity: boolean;
  public showHistory: boolean;
  public showSearch: boolean;
  private rolePermissions: Role[];
  public accessToCustomers: NameIdModel[] = [];
  public contextCustomerId: string;
  public instanceName: string;
  public entityTypes: { id: string; name: string }[];
  public selectedEntityType: string;
  private _myRecordsService;

  public fullAccess = 'full';
  public readOnlyAccess = 'read-only';
  constructor(private accountService: AccountService,
    private searchService: SearchService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private contextSwitchingService: ContextSwitchingService,
    myRecordsService: MyRecordsService,
    private modalService: NgbModal,
    public primeNgModalService: ModalService) {
    this._myRecordsService = myRecordsService
  }

  ngOnInit(): void {
    this.changeDetector.detectChanges();
    const user: User = JSON.parse(localStorage.getItem('user'));
    this.rolePermissions = JSON.parse(localStorage.getItem('rolePermissions'));
    if (user) {
      this.user = user;
      if (sessionStorage.getItem('autoLogin') !== null) {
        this.userName = user.fullName != '' ? user.fullName : user.email;
      }
      else {
        this.userName = user.username;
      }
    }
    this.userAvailable = (localStorage.getItem('available') === "true");
    this.populateCustomerAccessDd();
    this.updateAvailabilityStatus();
    this.loadHistory();
    this.configureMenu();
    this.instanceName = localStorage.getItem('instanceName');
  }

  populateCustomerAccessDd() {
    if (this.user) {
      this.accessToCustomers = [];
      this.user.accessToViewModels.forEach(accessTo => {
        this.accessToCustomers.push(accessTo.customer)
      });

      this.contextCustomerId = localStorage.getItem('requestCustomer');
      this.changeDetector.detectChanges();
      this.populateEntityTypes();
    }
  }

  populateEntityTypes() {
    this._myRecordsService.getEntityTypes().subscribe({
      next: domains => {
        this.entityTypes = domains.map(x => ({
          id: x,
          name: x
        }));
      }
    });
  }

  onChangeContextCustomer(event) {
    const user: User = JSON.parse(localStorage.getItem('user'));
    localStorage.setItem('rolePermissions', JSON.stringify(user.accessToViewModels.find(x => x.customer.id == event.value).roles.map(x => x.rolePermission)));
    localStorage.setItem('requestCustomer', user.accessToViewModels.find(x => x.customer.id == event.value).customer.id);
      
    this.accountService.getDomainModelCount().subscribe({
      next: result => {
        if(result > 0){
          localStorage.setItem('hasDomainModels', JSON.stringify(true));
        }
        else{
          localStorage.setItem('hasDomainModels', JSON.stringify(false));
        }
        this.contextSwitchingService.triggerContextChange();
        this.populateEntityTypes();
      },
      error: err => err
    });
  }

  onSearch(event) {
    if (this.selectedEntityType && event.target.value) {
      setTimeout(() => {
        const modalRef = this.modalService.open(SearchResultsGridComponent, { centered: true });
        modalRef.componentInstance.entityType = this.selectedEntityType;
        modalRef.componentInstance.searchText = event.target.value;
      }, 0);
    }
    else {
      this.primeNgModalService.displayErrorModal("Entity type and search text is required");
    }
  }

  updateHistoryItems() {
    const items: any = JSON.parse(localStorage.getItem('history'));
    const user: User = JSON.parse(localStorage.getItem('user'));
    if (items.user === user.username) {
      this.historyItems = items.items;
    }
    else {
      this.loadHistory();
    }
  }

  loadHistory() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    // TBD with auditing integration
    // this.accountService
    //   .loadHistory(user.username)
    //   .subscribe(items => {
    //     this.historyItems = items;
    //     localStorage.setItem('history', JSON.stringify({
    //       'user': user.username,
    //       'items': items
    //     }));
    //   });
  }

  showhistoryItem(entityType: string, entityId: string): void {
    const model: any = {
      EntityType: entityType,
      Id: entityId,
      IncludeEmptyFields: false
    };
    const workflowTask = '';
    this.searchService.viewEntity(model, workflowTask).subscribe({
      next: (response) => {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['./entity/viewentity'], { state: { responseData: response } });
        });
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  updateUserAvailability(): void {
    if (this.userAvailable) {
      this.accountService.setUserAsUnavailable(this.user.id);
    }
    else {
      this.accountService.setUserAsAvailable(this.user.id);
    }

    localStorage.setItem('available', (!this.userAvailable).toString());
  }

  private updateAvailabilityStatus() {
    this.userAvailability = (this.userAvailable) ? "Available" : "Unavailable";
    this.targetAvailability = (this.userAvailable) ? "unavailable" : "available";
  }

  logOff(): void {
    this.accountService.logout();
  }
  configureMenu() {
    this.showMyRecords = this.currentUserHasAccess([PermissionFunctions.Entities], 'read-only');
    this.showAddNewEntity = this.currentUserHasAccess([PermissionFunctions.Entities], 'full');
    this.showHistory = this.currentUserHasAccess([PermissionFunctions.Entities], 'read-only');
    this.showSearch = this.currentUserHasAccess([PermissionFunctions.Search], "read-only");
    this.simpleLogin = (sessionStorage.getItem('autoLogin') === null);
  }

  currentUserHasAccess(pages: PermissionFunctions[], accessType: 'full' | 'read-only'): boolean {
    for (const page of pages) {
      for (const rolePermission of this.rolePermissions) {
        const uiPermission = rolePermission.uiPermissions.find(uiPerm => uiPerm.page.toString() === page.toString());
        if (uiPermission) {
          if (accessType === 'full' && uiPermission.isFullAccess) {
            return true;
          }
          if (accessType === 'read-only' && (uiPermission.isReadOnlyAccess || uiPermission.isFullAccess)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  navigateToAddEntity() {
    const response = {
      virtualId: '',
      entityType: '',
      currentPage: "add",
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./entity/entity'], {
      state: { responseData: response },
    });
  }
  getPageNamesForAuthorization() {
    return [PermissionFunctions.Entities];
  }

}
