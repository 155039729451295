import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
  })
  export class DateValidatorService {

    moment: any = moment;

    constructor() { }

    public isDate(strInput: any): boolean {
        return moment(strInput, 'M/D/YYYY', true).isValid();
    }

    public isDateTime(strInput: any): boolean {
        return moment(strInput, 'M/D/YYYY H:mm:ss A', true).isValid();
    }
  }
