import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

  @Injectable({
    providedIn: 'root'
  })
  export class CustomerService {

    
    getCustomersUrl = "/api/Customers";
    getCustomerUrl = "/api/Customers/Edit?customerName="
    getCustomersListUrl = "/api/Customers/getCustomerList";

    setCustomersUrl = "/api/Customers";

    constructor(private http: HttpClient) { }
    
    getCustomers(): any {
        return this.http.get(this.getCustomersUrl);
      }
    setCustomers(customer): any {
        return this.http.post(this.setCustomersUrl,customer);
      }
      getCustomerList(): any {
        return this.http.get(this.getCustomersListUrl);
      }
      getCustomer(customerName:string): any {
        return this.http.get(this.getCustomerUrl+customerName);
      }
  }