<div class="card">
    <div class="card-body">
        <h1 class="card-title">Reset Password</h1>

        <div *ngIf="showError" class="alert alert-danger" role="alert">
            <span [innerHTML]="errorMessage"></span>
        </div>

        <div *ngIf="showSuccess" class="alert alert-success" role="alert">
            Your password has been reset. Please <a [routerLink]="['login?dashboard']"> click here to log in. </a>
        </div>
        <div class="information">
            <ul>
                <li>Password must be at least 12 characters</li>
                <li>Password must have at least one lowercase (a-z), one uppercase (A-Z), 
                    one digit (0-9), and one of the following symbols (&#64;$!%*#?&~|+£:;-)</li>
                <li>Password cannot contain the \ character</li>
            </ul>
        </div>
        <form [formGroup]="resetPasswordForm" autocomplete="off" novalidate
            (ngSubmit)="resetPassword(resetPasswordForm.value)">
            <div class="form-group required row">
                <label for="password" class="col-form-label col-sm-2">Password:</label>
                <div class="col-md-5">
                    <input pInputText type="password" id="password" formControlName="password" class="form-control" autofocus
                        required="required" minlength="12" [pattern]="passwordPattern" />
                </div>
                <div class="col-md-5">
                    <div  class="p-invalidtext-wrapper"> 
                    <div *ngIf="resetPasswordForm.hasError('containsInvalidChar')" class="p-invalidtext">
                        Password cannot contain a \ character
                    </div>   
                       </div>
                        <em *ngIf="validateControl('password') && hasError('password', 'required')">Password is
                            required</em>
                </div>
            </div>
            <div class="form-group row">
                <label for="confirm" class="col-form-label col-sm-2">Confirm Password:</label>
                <div class="col-md-5">
                    <input pInputText type="password" id="confirm" formControlName="confirm" class="form-control" />
                </div>
                <div class="col-md-5">
                    <em *ngIf="validateControl('confirm') && hasError('confirm', 'required')">Confirmation is
                        required</em>
                    <em *ngIf="hasError('confirm', 'mustMatch')">Passwords must match</em>
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-1">
                    <p-button type="submit"
                        [disabled]="!resetPasswordForm.valid">Submit</p-button>
                </div>
            </div>
        </form>
    </div>
</div>