import { Component, OnInit, ViewChild } from '@angular/core';
import { ShareFilterCriteria } from '../models/share-filter-criteria';
import { ViewUpdateModel } from '../models/share-view-update-model';
import { ShareFilterFormComponent } from '../share-filter-form/share-filter-form.component';
import { ShareSearchResultsComponent } from '../share-search-results/share-search-results.component';
import { ShareViewUpdateComponent } from '../share-view-update/share-view-update.component';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  @ViewChild(ShareFilterFormComponent) searchFilterFormComponent: ShareFilterFormComponent;
  @ViewChild(ShareSearchResultsComponent) searchResultsComponent: ShareSearchResultsComponent;
  @ViewChild(ShareViewUpdateComponent) viewUpdateFormComponent: ShareViewUpdateComponent;

  constructor() { }

  ngOnInit(): void {
    
  }

  public setCriteria(criteria: ShareFilterCriteria) {
    this.searchResultsComponent.Criteria = criteria;
  }

  public clear() {
    this.searchResultsComponent.clearSearchResults();
  }

  public viewUpdate(update: ViewUpdateModel) {
    this.searchFilterFormComponent.hide();
    this.searchResultsComponent.hide();

    this.viewUpdateFormComponent.ViewUpdateModel = update;
    this.viewUpdateFormComponent.show();
  }

  public returnToSearchResults()
  {    
    this.viewUpdateFormComponent.hide();
    this.searchFilterFormComponent.show();
    this.searchResultsComponent.show();
    this.searchResultsComponent.search();
  }

}
