import { AccountService } from './../account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
    form: UntypedFormGroup;
    submitted = false;
    public message: string;

    // This is changed in the installer build pipeline
    public buildNumber = '7.0.0.0';

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        public modalService:ModalService)
    { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            username: ['', Validators.required],
        });
    }

    get fields() { return this.form.controls; }
    
    onSubmit(): void {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        this.accountService.forgotPassword(this.fields.username.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.message = "";
                },
                error => {
                    this.message = "An error occurred";
        });

        this.modalService.displayInfoModal("A message will shortly be sent to your primary email account with instructions on how to reset your password.");
    }
}
