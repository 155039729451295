<div id="main-navbar-collapse" class="top-navbar">
  <nav class="navbar navbar-expand-md flex-md-nowrap py-0 px-2">
    <div class="logo-wrapper d-flex align-items-center">
      <img src="assets/images/mdm-logo.JPG" alt="MDM" style="width: 40px; height: 40px; margin-right: 10px;" />
      <!-- <a class="multivue-logo" [routerLink]="'/dashboard'">MDM</a> -->
      <a class="multivue-logo" [routerLink]="'/dashboard'">
        <div class="logo-text-container">
          <img src="../../assets/images/civica logo.svg" class="logo"/>
          <!-- <div class="civica-text">Civica</div> -->
          <div class="mdm-text">MDM</div>

        </div>
      </a>
    </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"><i class="ti ti-menu"></i> </span>
    </button>
 
    
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <h3 class="mr-5">{{instanceName}}</h3>
      <p-dropdown *ngIf="accessToCustomers.length > 1" class="mr-5" [options]="accessToCustomers"  [(ngModel)]="contextCustomerId" optionLabel="name"
              optionValue="id" [ngModelOptions]="{standalone: true}" (onChange)="onChangeContextCustomer($event)"> </p-dropdown>
      
      <!-- Search Box -->
      <form class="custom-search-box d-flex" *ngIf="showSearch">
        <div class="input-group">
          <div class="p-inputwrapper d-flex">
            <p-dropdown class="domain-dd-width" [options]="entityTypes" placeholder="Domain Model" [(ngModel)]="selectedEntityType" optionLabel="name"
            optionValue="id" [ngModelOptions]="{standalone: true}" title="Select domain to search on" (onClick)="populateEntityTypes()"> </p-dropdown>
            <span class="p-input-icon-left" style="margin-left: 47px;">
              <i class="ti ti-search"></i>
              <input type="text" id="txt-header-search" pInputText class="p-inputtext" (keydown.enter)="onSearch($event)"/>
            </span>
          </div>
          <div class="input-group-append">
            <p-button label="Advanced Search" [routerLink]="'entity/search'" routerLinkActive="active"></p-button>
          </div>
        </div>
      </form>

      <ul class="navbar-nav ml-auto py-md-0 align-items-center">

        <li class="nav-item" *ngIf="showMyRecords">
          <a class="nav-link" [routerLink]="'entity/myrecords'" id="al_indexmr">My Records</a>
        </li>
        <li class="nav-item" *ngIf="showAddNewEntity">
          <a class="nav-link" (click)="navigateToAddEntity()" [routerLink]="'entity'" id="al_indexne"  authorize [pages]="getPageNamesForAuthorization()" [accessType] = "fullAccess">Add New Entity</a>
        </li>
        <li class="nav-item dropdown" *ngIf="showHistory">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
            aria-expanded="false" (click)="updateHistoryItems()"> History</a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button class="dropdown-item" ngbDropdownItem *ngFor="let item of historyItems;"
              (click)="showhistoryItem(item.entityType, item.entityId)">{{item.displayName}} </button>
            <a class="dropdown-item" *ngIf="historyItems?.length === 0">No records have been viewed during this
              session</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle profile-nav" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
            aria-expanded="false"><i class="ti ti-user"></i></a>
          <div class="dropdown-menu">
            <a class="dropdown-item"> {{userName}}</a>
            <a class="dropdown-item" *ngIf="simpleLogin" [routerLink]="'change-password'">Change Password</a>
            <a class="dropdown-item" *ngIf="simpleLogin" href="#" id="al_logoff" (click)="logOff()">Log Out</a>
            <div *ngIf="simpleLogin" class="dropdown-divider"></div>
            <label id="lblAvailability" class="dropdown-header no-wrap">Currently: {{userAvailability}}</label>
            <a class="dropdown-item" href="#" id="al_makeusernotavailable" (click)="updateUserAvailability()"> Set as
              {{targetAvailability}}</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>