<div class="card mt-4 mb-4" *ngIf="showSearchFilter">

    <div class="card-header">
        <h3 class="card-title">Filter Form</h3>
    </div>

    <form [formGroup]="filterForm" class="multivue-common-form">
        <div class="card-body">
            <div class="row">

                <div class="col-lg-2 col-md-4">
                    <div class="form-group no-margin-hr">
                        <label class="control-label" for="Domain">Domain</label>
                        <div class="select">
                            <option value=""></option>
                            <select class="form-control" formControlName="domain" id="Domain">
                                <option *ngFor="let domain of domains" [value]="domain">
                                    {{ domain }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4">
                    <div class="form-group no-margin-hr">
                        <label class="control-label" for="Consumer">Consumer</label>
                        <div class="select">
                            <select class="form-control" formControlName="consumer" id="Consumer">
                                <option *ngFor="let consumer of sources" [value]="consumer">
                                    {{ consumer }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4">
                    <div class="form-group no-margin-hr">
                        <label class="control-label" for="Source">Source</label>
                        <div class="select">
                            <select class="form-control" id="Source" formControlName="source">
                                <option *ngFor="let source of sources" [value]="source">
                                    {{ source }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4">
                    <div class="form-group no-margin-hr">
                        <label class="control-label" for="Status">Status</label>
                        <div class="select">
                            <select class="form-control" id="Status" formControlName="status">
                                <option *ngFor="let status of statuses.items" [value]="status.key">
                                    {{ status.value }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4">
                    <div class="form-group no-margin-hr">
                        <label class="control-label" for="Processed">Processed</label>
                        <div class="select">
                            <select class="form-control" id="Processed" formControlName="process">
                                <option *ngFor="let process of processes.items" [value]="process.key">
                                    {{ process.value }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4">
                    <div class="form-group no-margin-hr">
                        <label class="control-label" for="Users">Users</label>
                        <div class="select">
                            <select class="form-control" id="Users" formControlName="user">
                                <option *ngFor="let user of users" [value]="user">
                                    {{ user }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </form>

    <div class="card-footer text-right">
        <button type="button" class="btn btn-primary mr-3 m-1" id="ab_search" (click)="onSearch()">Search</button>
        <button type="button" class="btn btn-secondary mr-3 m-1" id="cancel-button" (click)="clearFilterForm()">Clear</button>
    </div>

</div>