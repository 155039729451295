import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FilterUpdatesModel } from './models/filter-updates-model'
import { ShareFilterCriteria } from './models/share-filter-criteria';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  private filterOptionsUrl = '/api/downstream/filterOptions';
  private searchUrl = '/api/downstream/search';
  private acceptUrl = '/api/downstream/accept';
  private rejectUrl = '/api/downstream/reject';
  private detailUrl = '/api/downstream/details';
  private nextUrl = '/api/downstream/next';
  private previousUrl = '/api/downstream/previous';

  constructor(private http: HttpClient) {
  }

  acceptUpdate(downstreamUpdateId: string) {
    return this.http.post(`${this.acceptUrl}?downstreamUpdateId=${downstreamUpdateId}`, null)
      .pipe(catchError(this.handleError));
  }

  rejectUpdate(downstreamUpdateId: string, rejectReason: number) {
    return this.http.post(`${this.rejectUrl}?downstreamUpdateId=${downstreamUpdateId}&rejectionReason=${rejectReason}`, null)
      .pipe(catchError(this.handleError));
  }

  // pageIndex and rowIndex are used to calculate an updateIndex 
  //  it's index in the search results represented by filterUpdates
  getUpdateDetail(pageIndex: number, rowIndex: number, criteria: ShareFilterCriteria) {
    const headers = { 'content-type': 'application/json' };
    let filterUpdates : FilterUpdatesModel = new FilterUpdatesModel(criteria);    
    const body = JSON.stringify(filterUpdates);

    // rowIndex is off by one (zero on UI, expected 1 in backend)
    return this.http.post(`${this.detailUrl}?page=${pageIndex}&index=${++rowIndex}`, body, { 'headers': headers })
      .pipe(catchError(this.handleError));
  }

  // updateIndex is the index of the update in the search results represented by filterUpdates
  //  it has been unfortunately been called page in the backend
  getNextUpdateDetail(updateIndex: number, criteria: ShareFilterCriteria) {
    const headers = { 'content-type': 'application/json' };
    let filterUpdates : FilterUpdatesModel = new FilterUpdatesModel(criteria);    
    const body = JSON.stringify(filterUpdates);

    return this.http.post(`${this.nextUrl}?page=${updateIndex}`, body, { 'headers': headers })
    .pipe(catchError(this.handleError));
  }

    // updateIndex is the index of the update in the search results represented by filterUpdates
  //  it has been unfortunately been called page in the backend
  getPreviousUpdateDetail(updateIndex: number, criteria: ShareFilterCriteria) {
    const headers = { 'content-type': 'application/json' };
    let filterUpdates : FilterUpdatesModel = new FilterUpdatesModel(criteria);    
    const body = JSON.stringify(filterUpdates);

    return this.http.post(`${this.previousUrl}?page=${updateIndex}`, body, { 'headers': headers })
      .pipe(catchError(this.handleError));
  }

  getSearchResults(filterUpdates: FilterUpdatesModel) {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(filterUpdates);

    return this.http.post(this.searchUrl, body, { 'headers': headers })
      .pipe(catchError(this.handleError));
  }

  getFilterOptions() {
    return this.http.get(this.filterOptionsUrl)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.log('Error Message: ' + error.message);
    return throwError(error);
  }

}
