import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionEnum } from '../../models/action.enum';

@Component({
  selector: 'app-match-reevaluation-modal',
  templateUrl: './match-reevaluation-modal.component.html'
})
export class MatchReevaluationModalComponent implements OnInit {

  @Input() 
  evaluationData: { matchThreshold: any; mergeThreshold: any; scoreAndDescription: ({ label: string; current: number; updated: any; } | { label: string; current: string; updated: any; })[]; };
  @Input() matchId: string;
  showMatchRevaluationPopup: boolean;
  actionEnumValues = ActionEnum;

  constructor(
    private activeModal: NgbActiveModal) {
    
  }
  ngOnInit(): void {
    this.showMatchRevaluationPopup = true;
  }

  acceptReEvaluation(action: ActionEnum) {
    this.activeModal.close({ accepted: true, data: this.matchId, action: action });
    this.closeModal();
  }

  closeModal() {
    this.activeModal.close({ accepted: false, data: 'Modal Closed'});
  }

  dismissModal() {
    this.activeModal.dismiss('Modal Dissmissed');
  }

}
