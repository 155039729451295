import { IDataSummary } from './../models/data-summary';
import { DashboardService } from './../dashboard.service';
import { IAppStatus } from './../models/app-status';
import { ISourceSummary } from './../models/source-summary';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-app-status',
  templateUrl: './app-status.component.html',
  styleUrls: ['./app-status.component.scss']
})
export class AppStatusComponent implements OnInit {

  public licenceExpiresInDays: number = 0;
  public gradientColours: string[] = [];
  private _dashboardService;
  statuses: IAppStatus[] = [];
  sourceSummary: ISourceSummary[] = [];
  public domainModels: IDataSummary[] = [];
  errorMessage = '';
  subscription: Subscription;
  groupedSummary: any[] = [];
  constructor(dashboardService: DashboardService,  private cdRef:ChangeDetectorRef) {
    this._dashboardService = dashboardService;
  }
 
  expandedRows: { [key: string]: boolean } = {};
  ngOnInit() : void {

    // this._dashboardService.getLicenseExpiryDays().subscribe({
    //   next: expiryDays => {
    //       this.licenceExpiresInDays = expiryDays;
    //       let licenseMsg = "Licence will expire in " + this.licenceExpiresInDays + " day(s)";
    //       if (this.licenceExpiresInDays > 30 && this.licenceExpiresInDays <= 90) {
    //         this.toastr.warning(licenseMsg);
    //       }
    //       else if (this.licenceExpiresInDays > 0 && this.licenceExpiresInDays <= 30) {
    //         this.modalService.displayErrorModal((licenseMsg);
    //       }
    //       else if (this.licenceExpiresInDays <= 0) {
    //         this.modalService.displayErrorModal(("Licence for MultiVue has expired");
    //       }          
    //   },
    //   error: err => this.errorMessage = err
    // });

    // this.refreshAppStatus();
    this.refreshSourceSummary();
    this.refreshDomainModels();
    this.gradientColours = ['blue-gradient', 'green-gradient', 'red-gradient', 'ocean-gradient', 
                            'cyan-gradient', 'silver-gradient', 'gold-gradient', 'gray-gradient', 
                            'green-gradient', 'ocean-gradient', 'red-gradient', 'green-gradient'];
  }

ngAfterContentInit()
{
  this.cdRef.detectChanges();
}

  refreshAppStatus() : void {
    this._dashboardService.getAppStatus().subscribe({
      next: statuses => {
        this.statuses = statuses;
      },
      error: err => this.errorMessage = err
    });    
  }

  refreshDomainModels(): void {
    this._dashboardService.getDomainModels().subscribe({
      next: domainModels => {
        this.domainModels = domainModels;
      },
      error: err => this.errorMessage = err
    })
  }

  refreshSourceSummary() : void {
    this._dashboardService.getSourceSummary().subscribe({
      next: sourceSummary => {
        console.log(sourceSummary);
        this.groupSourceSummary(sourceSummary)
      },
      error: err => this.errorMessage = err
    });
  }
  groupSourceSummary(sourceSummary:any) {
    const grouped = sourceSummary.reduce((acc, curr) => {
      const domain = acc.find(d => d.domainName === curr.domainName);
      if (domain) {
        domain.sources.push(curr);
        domain.totalToday += curr.countToday;
        domain.total7Days += curr.count7Days;
        domain.total30Days += curr.count30Days;
      } else {
        acc.push({
          domainName: curr.domainName,
          sources: [curr],
          totalToday: curr.countToday,
          total7Days: curr.count7Days,
          total30Days: curr.count30Days
        });
      }
      return acc;
    }, []);

    this.groupedSummary = grouped;
   }

}
