import { AccountService } from './../account/account.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private accountService: AccountService,
        private router: Router, ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            // if the error was too many requests then redirect to an appropriate error page
            //  do not allow the error to propagate
            if ([429].includes(err.status)) {
                this.router.navigate(['./too-many-requests']);
            }
           else if ([401].includes(err.status)&& (this.router.url.indexOf("/login")<-1)){
                this.accountService.autoLogout();
            } 
            else
            {
                const error = err.error?.message || err.statusText;
                // console.error(err);
                return throwError(error);
            }
        }));
    }
}