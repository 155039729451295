import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ISearchEntitiesCriteria } from 'src/app/shared/models/Search/search-entities-criteria';

@Injectable({
  providedIn: 'root'
})
export class EnrichmentService {
  appConfigUrl = '/api/Configuration';
  stewardshipUrl = '/api/EnrichStewardship';
  workflowUrl = '/api/Workflow';
  searchUrl = '/api/search';
  constructor(private http: HttpClient) {
  }

  // Send message from popup while closing to refresh parent page grid
  private messageSubject = new BehaviorSubject(false);
  currentMessage = this.messageSubject.asObservable();

  public getEnrichmentServices(): Observable<any> {
   
    return this.http
      .get(this.appConfigUrl + '/EnrichProviders')
      .pipe(catchError(this.handleError));
  }

  public getEnrichConfiguration(providerId: any): Observable<any> {
    return this.http
      .get(this.appConfigUrl + '/EnrichConfiguration?providerId=' + providerId)
      .pipe(catchError(this.handleError));
  }
  public getProviderOptions(providerId: any): Observable<any> {
    return this.http
      .get(this.appConfigUrl + '/EnrichProviderOptions?providerId=' + providerId)
      .pipe(catchError(this.handleError));
  }

  public getProviderSearchFields(providerId: any): Observable<any> {
    return this.http
      .get(this.appConfigUrl + '/EnrichProviderFields?providerId=' + providerId)
      .pipe(catchError(this.handleError));
  }

  postSearchRequest(searchEntitiesCriteria: ISearchEntitiesCriteria) {
    const body = JSON.stringify(searchEntitiesCriteria);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http
      .post<any>(this.stewardshipUrl + '/searchenrichments', body, {
        headers: headers,
      })
      .pipe(catchError(this.handleError));
  }

  postUpdateRequest(updateCriteria: any) {
    const body = JSON.stringify(updateCriteria);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http
      .post<any>(this.stewardshipUrl + '/UpdateEnrichments', body, {
        headers: headers,
      })
      .pipe(catchError(this.handleError));
  }  
  postEnrichmentConfiguration(enrichProviderSettings: any): Observable<any> {
    let body = enrichProviderSettings;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<any>(
        this.appConfigUrl + '/EnrichConfiguration',
        body,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }
  // getEnrichWorkflowTasks(user: any,domain:any,enrichStatus:any, page: any, pageSize: any, sortOrder: any, sortColumn: any): Observable<any> {
  //   return this.http
  //     .get(this.stewardshipUrl + '/GetWorkflowTasks?username=' + user + '&domain=' +
  //     domain +
  //       '&status=' +
  //     enrichStatus +
  //       '&page=' +
  //       page +
  //       '&pageSize=' +
  //       pageSize +
  //       '&sortOrder=' +
  //       sortOrder +
  //       '&sortColumn=' +
  //       sortColumn)
  //     .pipe(catchError(this.handleError));
  //   // return this.http.get("assets/enrichmentDetailsForRecord1.json");
  // }
  getEnrichWorkflowTasks(gridParameters): any {
    let body = gridParameters;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

  return this.http.post<any>(this.stewardshipUrl + '/GetWorkflowTasks', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  
}
  postStatusUpdate(statusUpdates: any): Observable<any> {
    let body = statusUpdates;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<any>(
        this.stewardshipUrl + '/UpdateStatus',
        body,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }
  getStatusHistory(id: any): Observable<any> {
    return this.http
      .get(this.stewardshipUrl + '/GetStatusHistory?id=' + id)
      .pipe(catchError(this.handleError));
  }
  
  editStewardshipEntity(entityId:string,entityType:string,sourceSystem:string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .get<any>(
        this.stewardshipUrl +
        '/EditEntity?entityId=' +
        entityId +
        '&entityType=' +
        entityType +
        '&sourceSystem=' +
        sourceSystem,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }
 
  acceptEnrichment(enrichRequest: any,isReEnrich:boolean) {
    let body = enrichRequest;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<any>(
        this.stewardshipUrl + '/AcceptEnrichment?isReEnrich=' + isReEnrich,
        body,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }
  acceptEnrichments(Ids: string[], username: string,domain:string,status:string): Observable<any> {
    let headers = new HttpHeaders();
    let body = Ids;
    headers = headers.set('Content-Type', 'application/json');
    return this.http
      .post<any>(this.stewardshipUrl + '/AcceptEnrichments?username=' + username + '&domain=' +
        domain +
        '&status=' +
      status,
        body,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }
  rejectEnrichments(Ids: string[], username: string,domain: string, status:string,isReenrich:boolean): Observable<any> {
    let body = Ids;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<any>(this.stewardshipUrl +
      '/RejectEnrichments?username=' + username + '&domain=' +
      domain +
      '&status=' +
      status + '&isReEnrich=' + isReenrich,
        body,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }
  getActiveUsers(): any {
    return this.http.get(this.stewardshipUrl + '/ActiveUsers');
  }

  refreshGrid(isRefreshGrid: true) {
    this.messageSubject.next(isRefreshGrid);
  }

  update(entitySaveRequest: any): Observable<any> {
    const body = entitySaveRequest;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/multivue+json');

    return this.http
      .post<any>(this.stewardshipUrl + "/UpdateStewardshipEntity"
        ,body, {
        headers: headers
      })
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.log('Error Message :' + error.message);
    return throwError(error);
  }
}
