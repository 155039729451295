import { Directive, ElementRef, HostListener, Input, OnInit, Optional, Self } from "@angular/core";
import { Checkbox } from "primeng/checkbox";
import { Dropdown } from "primeng/dropdown";
import { Button } from "primeng/button";
import { NgControl } from "@angular/forms";
import { Slider } from "primeng/slider";
import { PermissionFunctions } from "../models/permission-functions";
import { Role } from "src/app/security-configuration/role-administration/models/roles";
import { FileUpload } from "primeng/fileupload";
import { SplitButton } from "primeng/splitbutton";

@Directive({
    selector: '[authorize]'
})
export class AuthorizeDirective implements OnInit {
    @Input() pages: PermissionFunctions[];
    @Input() accessType: string = 'read-only';
    @Input() forceDisabled: boolean = false;

    constructor(
        @Optional() @Self() private control: NgControl,
        @Optional() @Self() private checkBox: Checkbox,
        @Optional() @Self() private dropDown: Dropdown,
        @Optional() @Self() private button: Button,
        @Optional() @Self() private slider: Slider,
        @Optional() @Self() private fileUpload: FileUpload,
        @Optional() @Self() private splitButton: SplitButton,
        private elementRef: ElementRef

    ) { }

    ngOnInit(): void {
        this.setDisabled();
    }

    get hasAccess(): boolean {
        const rolePermissions: Role[] = JSON.parse(localStorage.getItem('rolePermissions'));
        for (const page of this.pages) {
            for (const rolePermission of rolePermissions) {
                const uiPermission = rolePermission.uiPermissions.find(uiPerm => uiPerm.page.toString() === page.toString());
                if (uiPermission) {
                    if (this.accessType === 'full' && uiPermission.isFullAccess) {
                        return true;
                    }
                    if (this.accessType === 'read-only' && (uiPermission.isReadOnlyAccess || uiPermission.isFullAccess)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    @HostListener('permissionCheck')
        onDirectiveCheck() {
        this.setDisabled();
    }

    setDisabled() {
        
        if (!this.hasAccess || this.forceDisabled) {
            this.disableElement()
        }
        else this.enableElement();
    }

    disableElement(): void {
        if (this.control && this.control.control)
            this.control.control.disable()
        else if (this.button)
            this.button.disabled = true
        else if (this.checkBox)
            this.checkBox.disabled = true;
        else if (this.dropDown)
            this.dropDown.disabled = true;
        else if (this.slider)
            this.slider.disabled = true;
        else if (this.fileUpload)
            this.fileUpload.disabled = true;
        else if (this.splitButton)
            this.splitButton.disabled = true;
        else if (this.elementRef.nativeElement instanceof HTMLButtonElement) {
                // If it's a simple HTML button
            this.elementRef.nativeElement.disabled = true;
        }
        else if (this.elementRef.nativeElement instanceof HTMLInputElement) {
            this.elementRef.nativeElement.disabled = true;
        }
        else if (this.elementRef.nativeElement instanceof HTMLAnchorElement) {
                // If it's a simple HTML anchor tag
            this.elementRef.nativeElement.removeAttribute('href');
            this.elementRef.nativeElement.classList.add('disabled');
        }
    
    }

    enableElement(): void {
        if (this.control && this.control.control)
            this.control.control.enable()
        else if (this.button)
            this.button.disabled = false;
        else if (this.checkBox)
            this.checkBox.disabled = false;
        else if (this.dropDown)
            this.dropDown.disabled = false;
        else if (this.slider)
            this.slider.disabled = false;
        else if (this.fileUpload)
            this.fileUpload.disabled = false;
        else if (this.splitButton)
            this.splitButton.disabled = false;
    }
}