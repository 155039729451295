import { FilterUpdatesModel } from "./filter-updates-model";

export class DownstreamUpdateDetailsModel extends FilterUpdatesModel {
    id: string;
    entityType: string;
    hasMoreRecords: boolean;
    hasPreviousRecords: boolean;
    newValue: any;
    oldValue: any;
    consumerUniqueId: string;
    dateCreated: Date;
    lastUpdated: Date;
    fieldName: string;
    processedStatus: number;
}