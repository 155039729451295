import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LeftPaneModel } from '../../models/left-pane-model';

@Component({
  selector: 'app-left-pane',
  templateUrl: './left-pane.component.html',
  styleUrls: ['./left-pane.component.scss']
})
export class LeftPaneComponent implements OnInit {

  @Input() itemsList : LeftPaneModel[];
  @Input() listName : string;
  @Output() itemSelectionEvent: EventEmitter<{ item: any, i: number }> = new EventEmitter<{ item: any, i: number }>();
  searchText: string = '';
  filteredItemsList: LeftPaneModel[];
  selectedItem: LeftPaneModel;
  constructor() { }

  ngOnInit(): void {
    this.filteredItemsList = this.itemsList;
  }

  onItemSelection(item,i) {
    //this.selectedItem = item;
    this.itemSelectionEvent.emit({item, i});
  }

  applySearchFilter() {
    this.filteredItemsList = this.itemsList.filter(item =>
      item.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  setActiveItem(item: any) {
    this.selectedItem = item;
  }

}
