import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[preventCopyPaste]'
})
export class PreventCopyPasteDirective {
  constructor() { }

  @HostListener('paste', ['$event']) blockPaste(e: ClipboardEvent) {
    e.preventDefault();
  }
}