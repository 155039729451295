import { ServiceName } from './../../shared/models/service-name';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit} from '@angular/core';
import { Audit } from '../models/audit';

@Component({
  selector: 'app-audit-view-detail',
  templateUrl: './audit-view-detail.component.html',
  styleUrls: ['./audit-view-detail.component.scss']
})
export class AuditViewDetailComponent implements OnInit {

 

  public get serviceEnum(): typeof ServiceName {
    return ServiceName;
  }
  
  language: string = navigator.language;

  constructor(public modal: NgbActiveModal) {
  }
  audit: Audit;
  showAuditPopup: boolean;
  tags: Array<string> = [];

  ngOnInit(): void {
    for (const key in this.audit.tags) {
      this.tags.push(key + ': ' + this.audit.tags[key]);
    }
  }

  public show(): void {
    this.showAuditPopup = true;
  }

  public hide(): void {
    this.showAuditPopup = false;
  }

}
