export enum PermissionFunctions {
    Audit,
    Configuration,
    Customers,
    DomainModels,
    EnrichConfiguration,
    EnrichStewardship,
    Entities,
    Inbox,
    BulkJobs,
    MatchAnalysis,
    Matches,
    RelationshipMatchAnalysis,
    RelationshipMatches,
    RelationshipModels,
    Roles,
    ScoringCombinations,
    Search,
    Share,
    Sources,
    Users,
    WorkflowRules
}