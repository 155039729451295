import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContextSwitchingService {
  private contextChangedSource = new Subject<void>();

  contextChanged$ = this.contextChangedSource.asObservable();

  triggerContextChange(): void {
    this.contextChangedSource.next();
  }
}