import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterUpdatesModel } from '../models/filter-updates-model';
import { ShareFilterCriteria } from '../models/share-filter-criteria';
import { ShareService } from '../share.service';
import { FilterUpdatesResultModel } from '../models/filter-updates-result-model';
import { DownstreamUpdateListItemModel } from '../models/filter-updates-result-model';
import { ViewUpdateModel } from '../models/share-view-update-model';
import { DateValidatorService } from 'src/app/shared/custom-validators/date-validator.service';

@Component({
  selector: 'app-share-search-results',
  templateUrl: './share-search-results.component.html',
  styleUrls: ['./share-search-results.component.scss']
})
export class ShareSearchResultsComponent implements OnInit {

  @Output() viewed = new EventEmitter<ViewUpdateModel>();

  private _criteria: ShareFilterCriteria;

  @Input()
  get Criteria(): ShareFilterCriteria { return this._criteria; }
  set Criteria(criteria: ShareFilterCriteria) {
    this._criteria = criteria;
    this.search();
  }

  downstreamUpdates: DownstreamUpdateListItemModel[];
  showResultsGrid = false;

  public resultColumns = {
    columns: [
      { columnHeader: 'Consumer' },
      { columnHeader: 'Source' },
      { columnHeader: 'Field Name' },
      { columnHeader: 'Old Value' },
      { columnHeader: 'New Value' }
    ]
  };

  public rejectionReasons = {
    reasons: [
      { key: 0, value: 'Verified By Subject' },
      { key: 1, value: 'Incorrect Details' }
    ]
  };

  totalRecordsCount = 0;
  pageSize = 10;
  currentPage = 1;

  showRejectDropdownMenu: boolean;
  language: string = navigator.language;

  constructor(private shareService: ShareService,

    // this is not unused, it is used in html template
    private dateValidatorService: DateValidatorService) { }

  ngOnInit(): void {
  }

  onPageChanged(page: number): void {
    this.currentPage = page;
    this.search();
  }

  public search() {
    // use the criteria to perform the search
    //  the criteria is provided by the share-filter-form component 
    if (this._criteria) {
      let filterUpdates: FilterUpdatesModel = new FilterUpdatesModel(this._criteria);

      filterUpdates.page = this.currentPage;
      filterUpdates.pageSize = this.pageSize;

      this.shareService.getSearchResults(filterUpdates)
        .subscribe((data: FilterUpdatesResultModel) => {

          this.downstreamUpdates = data.downstreamUpdates;

          this.totalRecordsCount = data.totalItemCount;
          this.currentPage = data.page;
          this.pageSize = data.pageSize;

          if (this.totalRecordsCount === 0) {
            this.showResultsGrid = false;
          }
          else {
            this.showResultsGrid = true;
          }

        });

    }
  }

  clearSearchResults() {
    this.showResultsGrid = false;
    this.downstreamUpdates = [];
    this.totalRecordsCount = 0;
  }

  onViewRow(rowIndex: number) {
    let model: ViewUpdateModel = new ViewUpdateModel();
    model.criteria = this._criteria;
    model.rowIndex = rowIndex;
    model.pageIndex = this.currentPage;

    this.viewed.emit(model);
  }

  hasAcceptButton(update: DownstreamUpdateListItemModel) {
    // if status is either pending (0) or rejected (2) 
    if (update.status === 0 || update.status === 2) {
      // if process status is either not-processed (0) or process-failure (2) 
      if (update.processedStatus == 0 || update.processedStatus == 2) {
        return true;
      }
    }
    return false;
  }

  onAcceptRow(update: DownstreamUpdateListItemModel) {
    // update the search results once and update has been accepted
    //  performing the search will allow the grid to remain fully populated
    this.shareService.acceptUpdate(update.id).subscribe(() => {
      this.search();
    });
  }

  hasRejectButton(update: DownstreamUpdateListItemModel) {
    // if status is either pending (0) or accepted (1) 
    if (update.status === 0 || update.status === 1) {
      // if process status is either not-processed (0) or process-failure (2) 
      if (update.processedStatus == 0 || update.processedStatus == 2) {
        return true;
      }
    }
    return false;
  }

  onRejectRow(update: DownstreamUpdateListItemModel, rejectReason: number) {
    // update the search results once and update has been rejected
    //  performing the search will allow the grid to remain fully populated
    this.shareService.rejectUpdate(update.id, rejectReason).subscribe(() => {
      this.search();
    });
  }

  public show() {
    this.showResultsGrid = true;
  }

  public hide() {
    this.showResultsGrid = false;
  }

}
