import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { DifferenceTypeEnum } from "../../models/difference-type.enum";
import { MatchDetailsModel } from "../../models/match-details-model";
import { DateValidatorService } from "../../custom-validators/date-validator.service";

@Component({
    selector: 'app-match-comparison',
    templateUrl: './match-comparison.component.html'
  })
export class MatchComparisonComponent implements OnInit, OnChanges {
    
    @Input() matchDetailsResponse: MatchDetailsModel = new MatchDetailsModel();
    @Input() matchDetailsArray: any[] = [];
    @Input() showMatchTypes: boolean = false;
    @Input() scoringDetails: any;
    differenceTypeEnum = DifferenceTypeEnum;
    
    constructor(
        // this is not unused, it is used in html template
        private dateValidatorService: DateValidatorService) { }

    ngOnInit(): void {
        
    }

    ngOnChanges(): void {

        // Scoring details object is sent on my records match comparison screen
        if (this.scoringDetails)
        {
            this.matchDetailsResponse.score = this.scoringDetails.totalScore;
            this.matchDetailsResponse.matchRule = this.scoringDetails.matchRuleName;
            this.matchDetailsResponse.scoringCombinationDescription = this.scoringDetails.description;
        }
    }

    
}