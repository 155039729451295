import { Component, Inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html'
})
export class InfoModalComponent {
  infoMessage: string;

  constructor(private ref: DynamicDialogRef, @Inject(DynamicDialogConfig) private config: DynamicDialogConfig) {
    this.infoMessage = this.config.data.message;
  }

  close() {
    this.ref.close();
  }
}
