import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';

// Throttle limits (mapped from API limits)
const limitPerSecond = 10;
const limitPerMinute = 60;
const limitPerHour = 3600; 
const limitPerDay = 86400;

// Time constants
const msOneSecond = 1000;
const msOneMinute = 60000;
const msOneHour = 3600000;
const msOneDay = 86400000;

/** Pass untouched request through to the next request handler. */
@Injectable()
export class RateInterceptor implements HttpInterceptor {

  // Current values
  private activeSecondCount = 0;
  private activeMinuteCount = 0;
  private activeHourCount = 0;
  private activeDayCount = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }

  private canMakeApiCall(): boolean {

    if (this.activeSecondCount >= limitPerSecond) {
      return false;
    }
    else {
      this.activeSecondCount++;
      setTimeout(() => { this.activeSecondCount--; }, msOneSecond);      
    }

    if (this.activeMinuteCount >= limitPerMinute) {
      return false;
    }
    else {
      this.activeMinuteCount++;
      setTimeout(() => { this.activeMinuteCount--; }, msOneMinute);    
    }

    if (this.activeHourCount >= limitPerHour) {
      return false;
    }
    else {
      this.activeHourCount++;
      setTimeout(() => { this.activeHourCount--; }, msOneHour);    
    }

    if (this.activeDayCount >= limitPerDay) {
      return false;
    }
    else {
      this.activeDayCount++;
      setTimeout(() => { this.activeDayCount--; }, msOneDay);    
    }

    // Ok to call
    return true;
  }  

  async handle(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<unknown>> {
    if (this.canMakeApiCall()) {
      return next.handle(request).toPromise();
    }
    else {
      await this.sleep((Math.random() * 500) + 500);
      return this.handle(request, next);
    }
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }      
}