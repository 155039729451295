import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MyRecordsService {

  baseUrl = '/api/myrecords';

  constructor(private http: HttpClient) { }

  getEntityTypes(): any {
    let headers = new HttpHeaders();
    headers = headers.set('no-spinner', '');
    return this.http.get(this.baseUrl + '/entity-types', { headers: headers});
  }

  getMyRecordsFilterResult(myRecordsRequest) {

    const body = JSON.stringify(myRecordsRequest);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.baseUrl + '/search', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

  delete(virtualId: string, entityType: string) {
    return this.http.delete<any>(this.baseUrl + '?virtualId=' + virtualId + '&entityType=' + entityType).pipe(catchError(this.handleError));
  }

  merge(RecordsToMerge: any) {
    const body = RecordsToMerge;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.baseUrl + '/Merge', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

  compare(RecordsToCompare: any) {
    const body = RecordsToCompare;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.baseUrl + '/Compare', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }
  scoringDetails(scoreModel: any) {
    const body = scoreModel;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post<any>(this.baseUrl + '/Score', body, {
      headers: headers,
    }).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.log('Error Message :' + error.message);
    return throwError(error);
  }


}


