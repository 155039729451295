<app-content-header></app-content-header>
<section class="content">
  <div class="container-fluid">
  <div class="row">
    <div class="col-12 p-0 content-height-12rem">
      <div class="card mt-3">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h3 class="card-title mb-0">Domain Model Summary- {{domainModels.length}} domain models</h3>
          </div>
          <div class="card-body">
      <p-table [value]="domainModels" class="p-datatable-gridlines">
        <ng-template pTemplate="header">
          <tr>
            <th>Domain Model</th>
            <th>Entity Count</th>
            <th>Matches Count</th>
            <th>Virtuals Count</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-domainModel>
          <tr>
            <td>{{domainModel.domainName}} Domain</td>
            <td>{{domainModel.entityCount}}</td>
            <td>{{domainModel.matchCount}}</td>
            <td>{{domainModel.distinctCount}}</td>
          </tr>
        </ng-template>
      </p-table>
        </div>
        </div>
      <div class="service-grid card mt-3" style="display:none">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h3 class="card-title">Service Status</h3>
          <button type="button" id="serviceStateRefresh" class="btn btn-info btn-sm"
            (click)="refreshAppStatus()">Refresh</button>
        </div>
        <p-table [value]="statuses" *ngIf="statuses && statuses.length" class="p-datatable-gridlines">
          <ng-template pTemplate="header">
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Description</th>
              <th>UpTime</th>
              <th>License Status</th>
            </tr>
          </ng-template>
          
          <ng-template pTemplate="body" let-status>
            <tr >
              <td>{{ status.serviceName }}</td>
              <td>
                <span *ngIf="status.serviceStatusText === 'Running'" class="badge badge-success">{{ status.serviceStatusText }}</span>
                <span *ngIf="status.serviceStatusText !== 'Running'" class="badge badge-danger">{{ status.serviceStatusText }}</span>
              </td>
              <td>{{ status.description }}</td>
              <td>{{ status.upTime }}</td>
              <td>{{ status.licenseStatus }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
 
      <div class="card mt-3">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h3 class="card-title mb-0">Source System Summary</h3>
          <button type="button" id="sourceSystemRefresh" class="btn btn-info btn-sm" (click)="refreshSourceSummary()">Refresh</button>
        </div>
      
        <div class="card-body">
          <p-table
            [value]="groupedSummary"
            dataKey="domainName"
            rowExpandMode="multiple"
            [tableStyle]="{'min-width': '60rem'}"
            [(expandedRowKeys)]="expandedRows"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 50px;"></th> <!-- Column for expand/collapse icon -->
                <th>Domain</th>
                <th>Sources</th>
                <th>Records Processed Today</th>
                <th>Records Processed Last Seven Days</th>
                <th>Records Processed Last Thirty Days</th>
              </tr>
            </ng-template>
      
            <!-- Parent Row -->
            <ng-template pTemplate="body" let-domain let-expanded="expanded">
              <tr>
                <td>
              
                    <button type="button" pButton pRipple [pRowToggler]="domain" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
               
                </td>
                <td>{{ domain.domainName }}</td>
                <td>{{ domain.sources.length }}</td>
                <td>{{ domain.totalToday }}</td>
                <td>{{ domain.total7Days }}</td>
                <td>{{ domain.total30Days }}</td>
              </tr>
            </ng-template>
      
            <!-- Expanded Rows (Child Rows) -->
            <ng-template pTemplate="rowexpansion" let-domain>
              <tr *ngFor="let source of domain.sources">
                <td></td> <!-- Empty cell for indentation -->
                <td></td>
                <td>{{ source.sourceName }}</td>
                <td>{{ source.countToday }}</td>
                <td>{{ source.count7Days }}</td>
                <td>{{ source.count30Days }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      
    </div>
  </div>
</div>
</section>