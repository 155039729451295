<div class="modal" id="addRelationshipModalPopup" [ngStyle]="{
  display: showAddRelationshipPopup === true ? 'block' : 'none'
}">
 >
  
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
 
        <div class="modal-content">
            <div class="modal-body">
<ng-container>
    <section class="content">
      <div class="row mt-4"> 
        <div class="col-12">
          <div class="row">
          
            <div class="col-md-12">
              <form [formGroup]="addRelationshipTypeForm"
              id="requestReviewForm">
              <li class="list-group-item">
                <ul class="list-group">
                  <div class="card-header">
                    <span>Relationship Metadata</span>
                  </div>
           
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-md-3">
                        Entity 1 Relationship Type
                      </div>

                      <div class="col-md-9">
                        <input type="text" pInputText  formControlName="entity1RelationshipType"
                          />
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-md-3">
                        Entity 2 Relationship Type
                      </div>

                      <div class="col-md-9">
                        <input type="text" pInputText 
                        formControlName="entity2RelationshipType" />
                      </div>
                    </div>
                  </li>
              
                </ul>
              </li>
              </form>
              <li class="list-group-item">
                <ul class="list-group">
                  <div class="card-header">
                    <span>Relationship Attributes</span>
                  </div>
                  <ul class="list-group">
                    <ng-container *ngFor="
                        let domainModelField of domainModelFields
                      ">
                      <ng-container *ngIf="
                          domainModelField.isSingleValue &&
                          domainModelField.isMultiple &&
                          (domainModelField.level == 1 ||
                            domainModelField.level ==
                              undefined)
                        ">
                        <ng-template [ngTemplateOutlet]="
                            singlevaluemultipletype
                          " [ngTemplateOutletContext]="{
                            $implicit: domainModelField
                          }">
                        </ng-template>
                      </ng-container>

                      <ng-container *ngIf="
                          domainModelField.isSingleValue &&
                          !domainModelField.isMultiple
                        ">
                        <ng-template [ngTemplateOutlet]="
                            simpleliItemsEdit
                          " [ngTemplateOutletContext]="{
                            $implicit: domainModelField
                          }">
                        </ng-template>
                      </ng-container>

                      <ng-container *ngIf="
                          domainModelField.isMultiple &&
                          domainModelField.isMultiValue &&
                          !domainModelField.isSingleValue
                        ">
                        <ng-template [ngTemplateOutlet]="
                            multivaluemultipletype
                          " [ngTemplateOutletContext]="{
                            $implicit: domainModelField,
                            b: domainModelField,
                            d: domainModelFields
                          }">
                        </ng-template>
                      </ng-container>

                      <ng-container *ngIf="
                          domainModelField.isMultiValue &&
                          !domainModelField.isMultiple &&
                          !domainModelField.isSingleValue
                        ">
                        <ng-template [ngTemplateOutlet]="multivalue" [ngTemplateOutletContext]="{
                            $implicit: domainModelField,
                            d: domainModelFields
                          }">
                        </ng-template>
                      </ng-container>
                    </ng-container>
                  </ul> 
            
                </ul>
              </li>
            </div>

            <ng-template #simpleliItemsEdit let-simpleli>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-md-3">
                    {{ simpleli.fieldName }}
                    <span *ngIf="
                        simpleli.isClassificationAttribute ==
                        true
                      " class="label-asterisk">*</span>
                  </div>
                  <ng-container>
                    <div class="col-md-9" *ngIf="
                        simpleli.isString ||
                        simpleli.isInteger ||
                        simpleli.isDecimal ||
                        simpleli.isUrl
                      ">
                      <input type="text" pInputText  [(ngModel)]="simpleli.value"
                        #ctrlName="ngModel" [attr.data-controltype]="
                          simpleli.fieldType
                        " id="{{ simpleli.fieldName }}" placeholder="{{ simpleli.fieldName }}" />
                    </div>

                    <div class="col-md-9" *ngIf="
                        simpleli.isDateTime || simpleli.isDate
                      ">

                      <ng-container
                        *ngIf="simpleli.value != null && simpleli.value != '' && simpleli.value != '0001-01-01T00:00:00Z' ">

                        <input type="text" pInputText bsDatepicker id="{{ simpleli.fieldName }}"
                          [(ngModel)]="simpleli.value" [attr.data-controltype]="simpleli.fieldType"
                          placeholder="Select date"  autocomplete="off"
                          [bsConfig]="{showWeekNumbers: false, containerClass: 'theme-default', selectFromOtherMonth: true}">
                      </ng-container>
                      <ng-container
                        *ngIf="simpleli.value == null || simpleli.value == '' || simpleli.value == '0001-01-01T00:00:00Z'">

                        <input type="text" pInputText bsDatepicker id="{{ simpleli.fieldName }}"
                          [(ngModel)]="simpleli.value" [attr.data-controltype]="simpleli.fieldType"
                          placeholder="Select date"  autocomplete="off"
                          [bsConfig]="{showWeekNumbers: false, containerClass: 'theme-default', selectFromOtherMonth: true}">
                      </ng-container>
                    </div>

                    <div class="col-md-9" *ngIf="
                        simpleli.isReference === true &&
                        simpleli.referenceList != null &&
                        !simpleli.referenceList
                          .displayAsTypeahead
                      ">
                      <div class="select">
                        <p-dropdown [options]="simpleli.referenceList.values" [(ngModel)]="simpleli.value"
                        optionLabel="name" optionValue="value"
                        ></p-dropdown>
                    </div>
                      </div>
                    
                    <div class="col-md-9" *ngIf="
                        simpleli.isReference === true &&
                        simpleli.referenceList != null &&
                        simpleli.referenceList
                          .displayAsTypeahead
                      ">
                      <ng-autocomplete [data]="referenceValues" [searchKeyword]="keyword"
                        (inputChanged)="
                          getReferenceValues(
                            $event,
                            simpleli.fieldName
                          )
                        " (selected)="
                          onAutoCompleteSelectedValue(
                            $event,
                            simpleli.fieldName
                          )
                        " [itemTemplate]="itemTemplate" [minQueryLength]="3"
                        placeholder="Please type to view options"
                        [notFoundTemplate]="notFoundTemplate" id="{{ simpleli.fieldName }}"
                        [attr.data-controltype]="
                          simpleli.fieldType
                        " [(ngModel)]="simpleli.value" #ctrlName="ngModel">
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.display"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  </ng-container>
                </div>
              </li>
            </ng-template>

            <ng-template #singlevaluemultipletype let-complexli>
              <li class="list-group-item">
                <ng-container *ngIf="
                    complexli.value &&
                    complexli.value.length > 0
                  ">
                  <ul class="list-group">
                    <div class="card-header">
                      <span>{{ complexli.fieldName }}</span>
                    </div>
                    <ng-container *ngTemplateOutlet="
                        displayCardsEdit;
                        context: { $implicit: complexli }
                      ">
                    </ng-container>
                  </ul>
                </ng-container>
              </li>
            </ng-template>

            <ng-template #multivalue let-complexli let-json2="b" let-domainmodelfields="d">
              <li class="list-group-item">
                <ng-container>
                  <ul class="list-group">
                    <div class="card-header">
                      <span>{{ complexli.fieldName }}</span>
                      <span *ngIf="
                          complexli.isClassificationAttribute ==
                          true
                        " class="label-asterisk">*</span>
                      <div class="dropdown float-right">
                        <div class="dropdown float-right">
                          <button class="btn btn-info btn-sm" type="button" id="add" (click)="
                                  addRemoveMultipleAttribute(
                                    complexli,
                                    'add',
                                    json2,
                                    complexli.fields
                                  )
                                " aria-haspopup="true" aria-expanded="false">
                            Add {{ complexli.fieldName }}
                          </button>
                        </div>

                      </div>
                    </div>
                    <ng-container *ngTemplateOutlet="
                        displayCardsMultivalue;
                        context: {
                          $implicit: complexli,
                          c: json2
                        }
                      ">
                    </ng-container>
                  </ul>
                </ng-container>
              </li>
            </ng-template>

            <ng-template #multivaluemultipletype let-complexli let-json2="b"
              let-domainmodelfields="d">
              <li class="list-group-item">
                <ng-container>
                  <ul class="list-group">
                    <div class="card-header">
                      <span class="card-title">{{
                        complexli.fieldName | titlecase
                        }}</span>

                      <ng-container *ngFor="
                          let domain of domainmodelfields[
                            domainmodelfields.length - 1
                          ]
                        ">
                        <ng-container *ngIf="
                            domain.fieldName ==
                            complexli.fieldName
                          ">
                          <div class="dropdown float-right">
                            <button class="btn btn-info btn-sm" type="button" id="rnd()" (click)="
                                addRemoveMultipleAttribute(
                                  complexli,
                                  'add',
                                  json2,
                                  domain.fields
                                )
                              " aria-haspopup="true" aria-expanded="false">
                              Add {{ complexli.fieldName }}
                            </button>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>

                    <ng-container *ngTemplateOutlet="
                        displayCardsMultiValueMultitype;
                        context: {
                          $implicit: complexli,
                          c: json2
                        }
                      ">
                    </ng-container>
                  </ul>
                </ng-container>
              </li>
            </ng-template>

            <ng-template #displayCardsMultiValueMultitype let-domainModelField let-jsonobj="c">
              <ng-container>
                <ng-container *ngFor="
                    let multi of domainModelField.value;
                    let k = index
                  ">
                  <li class="list-group-item">
                    <ul class="list-group">
                      <div class="card-header">
                        <span class="card-title">{{
                          domainModelField.fieldName
                          | titlecase
                          }}
                          {{ k + 1 }}</span>
                        <div class="dropdown float-right">
                          <button class="btn btn-info btn-sm" type="button" id="add" (click)="
                              addRemoveMultipleAttribute(
                                multi,
                                k,
                                jsonobj
                              )
                            " aria-haspopup="true" aria-expanded="false">
                            Delete
                            {{ domainModelField.fieldName }}
                            {{ k + 1 }}
                          </button>
                        </div>
                      </div>

                      <ng-container class="list-group-item" *ngFor="
                          let child of multi.value;
                          let i = index
                        ">
                        <ng-container *ngIf="
                            child.isSingleValue &&
                            child.isMultiple &&
                            (child.level == 1 ||
                              child.level == undefined)
                          ">
                          <ng-template [ngTemplateOutlet]="
                              singlevaluemultipletype
                            " [ngTemplateOutletContext]="{
                              $implicit: child,
                              b: multi
                            }">
                          </ng-template>
                        </ng-container>

                        <ng-container *ngIf="
                            child.isSingleValue &&
                            !child.isMultiple
                          ">
                          <ng-template [ngTemplateOutlet]="
                              simpleliItemsEdit
                            " [ngTemplateOutletContext]="{
                              $implicit: child,
                              b: multi
                            }">
                          </ng-template>
                        </ng-container>

                        <ng-container *ngIf="
                            child.isMultiple &&
                            child.isMultiValue &&
                            !child.isSingleValue
                          ">
                          <ng-template [ngTemplateOutlet]="
                              multivaluemultipletype
                            " [ngTemplateOutletContext]="{
                              $implicit: child,
                              b: multi
                            }">
                          </ng-template>
                        </ng-container>

                        <ng-container *ngIf="
                            child.isMultiValue &&
                            !child.isMultiple &&
                            !child.isSingleValue
                          ">
                          <ng-template [ngTemplateOutlet]="multivalue" [ngTemplateOutletContext]="{
                              $implicit: child,
                              b: multi
                            }">
                          </ng-template>
                        </ng-container>

                      </ng-container>
                    </ul>
                  </li>
                </ng-container>
              </ng-container>
            </ng-template>

            <ng-template #displayCardsEdit let-domainModelField>
              <ng-container class="list-group-item" *ngFor="
                  let child of domainModelField.value;
                  let i = index
                ">
                <ng-container *ngIf="
                    child.isSingleValue &&
                    child.isMultiple &&
                    (child.level == 1 ||
                      child.level == undefined)
                  ">
                  <ng-template [ngTemplateOutlet]="
                      singlevaluemultipletype
                    " [ngTemplateOutletContext]="{
                      $implicit: child,
                      b: []
                    }">
                  </ng-template>
                </ng-container>

                <ng-container *ngIf="
                    child.isSingleValue && !child.isMultiple
                  ">
                  <ng-template [ngTemplateOutlet]="simpleliItemsEdit" [ngTemplateOutletContext]="{
                      $implicit: child,
                      b: []
                    }">
                  </ng-template>
                </ng-container>

                <ng-container *ngIf="
                    child.isMultiple &&
                    child.isMultiValue &&
                    !child.isSingleValue
                  ">
                  <ng-template [ngTemplateOutlet]="
                      multivaluemultipletype
                    " [ngTemplateOutletContext]="{
                      $implicit: child,
                      b: domainModelField
                    }">
                  </ng-template>
                </ng-container>

                <ng-container *ngIf="
                    child.isMultiValue &&
                    !child.isMultiple &&
                    !child.isSingleValue
                  ">
                  <ng-template [ngTemplateOutlet]="multivalue" [ngTemplateOutletContext]="{
                      $implicit: child,
                      b: []
                    }">
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-template>

            <ng-template #displayCardsMultivalue let-domainModelField let-jsonobj="c">
              <ng-container *ngFor="
                  let multi of domainModelField.value;
                  let j = index
                ">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-md-3"></div>
                    <ng-container>
                      <div class="col-md-9" *ngIf="
                          domainModelField.isString ||
                          domainModelField.isInteger ||
                          domainModelField.isDecimal ||
                          domainModelField.isUrl
                        ">
                        <input type="text" pInputText  [(ngModel)]="multi.value"
                          #ctrlName="ngModel" [attr.data-controltype]="
                            domainModelField.fieldType
                          " id="{{
                            domainModelField.fieldName
                          }}" />
                        <div class="w-15 float-right">
                          <button class="btn btn-light btn-sm pt-0 pb-0" type="button" id="add"
                            (click)="
                              addRemoveMultipleAttribute(
                                multi,
                                j,
                                jsonobj
                              )
                            " aria-haspopup="true" aria-expanded="false">
                            <i class="far fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>

                      <div class="col-md-9" *ngIf="
                          domainModelField.isDateTime ||
                          domainModelField.isDate
                        ">
                        <input type="text" pInputText bsDatepicker id="{{ domainModelField.fieldName }}"
                          [(ngModel)]="multi.value"
                          [attr.data-controltype]="domainModelField.fieldType"
                          placeholder="Select date"  autocomplete="off"
                          [bsConfig]="{showWeekNumbers: false, containerClass: 'theme-default', selectFromOtherMonth: true}">
                        <div class="w-15 float-right">
                          <button class="btn btn-light btn-sm pt-0 pb-0" type="button" id="add"
                            (click)="
                              addRemoveMultipleAttribute(
                                multi,
                                j,
                                jsonobj
                              )
                            " aria-haspopup="true" aria-expanded="false">
                            <i class="far fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>

                      <div class="col-md-9" *ngIf="
                          domainModelField.isReference ===
                            true &&
                          domainModelField.referenceList !=
                            null &&
                          !domainModelField.referenceList
                            .displayAsTypeahead
                        ">
                        <div class="select">
                          <p-dropdown id="{{ child.fieldName }}" [options]="domainModelField.referenceList"
                          [(ngModel)]="multi.value" optionLabel="name"  optionValue="value"
                          ></p-dropdown>
                        </div>
                        <div class="w-15 float-right">
                          <button class="btn btn-light btn-sm pt-0 pb-0" type="button" id="add"
                            (click)="
                              addRemoveMultipleAttribute(
                                multi,
                                j,
                                jsonobj
                              )
                            " aria-haspopup="true" aria-expanded="false">
                            <i class="far fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>

                      <div class="col-md-9" *ngIf="
                          domainModelField.isReference ===
                            true &&
                          domainModelField.referenceList !=
                            null &&
                          domainModelField.referenceList
                            .displayAsTypeahead
                        ">
                        <ng-autocomplete [data]="referenceValues" [searchKeyword]="keyword"
                          (inputChanged)="
                            getReferenceValues(
                              $event,
                              domainModelField.fieldName
                            )
                          " (selected)="
                            onAutoCompleteSelectedValue(
                              $event,
                              domainModelField.fieldName
                            )
                          " [itemTemplate]="itemTemplate" [minQueryLength]="3"
                          placeholder="Please type to view options" [notFoundTemplate]="
                            notFoundTemplate
                          " id="{{
                            domainModelField.fieldName
                          }}" [attr.data-controltype]="
                            domainModelField.fieldType
                          " [(ngModel)]="multi.value" #ctrlName="ngModel">
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item>
                          <a [innerHTML]="item.display"></a>
                        </ng-template>
                        <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                        <div class="w-15 float-right">
                          <button class="btn btn-light btn-sm pt-0 pb-0" type="button" id="add"
                            (click)="
                              addRemoveMultipleAttribute(
                                multi,
                                j,
                                jsonobj
                              )
                            " aria-haspopup="true" aria-expanded="false">
                            <i class="far fa-trash-alt"></i>
                          </button>

                        </div>

                      </div>
                    </ng-container>
                  </div>
                </li>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>
<div class="modal-footer">
  <div class="dropdown float-right">
      <p-button label="Close" styleClass="btn-sm mr-2" id="bRequestSubmit" aria-haspopup="true"
          aria-expanded="false" (click)="onClose()">
      </p-button>
  </div>
  <div class="dropdown float-right">
    <p-button label="Submit" styleClass="btn-sm mr-2" id="bRequestSubmit" aria-haspopup="true"
        aria-expanded="false" (click)="onSubmit()">
    </p-button>
</div>
</div>
</div>
</div>
</div>