import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonFunctionsService {

  replaceAll(str, find, replace) {
    const escapedFind = find.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }

  formatText(str) {
    const first = this.replaceAll(str, '_', '.');
    const second = this.replaceAll(first, ' ', '_');
    return second;
  }

  getSortedColumnName(columnsObject): any[] {
    const columnsArray = [];
    for (const c in columnsObject) {
      if (columnsObject[c].width != 0 && columnsObject[c].columnHeader != null) {
        const newColumnObj = {
          field: c.replace('.', '_'),
          headerName: columnsObject[c].columnHeader,
          order: columnsObject[c].order,
          width: columnsObject[c].width
        }
        columnsArray.push(newColumnObj);
      }
    }
    columnsArray.sort((a, b) => (a.order > b.order) ? 1 : -1)
    return columnsArray;
  }

  getSortedColumnNameFromArray(columnsObject): any[] {
    const columnsArray = [];
    for (const c in columnsObject) {
      if (columnsObject[c][1].width != 0 && columnsObject[c][1].columnHeader) {
        const newColumnObj = {
          field: c[0].replace('.', '_'),
          headerName: columnsObject[c][1].columnHeader,
          order: columnsObject[c][1].order,
          width: columnsObject[c][1].width
        }
        columnsArray.push(newColumnObj);
      }
    }

    if (columnsArray.find(x => x.order > 0) != undefined)
    {
      columnsArray.sort((a, b) => (a.order > b.order) ? 1 : -1)
    }
    
    return columnsArray;
  }

  replaceDotObjectKeysWithUnderscore(obj) {
    return Object.keys(obj).reduce(function (o, prop) {
      const value = obj[prop];
      const newProp = prop.replace('.', '_');
      o[newProp] = value;
      return o;
    }, {});
  }

}
