import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { isDate } from 'moment';

@Component({
  selector: 'app-share-view-entity-attributes',
  templateUrl: './share-view-entity-attributes.component.html',
  styleUrls: ['./share-view-entity-attributes.component.scss']
})
export class ShareViewEntityAttributesComponent implements OnInit {

  _updateValueAttributes: any;

  @Input()
  get UpdateValueAttributes(): any { return this._updateValueAttributes; }
  set UpdateValueAttributes(updateValueAttributes: any) {
    this._updateValueAttributes = updateValueAttributes;
    this.initialiseEntityDetails();
  }

  @Input() Title: string

  constructor() { }

  isSingleValue: boolean = false;
  singleValueFieldName: string;
  singleValueFieldValue: string;
  singleValueFieldType: string;

  isComplexValue: boolean = false;
  complexValueFieldName: string;
  complexValueValues: [];

  language: string = navigator.language;

  ngOnInit(): void {
  }

  initialiseEntityDetails() {

    // no data defined, then keep it blank
    if (!this._updateValueAttributes) {
      this.isSingleValue = false;
      this.isComplexValue = false;
    }

    if (this._updateValueAttributes) {
      // no count defined, then only a single attribute being displayed
      if (this._updateValueAttributes.Count === undefined) {
        this.isSingleValue = true;
        this.isComplexValue = false;

        this.singleValueFieldName = this._updateValueAttributes.FieldName;
        this.singleValueFieldValue = this._updateValueAttributes.Value;
        this.singleValueFieldType = this._updateValueAttributes.Type;
      }

      // a count is defined and that meant it is a complex type
      if (this._updateValueAttributes.Count !== undefined) {
        this.isSingleValue = false;
        this.isComplexValue = true;

        this.complexValueFieldName = this._updateValueAttributes.FieldName;
        this.complexValueValues = this._updateValueAttributes.Value;
      }
    }
  }

  isString(type: string): boolean {
    return type.endsWith("DataAttributeString") || type.endsWith("DataAttributeUrl");
  }

  isDate(type: string): boolean {
    return type.endsWith("DataAttributeDateTime") || type.endsWith("DataAttributeDate");
  }

  isInteger(type: string): boolean {
    return type.endsWith("DataAttributeInteger");
  }

  isDecimal(type: string): boolean {
    return type.endsWith("DataAttributeDecimal");
  }

  isValueDate(value: any): boolean {
    return moment(value, true).isValid();
  }

}