import { Component, OnInit, SecurityContext, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AccountService } from './account.service';
import { User } from '../security-configuration/user-administration/models/user';
import { DataSanitisationService } from '../shared/custom-validators/data-sanitisation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { LoginStatusEnum } from '../audit/models/login-status.enum';
import { ModalService } from '../shared/services/modal.service';
declare var $: any;

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    form: UntypedFormGroup;
    submitted = false;
    returnUrl: string;
    hasDomainModels: boolean = false;
    errorMessage: any;
    loginType = LoginStatusEnum;
    public message: string;

    public changePasswordForm: UntypedFormGroup;
    public showChangePasswordPopup = false;

    public oldPasswordTextInput = false;
    public newPasswordTextInput = false;
    public confirmPasswordTextInput = false;

    public passwordPattern = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&~|+£:;-])(?!.*[<>])(?!.*[\"])(?!.*[*])(?!.*[£]).*$";

    // This is changed in the installer build pipeline
    public buildNumber = '7.0.0.0';
    expiredPasswordUserName:any;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private sanitizer: DomSanitizer,
        private modalService:ModalService

    ) { }

    ngOnInit() {
        // check that the user is still authenticated
        //  the local storage authentication token might have expired
        //  at this point no request has been made to the backend, 
        //  the with-credentials-interceptor has not been invoked
        if (!this.accountService.isAuthenticated()) {
            // if the token has expired then log the user out, 
            //  clear the local storage and the old token
            this.accountService.logout(false);
        }

        this.form = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            rememberMe: []
        });

        this.changePasswordForm = this.formBuilder.group({
            OldPassword: ['', Validators.required],
            Password: ['', Validators.required],
            ConfirmPassword: ['', Validators.required]
        }, { validators: [this.checkPasswords,this.checkPasswordForInvalidCharacters] }
        );

        this.validateAllFields(this.changePasswordForm);

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get fields() {
        return this.form.controls;
    }

    forgotPassword(): void {
        this.router.navigate(['./forgot-password']);
    }

    onSubmit(): void {
        this.sanitiseFormValues();
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        this.accountService.login(this.fields.username.value, this.fields.password.value, 'MDM')
            .pipe(first())
            .subscribe(
                data => {
                        this.passwordExpiryWarning(data);
                        this.router.navigate([this.returnUrl]);
                    
                },
                error => {
                    // Extract PasswordExpired value
                    const passwordExpired = error.includes('password hash has expired');
                   if(this.fields.username.value != null && passwordExpired){
                    this.showChangePasswordPopup = true;
                    this.expiredPasswordUserName = this.fields.username.value;
                   }
                   else{
                    this.message = error;
                   }
                });
    }

    onChangePasswordCancel(): void {     
        this.showChangePasswordPopup = false;
    }

    onChangePasswordSave(): void {
        if (this.changePasswordForm.valid) {

            const oldPassword = this.changePasswordForm.get('OldPassword').value;
            const password = this.changePasswordForm.get('Password').value;
            const confirmPassword = this.changePasswordForm.get('ConfirmPassword').value;

            this.accountService
                .changePassword(this.expiredPasswordUserName, oldPassword, password, confirmPassword)
                .pipe(first())
                .subscribe({
                    next: () => {
                        this.modalService.displayInfoModal("Password Changed");
                        this.showChangePasswordPopup = false;
                        $('#changePasswordPopup').modal('hide');
                        this.accountService.logout();
                        this.form.get("password").setValue('');
                    },
                    error: err => {
                        this.modalService.displayErrorModal(err, '', { enableHtml: true });
                    }
                });
        }
        else {
            this.validateAllFields(this.changePasswordForm);
        }
    }
    private checkPasswords(group: UntypedFormGroup) {
  
        const password = group.get('Password').value;
        const confirmPassword = group.get('ConfirmPassword').value;
        return password === confirmPassword ? null : { notSame: true };
    }

    private validateAllFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFields(control);
            }
        });
    }

    public isValidInputControl(fieldName): boolean {
        return (
            this.changePasswordForm.controls[fieldName].invalid &&
            (this.changePasswordForm.controls[fieldName].dirty ||
                this.changePasswordForm.controls[fieldName].touched)
        );
    }
    public isValidInputControlForLogin(fieldName): boolean {
        return (
            this.form.controls[fieldName].invalid &&
            (this.form.controls[fieldName].dirty ||
                this.form.controls[fieldName].touched)
        );
    }
    public sanitiseFormValues() {
        if (this.fields.username.value && typeof this.fields.username.value !== "boolean" && !Array.isArray(this.fields.username.value) && typeof this.fields.username.value !== "number") {
            this.fields.username.setValue(this.sanitizer.sanitize(SecurityContext.HTML, this.fields.username.value));
            this.fields.username.setValue(this.fields.username.value == "" ? this.fields.username.value : this.fields.username.value.replace(/<[^>]+>/ig, ""));
        }
        if (this.fields.password.value && typeof this.fields.password.value !== "boolean" && !Array.isArray(this.fields.password.value) && typeof this.fields.password.value !== "number") {
            this.fields.password.setValue(this.sanitizer.sanitize(SecurityContext.HTML, this.fields.password.value));
            this.fields.password.setValue(this.fields.password.value == "" ? this.fields.password.value : this.fields.password.value.replace(/<[^>]+>/ig, ""));
        }

    }
    private passwordExpiryWarning(data: Object) {
        let user = localStorage.getItem('user');
        if (data) {
            const passwordExpiryDate = JSON.parse(user).passwordHashExpiryDate;
            const daysLeft = this.calculateDiff(passwordExpiryDate);
            const expiryWarning = `Your password will expire in ${daysLeft} day(s)`;
            if (daysLeft > 0 && daysLeft <= 15) {
                this.modalService.displayWarningModal(expiryWarning);
                return true;
            }
          
            return false;
        }
    }
    private calculateDiff(passwordExpiryDate) {
        const currentDate = new Date();
        const expiryDate = new Date(passwordExpiryDate);

        const timeDiff = expiryDate.getTime() - currentDate.getTime();
        return timeDiff <= 0 ? 0 : Math.round(timeDiff / (1000 * 3600 * 24));
    }
    checkPasswordForInvalidCharacters(group: UntypedFormGroup) {
        const password:string = group.get('Password').value;
        return password.includes("\\") ? { containsInvalidChar: true } : null;
      }
}
