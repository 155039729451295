<div class="card" id="audit-grid"></div>
<div class="card-header" *ngIf="rows.totalRecordsCount <= 0">
    <span class="card-title">No Audits Found</span>
</div>
<div class="content-height-11rem">
    <p-table #dt id="resultTable" class="custom-table" [value]="rows.audits" [lazy]="true"
        (onLazyLoad)="searchAudits($event)" dataKey="id" [tableStyle]="{ 'min-width': '75rem' }" [paginator]="true"
        [rows]="10" [totalRecords]="rows.totalRecordsCount" [loading]="loading"
        [globalFilterFields]="['username', 'url', 'queryString', 'method', 'status', 'timestamp']">

        <ng-template pTemplate="header">

            <tr class="pheader-row-1">
                <th scope="col" style="cursor: pointer;" class="text-center pheader" pSortableColumn="Username">
                    User<p-sortIcon field="Username"></p-sortIcon></th>
                <th scope="col" style="cursor: pointer;" class="text-center pheader" pSortableColumn="Url">
                    URL<p-sortIcon field="Url"></p-sortIcon></th>
                <th scope="col" style="cursor: pointer;" class="text-center pheader" pSortableColumn="QueryString">Query
                    String<p-sortIcon field="QueryString"></p-sortIcon></th>
                <th scope="col" style="cursor: pointer;" class="text-center pheader" pSortableColumn="Method">
                    Method<p-sortIcon field="Method"></p-sortIcon></th>
                <th scope="col" style="cursor: pointer;" class="text-center pheader" pSortableColumn="Status">
                    Status<p-sortIcon field="Status"></p-sortIcon></th>
                <th scope="col" style="cursor: pointer;" class="text-center pheader" pSortableColumn="Timestamp">
                    Timestamp<p-sortIcon field="Timestamp"></p-sortIcon></th>

                <th class="pheader"></th>

            </tr>
            <tr class="pheader-row-2">
                <th class="pheader">
                    <p-columnFilter type="text" field="Username"></p-columnFilter>
                </th>
                <th class="pheader">
                    <p-columnFilter type="text" field="URL"></p-columnFilter>
                </th>
                <th class="pheader">
                    <p-columnFilter type="text" field="QueryString"></p-columnFilter>
                </th>
                <th class="pheader">
                    <p-columnFilter type="text" field="Method"></p-columnFilter>
                </th>
                <th class="pheader">
                    <p-columnFilter type="text" field="Status"></p-columnFilter>
                </th>
                <th class="pheader">
                    <p-columnFilter type="date" field="Timestamp">
                        <ng-template pTemplate="filter" let-filter="filterCallback">
                            <p-calendar appendTo="body" [(ngModel)]="selectedDate"
                                (onSelect)="filter($event)"></p-calendar>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th class="pheader"></th>

            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-tableRow>
            <tr>
                <td>{{tableRow.username}}</td>
                <td>{{tableRow.url}}</td>
                <td>{{tableRow.queryString}}</td>
                <td>{{tableRow.method}}</td>
                <td>{{tableRow.status}}</td>
                <td>{{ language === "en-US" ? (tableRow.timestamp | date: 'MM/dd/yyyy, h:mm a': 'UTC') :
                    tableRow.timestamp | date: 'short': 'UTC'}}</td>
                <td>
                    <input type="button" class="btn btn-primary btn-sm mr-2" value="View" (click)="onRowClick(tableRow)"
                        id="view">
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div class="p-paginator-container">
        <div class="jumpToPage">
            <span styleClass="jump-to-page-label">Jump to page:</span>
            <input pInputText type="number" min="1" [max]="rows.totalRecordsCount" [(ngModel)]="jumpToPage"
                (ngModelChange)="onJumpToPage()" class="jumpToPageInput" />
        </div>
    </div>
</div>