import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { Page } from '../models/page';
import { JobTypeEnum } from '../models/job-type.enum';
import { DateValidatorService } from '../custom-validators/date-validator.service';
import { PermissionFunctions } from '../models/permission-functions';

@Component({
  selector: 'app-custom-grid',
  templateUrl: './custom-grid.component.html',
  styleUrls: ['./custom-grid.component.scss']
})
export class CustomGridComponent implements OnInit, OnChanges {
  @ViewChild('customtable') public customtable: DatatableComponent;
  @Input() childGridRows: any[];
  @Input() childGridColumns: TableColumn[];
  @Input() childPage: Page = new Page();
  @Input() childGridTitle: string;
  @Input() pageNamesForAuthorization: PermissionFunctions[];
  @Output() callparentMethod: EventEmitter<Page> = new EventEmitter<Page>();
  @Output() callActionMethod: EventEmitter<any> = new EventEmitter<any>();
  isLoading = false;
  language: string = navigator.language;
  
  public fullAccess = 'full';
  public readOnlyAccess = 'read-only';

  public get jobTypeEnum(): typeof JobTypeEnum {
    return JobTypeEnum;
  }

  constructor(
    // this is not unused, it is being used in html template
    private dateValidatorService: DateValidatorService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  // This method emit the page model values when user click on pagination
  setPage(pageInfo = { offset: 0 }): void {
    this.childPage.page = pageInfo.offset;
    this.callparentMethod.emit(this.childPage);
  }

  // This method set sort in the page model and emit values when user click on sorting column
  onSort(event): void {
    this.childPage.sortColumn = event.sorts[0].prop;
    this.childPage.sortOrder = event.sorts[0].dir;
    this.setPage();
  }

  // This method which emit button values to parent component
  onActionButtonClick(row: any, btn: string, rowIndex?: number): void {
    const passValue: any = {};
    passValue.rowValue = row;
    passValue.buttonText = btn;
    passValue.rowIndex = rowIndex;
    this.callActionMethod.emit(passValue);
  }

}
