<div class="card content-height-12rem">
    <div class="card-body">
        <div class="p-inputwrapper d-flex flex-column">
            <span class="p-input-icon-left">
                <i class="ti ti-search"></i>
                <input type="text" pInputText [(ngModel)]="searchText" id="inputwithlefticon" (input)="applySearchFilter()" />
            </span>
        </div>
        <div class="list-group" id="itemsList" style="margin-top: 5px;">
            <ng-container *ngFor="let item of filteredItemsList">
                <a class="list-group-item list-group-item-action single-line"
                   [ngClass]="{'active': isItemSelected(item)}"
                   (click)="toggleItem(item)" [title]="item.name">
                   <span class="text-truncate">{{ item.name }}</span>
                    
                    <i class="fas fa-chevron-down icon" *ngIf="item.isExpanded && item.children?.length"></i>
                    <i class="fas fa-chevron-right icon" *ngIf="!item.isExpanded && item.children?.length"></i>
                </a>
                <div *ngIf="item.isExpanded && item.children?.length" class="ml-3">
                    <ng-container *ngFor="let child of item.children">
                        <a class="list-group-item list-group-item-action single-line"
                           [ngClass]="{'active': isItemSelected(child)}"
                           (click)="onItemSelection(child, item)" [title]="child.name">
                            {{ child.name }}
                        </a>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>
