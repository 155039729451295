import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
    
  })
  export class UtilityService {

    addSpaceInPascalCase(input: string): string {
        
        let result = '';
        for (let i = 0; i < input.length; i++) {
            const char = input[i];
            // Check if the character is uppercase and not the first character
            if (char === char.toUpperCase() && i !== 0) {
                // Add a space before the uppercase character
                result += ' ';
            }
            result += char;
        }
        return result;
    }
  }