<div *ngIf="matchDetailsResponse != null && matchDetailsResponse != undefined" class="row">
    <div class="col-md-12 mb-3">
        <div class="card-header">
                <div class="card-title"><b>Score: </b>{{matchDetailsResponse.score}} </div>
                <div class="card-title"><b>Match Rule: </b>{{matchDetailsResponse.matchRule}}</div>
                <div class="card-title"><b>Combination: </b>{{matchDetailsResponse.scoringCombinationDescription}}</div>
                <div class="card-title"><b>Last Updated: </b>{{ language === 'en-US' ? (matchDetailsResponse.lastUpdatedDate | date: 'MM/dd/yyyy, h:mm a') : (matchDetailsResponse.lastUpdatedDate | date: 'dd/MM/yyyy, HH:mm') }}
                </div>
        </div>
    </div>
</div>
<div class="row content-height">
    
    <div class="col-md-6">
        <ul class="list-group">
            <div class="card-header">
                <span class="card-title"><strong>{{matchDetailsResponse.entityType}} Entity 1</strong></span>
            </div>
            <ng-container *ngFor="let matchdetail1 of matchDetailsArray">
                <ng-container
                    *ngTemplateOutlet="matchdetail1.value ? simpleliItemsOne : complexliItemsOne; context: {$implicit:matchdetail1}">
                </ng-container>
            </ng-container>
        </ul>
    </div>
    <div class="col-md-6">
        <ul class="list-group">
            <div class="card-header">
                <span class="card-title"><strong>{{matchDetailsResponse.entityType}} Entity 2</strong></span>
            </div>
            <ng-container *ngFor="let matchdetail2 of matchDetailsArray">
                <ng-container
                    *ngTemplateOutlet="matchdetail2.value ? simpleliItemsSecond :complexliItemsSecond; context: {$implicit:matchdetail2}">
                </ng-container>
            </ng-container>
        </ul>
    </div>

    <ng-template #simpleliItemsOne let-simpleli1>
        <li class="list-group-item">
            <div class="matches-row row">
                <div class="matches-title col-md-3">{{simpleli1.value.fieldName}}</div>
              
                <ng-container *ngIf="simpleli1.value.differenceType === differenceTypeEnum.Equal && 
                    simpleli1.value.value1 && simpleli1.value.value2 else differenceTypeTempalte">
                    <div *ngIf="!showMatchTypes || simpleli1.value.matchType" class="matches-success btn-success col-md-6">
                        <span *ngIf="dateValidatorService.isDate(simpleli1.value.value1); else checkDateTime">
                            {{ language === "en-US" ? (simpleli1.value.value1 | date: 'MM/dd/yyyy') : simpleli1.value.value1 | date: 'shortDate'}}
                        </span>
                    </div>
                    <div *ngIf="showMatchTypes && !simpleli1.value.matchType" class="matches-success btn-light-green col-md-6">
                        <span *ngIf="dateValidatorService.isDate(simpleli1.value.value1); else checkDateTime">
                            {{ language === "en-US" ? (simpleli1.value.value1 | date: 'MM/dd/yyyy') : simpleli1.value.value1 | date: 'shortDate'}}
                        </span>
                    </div>
                </ng-container>
                <ng-template #differenceTypeTempalte>
                    <div class="matches-value col-md-6">
                        <span *ngIf="dateValidatorService.isDate(simpleli1.value.value1); else checkDateTime">
                            {{ language === "en-US" ? (simpleli1.value.value1 | date: 'MM/dd/yyyy') : simpleli1.value.value1 | date: 'shortDate'}}
                        </span>
                    </div>
                </ng-template>
                <ng-template #checkDateTime>
                  <span *ngIf="dateValidatorService.isDateTime(simpleli1.value.value1); else noCheck">
                    {{ language === "en-US" ? (simpleli1.value.value1 | date: 'MM/dd/yyyy, h:mm a') : simpleli1.value.value1 | date: 'short'}}
                  </span>                                
                </ng-template>
                <ng-template #noCheck>
                    {{simpleli1.value.value1}}
                </ng-template>
                <ng-conainter *ngIf="simpleli1.value.matchType" class="matches-status col-md-3">
                    <div class="matches-type" [innerHTML]="simpleli1.value.matchType">
                    </div>
                </ng-conainter>

            </div>
        </li>
    </ng-template>

    <ng-template #simpleliItemsSecond let-simpleli2>
        <li class="list-group-item">
            <div class="matches-row row">
                <div class="matches-title col-md-3">{{simpleli2.value.fieldName}}</div>
                <ng-container *ngIf="simpleli2.value.differenceType === differenceTypeEnum.Equal && 
                    simpleli2.value.value1 && simpleli2.value.value2 else differenceTypeTempalteSecond">
                    <div *ngIf="!showMatchTypes || simpleli2.value.matchType" class="matches-success btn-success col-md-6">
                        <span *ngIf="dateValidatorService.isDate(simpleli2.value.value2); else checkDateTime">
                            {{ language === "en-US" ? (simpleli2.value.value2 | date: 'MM/dd/yyyy') : simpleli2.value.value2 | date: 'shortDate'}}
                        </span>
                    </div>
                    <div *ngIf="showMatchTypes && !simpleli2.value.matchType" class="matches-success btn-light-green col-md-6">
                        <span *ngIf="dateValidatorService.isDate(simpleli2.value.value2); else checkDateTime">
                            {{ language === "en-US" ? (simpleli2.value.value2 | date: 'MM/dd/yyyy') : simpleli2.value.value2 | date: 'shortDate'}}
                        </span>
                    </div>
                </ng-container>
                <ng-template #differenceTypeTempalteSecond>
                    <div class="matches-value col-md-6">
                        <span *ngIf="dateValidatorService.isDate(simpleli2.value.value2); else checkDateTime">
                            {{ language === "en-US" ? (simpleli2.value.value2 | date: 'MM/dd/yyyy') : simpleli2.value.value2 | date: 'shortDate'}}
                        </span>
                    </div>
                </ng-template>
                <ng-template #checkDateTime>
                  <span *ngIf="dateValidatorService.isDateTime(simpleli2.value.value2); else noCheck">
                    {{ language === "en-US" ? (simpleli2.value.value2 | date: 'MM/dd/yyyy, h:mm a') : simpleli2.value.value2 | date: 'short'}}
                  </span>                                
                </ng-template>
                <ng-template #noCheck>
                    {{simpleli2.value.value2}}
                </ng-template>
            </div>
        </li>
    </ng-template>

    <ng-template #complexliItemsOne let-complexli1>
        <li class="list-group-item">
            <ng-container *ngIf="complexli1.children && complexli1.children.length > 0">
                <ul class="list-group">
                    <div class="card-header">
                        <span class="card-title">{{complexli1.name | titlecase}}</span>
                    </div>
                    <ng-container *ngTemplateOutlet="displayCards1; context: {$implicit:complexli1}">
                    </ng-container>
                </ul>
            </ng-container>
        </li>
    </ng-template>

    <ng-template #displayCards1 let-matchdetail1>
        <li class="list-group-item" *ngFor="let child of matchdetail1.children; let i = index;">
            <ng-container *ngIf="matchdetail1.title ==='NESTED-CHILD'">
                <div class="matches-row row">
                    <div class="matches-title col-md-3">{{child.fieldName}}</div>
                    <ng-container *ngIf="child.differenceType === differenceTypeEnum.Equal && 
                            child.value1 && child.value2 else differenceTypeNestedTempalte1">
                        <div *ngIf="!showMatchTypes || child.matchType" class="matches-success btn-success col-md-6">
                            <span *ngIf="dateValidatorService.isDate(child.value1); else checkDateTime">
                                {{ language === "en-US" ? (child.value1 | date: 'MM/dd/yyyy') : child.value1 | date: 'shortDate'}}
                            </span>
                        </div>
                        <div *ngIf="showMatchTypes && !child.matchType" class="matches-success btn-light-green col-md-6">
                            <span *ngIf="dateValidatorService.isDate(child.value1); else checkDateTime">
                                {{ language === "en-US" ? (child.value1 | date: 'MM/dd/yyyy') : child.value1 | date: 'shortDate'}}
                            </span>
                        </div>
                    </ng-container>
                    <ng-template #differenceTypeNestedTempalte1>
                        <div class="matches-value col-md-6">
                            <span *ngIf="dateValidatorService.isDate(child.value1); else checkDateTime">
                                {{ language === "en-US" ? (child.value1 | date: 'MM/dd/yyyy') : child.value1 | date: 'shortDate'}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template #checkDateTime>
                      <span *ngIf="dateValidatorService.isDateTime(child.value1); else noCheck">
                        {{ language === "en-US" ? (child.value1 | date: 'MM/dd/yyyy, h:mm a') : child.value1 | date: 'short'}}
                      </span>                                
                    </ng-template>
                    <ng-template #noCheck>
                        {{child.value1}}
                    </ng-template>
                    <ng-conainter *ngIf="child.matchType" class="matches-status col-md-3">
                        <div class="matches-type" [innerHTML]="child.matchType">
                        </div>
                    </ng-conainter>

                </div>
            </ng-container>

            <ng-container *ngIf="matchdetail1.title ==='TWO-NESTED-CHILD'">
                <ul class="list-group">
                    <div class="card-header">
                        <span class="card-title">{{child.name | titlecase}}</span>
                    </div>
                    <li class="list-group-item" *ngFor="let sc of child.children">
                        <div class="matches-row row">
                            <div class="matches-title col-md-3">{{sc.fieldName}}</div>
                            <ng-container *ngIf="sc.differenceType === differenceTypeEnum.Equal && 
                                sc.value1 && sc.value2 else differenceTypeTwoNestedTempalte1">
                                <div *ngIf="!showMatchTypes || sc.matchType" class="matches-success btn-success col-md-6">
                                    <span *ngIf="dateValidatorService.isDate(sc.value1); else checkDateTime">
                                        {{ language === "en-US" ? (sc.value1 | date: 'MM/dd/yyyy') : sc.value1 | date: 'shortDate'}}
                                    </span>
                                </div>
                                <div *ngIf="showMatchTypes && !sc.matchType" class="matches-success btn-light-green col-md-6">
                                    <span *ngIf="dateValidatorService.isDate(sc.value1); else checkDateTime">
                                        {{ language === "en-US" ? (sc.value1 | date: 'MM/dd/yyyy') : sc.value1 | date: 'shortDate'}}
                                    </span>
                                </div>
                            </ng-container>
                            <ng-template #differenceTypeTwoNestedTempalte1>
                                <div class="matches-value col-md-6">
                                    <span *ngIf="dateValidatorService.isDate(sc.value1); else checkDateTime">
                                        {{ language === "en-US" ? (sc.value1 | date: 'MM/dd/yyyy') : sc.value1 | date: 'shortDate'}}
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template #checkDateTime>
                              <span *ngIf="dateValidatorService.isDateTime(sc.value1); else noCheck">
                                {{ language === "en-US" ? (sc.value1 | date: 'MM/dd/yyyy, h:mm a') : sc.value1 | date: 'short'}}
                              </span>                                
                            </ng-template>
                            <ng-template #noCheck>
                                {{sc.value1}}
                            </ng-template>
                            <ng-conainter *ngIf="sc.matchType" class="matches-status col-md-3">
                                <div class="matches-type" [innerHTML]="sc.matchType">
                                </div>
                            </ng-conainter>
                        </div>
                    </li>
                </ul>
            </ng-container>
        </li>
    </ng-template>

    <ng-template #complexliItemsSecond let-complexli2>
        <li class="list-group-item">
            <ng-container *ngIf="complexli2.children && complexli2.children.length > 0">
                <ul class="list-group">
                    <div class="card-header">
                        <span class="card-title">{{complexli2.name | titlecase}}</span>
                    </div>
                    <ng-container *ngTemplateOutlet="displayCards2; context: {$implicit:complexli2}">
                    </ng-container>
                </ul>
            </ng-container>
        </li>
    </ng-template>

    <ng-template #displayCards2 let-matchdetail2>
        <li class="list-group-item" *ngFor="let child of matchdetail2.children; let i = index;">
            <ng-container *ngIf="matchdetail2.title ==='NESTED-CHILD'">
                <div class="matches-row row">
                    <div class="matches-title col-md-3">{{child.fieldName}}</div>
                   
                    <ng-container *ngIf="child.differenceType === differenceTypeEnum.Equal && 
                            child.value1 && child.value2 else differenceTypeNestedTempalte2">
                        <div *ngIf="!showMatchTypes || child.matchType" class="matches-success btn-success col-md-6">
                            <span *ngIf="dateValidatorService.isDate(child.value2); else checkDateTime">
                                {{ language === "en-US" ? (child.value2 | date: 'MM/dd/yyyy') : child.value2 | date: 'shortDate'}}
                            </span>
                        </div>
                        <div *ngIf="showMatchTypes && !child.matchType" class="matches-success btn-light-green col-md-6">
                            <span *ngIf="dateValidatorService.isDate(child.value2); else checkDateTime">
                                {{ language === "en-US" ? (child.value2 | date: 'MM/dd/yyyy') : child.value2 | date: 'shortDate'}}
                            </span>
                        </div>
                    </ng-container>
                    <ng-template #differenceTypeNestedTempalte2>
                        <div class="matches-value col-md-6">
                            <span *ngIf="dateValidatorService.isDate(child.value2); else checkDateTime">
                                {{ language === "en-US" ? (child.value2 | date: 'MM/dd/yyyy') : child.value2 | date: 'shortDate'}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template #checkDateTime>
                      <span *ngIf="dateValidatorService.isDateTime(child.value2); else noCheck">
                        {{ language === "en-US" ? (child.value2 | date: 'MM/dd/yyyy, h:mm a') : child.value2 | date: 'short'}}
                      </span>                                
                    </ng-template>
                    <ng-template #noCheck>
                        {{child.value2}}
                    </ng-template>
                    <ng-conainter *ngIf="child.matchType" class="matches-status col-md-3">
                        <div class="matches-type" [innerHTML]="child.matchType">
                        </div>
                    </ng-conainter>

                </div>
            </ng-container>

            <ng-container *ngIf="matchdetail2.title ==='TWO-NESTED-CHILD'">
                <ul class="list-group">
                    <div class="card-header">
                        <span class="card-title">{{child.name | titlecase}}</span>
                    </div>
                    <li class="list-group-item" *ngFor="let sc of child.children">
                        <div class="matches-row row">
                            <div class="matches-title col-md-3">{{sc.fieldName}}</div>
                            <ng-container *ngIf="sc.differenceType === differenceTypeEnum.Equal && 
                                sc.value1 && sc.value2 else differenceTypeTwoNestedTempalte2">
                                <div *ngIf="!showMatchTypes || sc.matchType" class="matches-success btn-success col-md-6">
                                    <span *ngIf="dateValidatorService.isDate(sc.value2); else checkDateTime">
                                        {{ language === "en-US" ? (sc.value2 | date: 'MM/dd/yyyy') : sc.value2 | date: 'shortDate'}}
                                    </span>
                                </div>
                                <div *ngIf="showMatchTypes && !sc.matchType" class="matches-success btn-light-green col-md-6">
                                    <span *ngIf="dateValidatorService.isDate(sc.value2); else checkDateTime">
                                        {{ language === "en-US" ? (sc.value2 | date: 'MM/dd/yyyy') : sc.value2 | date: 'shortDate'}}
                                    </span>
                                </div>
                            </ng-container>
                            <ng-template #differenceTypeTwoNestedTempalte2>
                                <div class="matches-value col-md-6">
                                    <span *ngIf="dateValidatorService.isDate(sc.value2); else checkDateTime">
                                        {{ language === "en-US" ? (sc.value2 | date: 'MM/dd/yyyy') : sc.value2 | date: 'shortDate'}}
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template #checkDateTime>
                              <span *ngIf="dateValidatorService.isDateTime(sc.value2); else noCheck">
                                {{ language === "en-US" ? (sc.value2 | date: 'MM/dd/yyyy, h:mm a') : sc.value2 | date: 'short'}}
                              </span>                                
                            </ng-template>
                            <ng-template #noCheck>
                                {{sc.value2}}
                            </ng-template>
                            <ng-conainter *ngIf="sc.matchType" class="matches-status col-md-3">
                                <div class="matches-type" [innerHTML]="sc.matchType">
                                </div>
                            </ng-conainter>

                        </div>
                    </li>
                </ul>
            </ng-container>
        </li>
    </ng-template>

</div>