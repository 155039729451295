import { MatchStatusEnum } from './match-status.enum';
import { SearchMatchesFilterModel } from './search-matches-filter-model';

export class MatchDetailsModel extends SearchMatchesFilterModel {
    hasMoreRecords: boolean;
    currentMatchStatus: MatchStatusEnum | null;
    hasPreviousRecords: boolean;
    id: string;
    page: number;
    searchPageNumber: number;
    fields: any;
    lastUpdatedDate: Date
    constructor() {
        super();
    }
}