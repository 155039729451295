
<div class="login-wrapper">
    <div class="row no-gutters">
        <div class="col-md-5 no-gutters">
           <div class="login-left-section d-flex">
            
            <div class="login-header"> 
                <div class="logo-container">
                <img src="../../assets/images/mdm-logo-white.png"/>
                <img src="../../assets/images/civica_logo_white.svg"/>
            </div>
                <h1>MDM</h1>
                <span>Master Data Management</span>
            </div>
           </div>
        </div>
        <div class="col-md-7 no-gutters">
            <div class="login-right-section d-flex">
            <div class="login-right-section-content">
            <h1>Log in <span class="login-label">to your account</span></h1>
            <div class="login-form">
                <form [formGroup]="form" (ngSubmit)="onSubmit()"> 
                    <div  class="form-group">
                        <label>Username</label>
                        <div  class="input-group mb-3 d-block">
                         
                                <span class="p-input-icon-left d-block">
                                    <i class="ti ti-user"></i>
                                    <input id="username" aria-label="Username" aria-describedby="basic-addon1"
                                type="text" pInputText formControlName="username"
                                 />
                                </span>

                            <div *ngIf="submitted && fields.username.errors" id="warningUsername" class="p-invalidtext-wrapper">
                                <span class="p-invalidtext" *ngIf="fields.username.errors.required">Username is required</span>
                            </div>
                        </div>
                    </div>

                    <div  class="form-group">
                        <label>Password</label>
                        <div  class="input-group mb-3 d-block">
                            <span class="p-input-icon-left d-block">
                                    <p-password [toggleMask]="true" [feedback]="false" formControlName="password" id="password" autocomplete="off" [inputStyle]="{'width':'100%'}" [style]="{'width':'100%'}" > </p-password>                             
                                </span>                     
                            <div *ngIf="submitted && fields.password.errors" id="warningPassword" class="p-invalidtext-wrapper">
                                <span class="p-invalidtext" *ngIf="fields.password.errors.required">Password is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                       
                        <p-button type="submit" styleClass="p-button p-button-lg signin-btn" id="signin" label="Log In">                           
                        </p-button>

                    </div>                
                        <div class="text-danger login-error" [innerHtml]="message"></div>     
                </form>
               
            </div>

            </div>
            
            </div>
        </div>

    </div>
</div>

<!-- Change Password -->
<p-dialog header="Header" [modal]="true" id="changePasswordPopup" styleClass="dialog-fixed-size"
    [(visible)]="showChangePasswordPopup">
    <ng-template pTemplate="header">
        <h5>Password Has Expired</h5>
    </ng-template>
    <form class="multivue-common-form" [formGroup]="changePasswordForm" *ngIf="changePasswordForm" novalidate id="changePasswordForm">   
                    <div class="row">
                        <div class="col-md-5">          
                            <label for="Previous" class="col-form-label mx-1">Current Password</label>
                            <span class="label-asterisk">*</span>
                        </div>
                        <div class="col-md-7">
                            <div class="form-group no-margin-hr">
                                <div class="input-group">
                                    <div class="input-group-append">
                                        <p-password [toggleMask]="true" formControlName="OldPassword" id="OldPassword" autocomplete="off"> </p-password>
                                    </div>
                                </div>
                                <div *ngIf="isValidInputControl('OldPassword')" class="p-invalidtext-wrapper">                         
                                    <span class="p-invalidtext" *ngIf="changePasswordForm.controls['OldPassword'].errors.required">
                                        The Current Password field is required
                                    </span>                            
                            </div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">                          
                            <label for="Previous" class="col-form-label mx-1">New Password</label>
                            <span class="label-asterisk">*</span>
                        </div>
                        <div class="col-md-7">
                            <div class="form-group no-margin-hr">

                                <div class="input-group">
                                    <div class="input-group-append">

                                        <p-password [toggleMask]="true" formControlName="Password" id="Password" required minlength="12" [pattern]="passwordPattern" autocomplete="off"> </p-password>
        
                                    </div>
                                </div>
                                <div *ngIf="changePasswordForm.hasError('containsInvalidChar')" class="p-invalidtext-wrapper">
                                    Password cannot contain a \ character
                                </div>  
                                <div *ngIf="isValidInputControl('Password')" class="p-invalidtext-wrapper">
                                  
                                        <span class="p-invalidtext" *ngIf="changePasswordForm.controls['Password'].errors.required">
                                            The New Password field is required
                                        </span>
                                        <span class="p-invalidtext" *ngIf="changePasswordForm.controls['Password'].errors.minlength">
                                            Password must be at least 12 characters
                                        </span>
                                        <span class="p-invalidtext" *ngIf="changePasswordForm.controls['Password'].errors.pattern">
                                            Password must have at least one lowercase (a-z), one uppercase (A-Z), one
                                            digit (0-9), and one of the following symbols (&#64;$!%*#?~|+:;-)
                                        </span>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5"> 
                            <label for="Previous" class="col-form-label mx-1">Confirm Password</label>
                            <span class="label-asterisk">*</span>
                        </div>
                        <div class="col-md-7">
                            <div class="form-group no-margin-hr">

                                <div class="input-group">
                                    <div class="input-group-append">
                                        
                                        <p-password preventCopyPaste [toggleMask]="true" formControlName="ConfirmPassword" id="ConfirmPassword" required notSame minlength="12" [pattern]="passwordPattern" > </p-password>
                                    </div>
                                </div>
                                 
        
                                <div *ngIf="isValidInputControl('ConfirmPassword')" class="p-invalidtext-wrapper">
                                    <div>
                                    <span class="p-invalidtext" *ngIf="changePasswordForm.controls['ConfirmPassword'].errors.required">
                                        The Confirm Password field is required
                                    </span>
                                    </div>
                                    <div>
                                    <span class="p-invalidtext" *ngIf="changePasswordForm.controls['ConfirmPassword'].errors.minlength">
                                        Password must be at least 12 characters
                                    </span>
                                    </div>
                                    <div>
                                    <span class="p-invalidtext" *ngIf="changePasswordForm.controls['ConfirmPassword'].errors.pattern">
                                        Password must have at least one lowercase (a-z), one uppercase (A-Z),
                                        digit (0-9), and one of the following symbols (&#64;$!%*#?~|+:;-)
                                    </span>
                                    </div>
    
                                </div>
                                <div *ngIf="changePasswordForm.hasError('notSame')" class="p-invalidtext-wrapper">
                                    <span class="p-invalidtext">
                                        Passwords do not match
                                    </span>
                                    </div>        
                                    
                                   
                            </div>
                        </div>

                    </div>
            
    </form>
    <ng-template pTemplate="footer">
        <p-button type="button" id="changePasswordSubmit"
            (click)="onChangePasswordSave()">Update</p-button>

        <p-button type="button" id="changePasswordCancel"  data-dismiss="modal"
            (click)="onChangePasswordCancel()">Cancel</p-button>
    </ng-template>

</p-dialog>



