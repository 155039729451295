import { AccountService } from '../account/account.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { RefreshTokenResponse } from '../account/models/refresh-token-response';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';

// import { log } from 'util';

@Injectable({ providedIn: 'root'})
export class AuthenticationGuard  {

    private refreshTokenUrl: string = '/api/authentication/refreshtoken';    

    constructor(private router: Router, 
        private accountService: AccountService,
        private http: HttpClient) { }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot)  {
        
        let passwordExpired : boolean = JSON.parse(localStorage.getItem('passwordExpired'));

        const token = this.accountService.getToken();       
        if (token && !this.accountService.tokenNotExpired(token)) {
            await this.accountService.tryRefreshingTokens(this, null, null);
        }
        
        if (this.authenticated()) {
            return true;
        }

        // if the password has expired, then logout the user, and return to the login page 
        this.accountService.logout(false);
        if (sessionStorage.getItem('autoLoginAvailable') !== null) {
            this.router.navigate(['/autologin'], {queryParams: {returnUrl: state.url.toString() }});
        }
        else {
            this.router.navigate(['/login'], {queryParams: {returnUrl: state.url.toString() }});
        }

        return false;
    }

    authenticated(): boolean {
        // isAuthenticated will check that the token has not expired
        // isUserLoggedIn will check that someone has logged in
        return this.accountService.isAuthenticated() && this.accountService.isUserLoggedIn();
    }
}
